import React from "react";
import { Form, Field } from "react-final-form";

import { CheckIcon, CrossIcon, PlusIcon } from "icons";
import { IconButton } from "components/generic/IconButton";
import { SectionButton } from "components/generic/SectionButton";

export function MenuSectionForm(props: MenuSectionFormProps) {
  const [showForm, setShowForm] = React.useState(false);

  const handleSubmit = async (values) => {
    await props.onSubmit(values);
    handleClose();
  };

  const handleClose = () => {
    setShowForm(false);
  };

  const handleEsc = (e) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <>
      <SectionButton
        onClick={() => setShowForm(true)}
        disabled={showForm}
        actions={<PlusIcon className="text-gray-600" />}
        variant="inverted"
      >
        Add a New Menu Section
      </SectionButton>

      {showForm && (
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="p-2 pl-6 flex justify-between items-center rounded-full bg-white shadow"
            >
              <Field
                autoFocus
                name="name"
                className="flex-1 focus:outline-none mr-4"
                placeholder="Type a section name"
                component="input"
                onKeyDown={handleEsc}
              />

              <div className="flex items-center space-x-2">
                <IconButton variant="terniary" onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
                <IconButton type="submit">
                  <CheckIcon />
                </IconButton>
              </div>
            </form>
          )}
        />
      )}
    </>
  );
}

export type MenuSectionFormProps = {
  onSubmit: (values: any) => void;
};
