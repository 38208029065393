import React from "react";
import useSWR from "swr";

import { fetcher } from "api/base";
import { flatten } from "core/array";
import { useMenuLoader } from "components/menu/useMenuLoader";

export const uploadResultsEndpoint = "api/v2/menu_builder/upload/results/";

export function useUploadResults() {
  const menuLoader = useMenuLoader();
  const [refresh, setRefresh] = React.useState(true);
  const response = useSWR(uploadResultsEndpoint, fetcher, {
    refreshInterval: refresh ? 2000 : 0,
  });

  const loading = response.data?.some(({ data }) => data.loading);

  let data = null;
  if (response.data != null) {
    const results = response.data;
    const blocks = flatten(
      results.map(
        ({ data }, index) =>
          data.data?.Blocks.filter(({ BlockType }) => BlockType === "LINE").map(
            (block) => ({
              ...block,
              Page: block.Page + index,
            })
          ) ?? []
      )
    );
    const urls = flatten(results.map(({ urls }) => urls));

    data = {
      loading,
      blocks,
      urls,
    };
  }

  // Keep refreshing data until AWS Textract completes it's job and
  // results are fully loaded.
  React.useEffect(() => {
    setRefresh(!!loading);
    if (!loading) {
      menuLoader.setLoading(false);
    } else {
      menuLoader.setLoading(true, "Uploading your menu…");
    }
  }, [loading]);

  return {
    ...response,
    data,
  };
}
