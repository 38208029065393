import React from "react";
import { connect } from "react-redux";

import ErrorBoundary from "../../containers/ErrorBoundary";
import DashboardDishList from "./DashboardDishList";
import DashboardSummaryBox from "./DashboardSummaryBox";
import CustomerType from "./CustomerType";
import PastWeekContainer from "../../containers/PastWeekContainer";

import eye from "../../assets/icons/summary-eye.svg";
import love from "../../assets/icons/summary-love.svg";
import timer from "../../assets/icons/summary-timer.svg";
import star from "../../assets/icons/summary-star.svg";
import { getMinimumReviewsVendor } from "../../selectors";

const DashboardOverview = (props) => {
  const { dashboard, minVendorReviews } = props;

  return (
    <div className="grid-container align-center dashboard">
      <div className="grid-x grid-margin-x grid-margin-y">
        <div className="cell small-6 medium-3 dashboard__cell">
          <DashboardSummaryBox
            title="Overall rating"
            description={`This is your rating, it will be visible to eaters once you have reached ${
              minVendorReviews || ""
            } ${minVendorReviews > 1 ? "reviews" : "review"}.`}
            image={star}
            isFraction={true}
            figure={dashboard.summary.overall_rating_average}
            denominator={5}
          />
        </div>
        <div className="cell small-6 medium-3 dashboard__cell">
          <DashboardSummaryBox
            title="Profile Views"
            description="How many customers checked out your page in the last 30 days."
            image={eye}
            figure={dashboard.summary.last_30_total_views || 0}
            up={{
              isUp:
                dashboard.summary.last_30_total_views >
                dashboard.summary.total_views,
              isPositive: true,
            }}
            down={{
              isDown:
                dashboard.summary.last_30_total_views <
                dashboard.summary.total_views,
              isPositive: false,
            }}
          />
        </div>
        <div className="cell small-6 medium-3 dashboard__cell">
          <DashboardSummaryBox
            title="Average Wait Time"
            description="Average time taken for people to receive their food past 30 days"
            image={timer}
            figure={dashboard.summary.last_30_wait_time}
            up={{
              isUp:
                dashboard.summary.last_30_wait_time >
                dashboard.summary.wait_time,
              isPositive: false,
            }}
            down={{
              isDown:
                dashboard.summary.last_30_wait_time <
                dashboard.summary.wait_time,
              isPositive: true,
            }}
          />
        </div>
        <div className="cell small-6 medium-3 dashboard__cell">
          <ErrorBoundary inline={true}>
            <DashboardSummaryBox
              title="Customer Love"
              description="How many customer have favourited you."
              image={love}
              figure={dashboard.summary.favourite}
            />
          </ErrorBoundary>
        </div>

        <div className="cell medium-4 dashboard__cell">
          <ErrorBoundary inline={true}>
            <DashboardDishList
              title="Most Purchased Dishes"
              description="Past 30 days."
              dishes={dashboard.mostPurchased.results.slice(0, 3)}
              seeAllLink="/dashboard/most-purchased"
            />
          </ErrorBoundary>
        </div>
        <div className="cell medium-4 dashboard__cell">
          <ErrorBoundary inline={true}>
            <DashboardDishList
              title="Highest Rated Dishes"
              description="Past 30 days."
              dishes={dashboard.highestRated.results.slice(0, 3)}
              seeAllLink="/dashboard/highest-rated"
            />
          </ErrorBoundary>
        </div>
        <div className="cell medium-4 dashboard__cell">
          <ErrorBoundary inline={true}>
            <CustomerType
              title="Customer Type"
              description="New customer purchases vs returning customer purchases in the past 30 days."
              new={Math.trunc(dashboard.customerType.new_customer)}
              returning={Math.trunc(dashboard.customerType.old_customer)}
            />
          </ErrorBoundary>
        </div>
        <div className="cell dashboard__cell dashboard__past-week">
          <ErrorBoundary inline={true}>
            <PastWeekContainer />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  minVendorReviews: getMinimumReviewsVendor(state),
});

export default connect(mapState)(DashboardOverview);
