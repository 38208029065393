import * as reviews from "../services/reviews";

const setError = (error) => ({
  type: "SET_ERROR",
  error,
});

export const getReviews = () => (dispatch) => {
  reviews
    .getReviews()
    .then((response) => {
      dispatch({
        type: "GET_REVIEWS_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch(setError(error));
    });
};

export const getMoreReviews = (url) => (dispatch) => {
  dispatch({
    type: "GET_MORE_REVIEWS_REQUEST",
  });
  reviews
    .getMoreReviews(url)
    .then((response) => {
      dispatch({
        type: "GET_MORE_REVIEWS_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_MORE_REVIEWS_FAIL",
      });
      dispatch(setError(error));
    });
};
