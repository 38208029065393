import * as api from "./api";
import { TRANSACTIONS } from "../api_urls";

export const getTransactions = () =>
  api
    .get(TRANSACTIONS)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getNextTransactions = (url) =>
  api
    .get(null, url)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const postTransaction = (transaction) =>
  api
    .post(TRANSACTIONS, transaction)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
