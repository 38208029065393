import React from "react";
import Cookies from "js-cookie";
import { APIError, APIErrorForbidden, APIErrorNotFound } from "api/exceptions";
import { getToken } from "services/auth";

const baseUrl = process.env.REACT_APP_SERVER || "https://api.dev.hawkker.com";

/**
 * ssr_fetcher is used only for server-side requests, when we need to copy
 * cookie header and pass it to the API request
 */
export function ssr_fetcher(req: any, path: string) {
  const cookie = req.headers?.cookie;
  const init = {
    headers: {
      cookie,
    },
  };

  return fetcher(path, init);
}

export function fetcher(path: string, init?: RequestInit) {
  // prepend `baseUrl` only to relative paths
  const url = path.indexOf("http") === 0 ? path : baseUrl + path;

  const headers =
    init?.headers != null
      ? ({ ...init.headers } as Record<string, string>)
      : {};

  headers["Authorization"] = `token ${getToken()}`;

  if (typeof window !== "undefined") {
    const csrftoken = Cookies.get("csrftoken");
    if (csrftoken != null) {
      headers["X-CSRFToken"] = csrftoken;
    }
  }

  return fetch(url, {
    ...init,
    credentials: "include",
    headers,
  }).then((response) => {
    if (!response.ok) {
      if (response.status === 403) {
        throw new APIErrorForbidden();
      } else if (response.status === 404) {
        throw new APIErrorNotFound();
      }

      return response.json().then((errors) => {
        throw new APIError(response.statusText, response.status, errors);
      });
    }

    return response.json().catch(() => {});
  });
}

export const post = makeMutationMethod("POST");
export const put = makeMutationMethod("PUT");
export const patch = makeMutationMethod("PATCH");
export const del = makeMutationMethod("DELETE");

export function makeMutationMethod(
  method: "POST" | "PUT" | "PATCH" | "DELETE"
) {
  return (path: string, data?: any) => {
    let body,
      contentType: string | null = "application/json";
    if (data instanceof FormData) {
      body = data;
      contentType = null;
    } else if (data != null) {
      body = JSON.stringify(data);
    }

    const init: RequestInit = {
      method,
      mode: "cors",
      headers:
        contentType != null
          ? {
              "Content-Type": contentType,
            }
          : undefined,
      body,
    };

    return fetcher(path, init);
  };
}
