import React from "react";
import { Form, Field } from "react-final-form";
import { object, string } from "yup";

import MainError from "components/forms/MainError";
import { makeValidate } from "helpers/forms";

const ForgottenPasswordForm = (props) => {
  const { onSubmit } = props;

  return (
    <div>
      <h3 className="forgotten-password__inner__heading">Forgotten Password</h3>

      <Form
        validate={validateForgottenPassword}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          hasValidationErrors,
          submitting,
          submitError,
        }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <MainError errorText={submitError} />}

            <p>
              Enter your email address to reset your Hawkker vendor account
              password.
            </p>

            <Field
              name="email"
              type="email"
              render={({ input, meta }) => (
                <>
                  <label>
                    Email Address
                    <input {...input} />
                  </label>
                  {meta.touched && meta.error && (
                    <span className="validation-message">
                      Error: Please provide a valid email address
                    </span>
                  )}
                </>
              )}
            />

            <button
              className="button expanded"
              type="submit"
              disabled={hasValidationErrors || submitting}
            >
              Submit
            </button>
          </form>
        )}
      />
    </div>
  );
};

const validateForgottenPassword = makeValidate(
  object({
    email: string().required().email(),
  })
);

export default ForgottenPasswordForm;
