import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getDashboardSummary, getReviews, getMoreReviews } from "../actions";

import Reviews from "../components/reviews/Reviews";

class ReviewsContainer extends React.Component {
  componentDidMount() {
    const {
      summaryLoading,
      getDashboardSummary,
      reviewsLoading,
      getReviews,
    } = this.props;
    if (summaryLoading) {
      getDashboardSummary();
    }
    if (reviewsLoading) {
      getReviews();
    }
  }
  render() {
    const {
      summaryLoading,
      averageRating,
      reviewsLoading,
      reviews,
      getMoreReviews,
      getMoreReviewsLoading,
    } = this.props;

    if (summaryLoading || reviewsLoading)
      return <div className="loading-placeholder" />;

    return (
      <div className="grid-container align-center reviews-container">
        <Reviews
          averageRating={averageRating}
          reviews={reviews}
          getMoreReviews={() => getMoreReviews(reviews.next)}
          getMoreReviewsLoading={getMoreReviewsLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  summaryLoading: state.dashboard.summary.isLoading,
  averageRating: state.dashboard.summary.overall_rating_average,
  reviewsLoading: state.reviews.isLoading,
  reviews: state.reviews,
  getMoreReviewsLoading: state.reviews.isLoadingMore,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getDashboardSummary, getReviews, getMoreReviews },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsContainer);
