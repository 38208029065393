import React from "react";
import { Link } from "react-router-dom";
import coins from "../../assets/images/coins.svg";

class Points extends React.Component {
  render() {
    return (
      <div className="points">
        <div className="grid-x">
          <div className="cell medium-6 points__display">
            <h1 className="points__title">Points</h1>
            <div className="grid-x points__display__inner">
              <div className="cell medium-6 points__display__inner__coins">
                <img src={coins} alt="coins" />
              </div>

              <div className="cell medium-6">
                <span className="points__display__inner__you-have">
                  You have
                </span>
                <span className="points__display__inner__count">
                  {this.props.pointsCount}{" "}
                  <span className="points__display__inner__count__points-text">
                    points
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Points;
