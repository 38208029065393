import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editIngredients } from "../../actions";

import EditIngredientsForm from "./forms/EditIngredientsForm";

class EditIngredients extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      submitDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleSubmit(data) {
    this.setState({ submitDisabled: true });
    const payLoad = {
      ingredients: data,
    };
    this.props
      .editIngredients(payLoad)
      .then(() => {
        this.props.history.push("/profile");
      })
      .catch((error) => {
        if (!this._isMounted) return;
        this.setState({
          error: error.data.error,
        });
        this.setState({ submitDisabled: false });
        window.scrollTo(0, 0);
      });
  }
  render() {
    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Edit Ingredients</h1>
            <EditIngredientsForm
              error={this.state.error}
              profile={this.props.profile}
              submitDisabled={this.state.submitDisabled}
              submitHandler={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editIngredients }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditIngredients);
