import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteAccount } from "../../actions";

import ModalPortal from "../../containers/ModalPortal";

class DeleteAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({ showModal: true });
  }
  closeModal() {
    this.setState({ showModal: false });
  }
  render() {
    const { showModal } = this.state;
    const { closeModal, openModal } = this;
    const { deleteAccount } = this.props;

    return (
      <div className="grid-container">
        <ModalPortal showModal={showModal} closeModal={closeModal}>
          <h2>Are you sure you want to permanently delete this account?</h2>
          <p>All your account data will be deleted.</p>
          <span className="delete-account-modal__btn-container">
            <button
              className="delete-account-modal__no-btn"
              onClick={closeModal}
            >
              No
            </button>
            <button
              className="button negative delete-account-modal__delete-btn"
              onClick={deleteAccount}
            >
              Delete
            </button>
          </span>
        </ModalPortal>
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Delete Account</h1>
            <p>
              Deleting your account erases all information you have provided to
              Hawkker and permanently removes the ability for your business to
              be visible to eaters until you create a new account.
            </p>
            <span className="delete-account__btn-wrapper">
              <button className="delete-account__btn" onClick={openModal}>
                Delete Account
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteAccount }, dispatch);

export default connect(null, mapDispatchToProps)(DeleteAccount);
