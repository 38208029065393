const validation = (form) => {
  const elements = [...form.elements].filter(
    (element) => element.dataset.validate
  );

  let valid = true;

  for (const element of elements) {
    if (!validate(element)) valid = false;

    element.addEventListener("input", () => {
      validate(element);
    });
    element.addEventListener("change", () => {
      validate(element);
    });
  }
  return valid;
};

const validate = (element) => {
  let valid = true;

  // Browser Validation
  if (!element.checkValidity()) {
    valid = false;
    toggleErrorMessage(element, "validate", true);
  } else {
    toggleErrorMessage(element, "validate", false);
  }

  // Toggle Required Target Validation
  if (element.dataset.toggleRequiredTarget) {
    const toggleRequiredReceivers = document.querySelectorAll(
      `[data-toggle-required-receiver="${element.dataset.toggleRequiredTarget}"]`
    );
    for (const receiver of toggleRequiredReceivers) {
      receiver.required = !element.checked;
      validate(receiver);
    }
  }

  if (!valid) {
    addInvalidStyle(element);
  } else {
    removeInvalidStyle(element);
  }

  return valid;
};

const addInvalidStyle = (element) => {
  element.classList.add("invalid");
};

const removeInvalidStyle = (element) => {
  element.classList.remove("invalid");
};

const toggleErrorMessage = (element, validationType, show) => {
  const errorMessage = document.querySelector(
    `[data-validate-message="${element.dataset[validationType]}"]`
  );

  if (show) {
    errorMessage.classList.remove("hide");
    return;
  }

  if (checkSharedElementsValid(element, validationType)) {
    errorMessage.classList.add("hide");
  }
};

const checkSharedElementsValid = (element, validationType) => {
  const sharedMessageElements = document.querySelectorAll(
    `[data-validate="${element.dataset.validate}"]`
  );
  if (sharedMessageElements.length < 2) return true;

  let allValid = true;

  for (const element of sharedMessageElements) {
    if (!element.checkValidity()) {
      allValid = false;
      break;
    }
  }

  return allValid;
};

export default validation;
