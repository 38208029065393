import React from "react";
import moment from "moment";

// TODO: Add prop validation
const ScheduleLocation = ({
  location: {
    id,
    location_type,
    market,
    street,
    postcode,
    postcode_area,
    opening_hours,
    offering,
  },
  onClick,
  ...props
}) => {
  const openWeekdays = opening_hours
    .filter((hour) => hour.open && hour.weekday)
    .map((day) =>
      moment()
        .set({ isoWeekday: day.weekday + 1 })
        .format("ddd")
    );

  return (
    <div>
      <div className="menu-item" onClick={() => onClick && onClick(id)}>
        <div className="menu-item__header">
          {/* eslint-disable-next-line camelcase */}
          <h2 className="menu-item__header__title">
            {location_type === "market" &&
              `${market.area}, ${market.market_name}`}
            {location_type === "fixed" && `${street}, ${postcode}`}
            {location_type === "private" &&
              `${postcode_area.postcode}, ${postcode_area.region}`}
            {location_type === "webshop" &&
              `Webshop ${postcode_area?.postcode}, ${postcode_area?.region}`}
          </h2>
          <span className="menu-item__header__price">Edit Location</span>
        </div>
        <p className="menu-item__description">
          {location_type === "market" && "Market Stall/Pitch"}
          {location_type === "fixed" && "Fixed Location"}
          {location_type === "private" && "Delivery Only Location"}
        </p>
        {!!offering.length && (
          <p className="menu-item__description">{offering.join(", ")}</p>
        )}
        <p className="menu-item__description">
          {location_type === "webshop"
            ? "Always Open"
            : openWeekdays.length > 0
            ? `Open: ${openWeekdays.join(", ")}`
            : "Always Closed"}
        </p>
      </div>
    </div>
  );
};

export default ScheduleLocation;
