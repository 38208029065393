import React from "react";
import { Link } from "react-router-dom";

class ProfileCard extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
    this.toggleContent = this.toggleContent.bind(this);
  }
  toggleContent() {
    this.setState({
      open: !this.state.open,
    });
    this.state.open
      ? (this.refs.content.style.height = "0px")
      : (this.refs.content.style.height = `${this.refs.innerContent.offsetHeight}px`);
    this.state.open
      ? this.refs.contentToggle.classList.remove("open")
      : this.refs.contentToggle.classList.add("open");
  }
  render() {
    return (
      <div className="profile__card">
        <div className="profile__card__header" onClick={this.toggleContent}>
          <span className="profile__card__header__title">
            {this.props.title}
          </span>
          <button
            ref="contentToggle"
            className="profile__card__header__open-btn"
          />
          {this.props.editLink ? (
            <Link
              className="profile__card__header__edit desktop"
              to={this.props.editLink}
            >
              Edit
            </Link>
          ) : (
            ""
          )}
        </div>
        <div
          ref="content"
          className={`profile__card__content ${this.state.open ? "open" : ""}`}
        >
          <div ref="innerContent" className="profile__card__content__inner">
            {this.props.children}
          </div>
          {this.props.editLink ? (
            <Link
              className="profile__card__header__edit mobile"
              to={this.props.editLink}
            >
              Edit
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default ProfileCard;
