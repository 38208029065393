import React from "react";
import { withScriptjs } from "react-google-maps";
import { googleAPIKey } from "../credentials/credentials";

export function withGoogleMaps(Component) {
  const googleMapUrl =
    "https://maps.googleapis.com/maps/api/js?key=" +
    googleAPIKey +
    "&v=3.exp&libraries=geometry,drawing,places";

  const ComponentWithScripts = withScriptjs(Component);

  return function WithGoogleMaps(props) {
    return (
      <ComponentWithScripts
        googleMapURL={googleMapUrl}
        loadingElement={<div />}
        {...props}
      />
    );
  };
}
