import React from "react";
import classnames from "classnames";
import { Field, useForm, useFormState } from "react-final-form";

import { IconButton } from "components/generic/IconButton";
import { FieldError } from "components/forms/FieldError";
import { DietaryInformationSelect } from "components/forms/DietaryInformationSelect";

export function MenuItemForm({ prefix = "", onClose }) {
  const { submitError, submitting } = useFormState();
  const { submit, focus } = useForm();

  React.useEffect(() => {
    focus(`${prefix}name`);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await submit();
    onClose();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-4 pb-3 flex flex-col space-y-3 rounded-xl bg-gray-100"
    >
      <div className="flex justify-between items-center">
        Item details
        <IconButton variant="primary" color="red" onClick={onClose}>
          <div className="w-3 h-px border-t border-b border-current" />
        </IconButton>
      </div>

      <div className="flex flex-col bg-white divide-y divide-gray-100 rounded-lg overflow-hidden">
        <Field
          name={`${prefix}name`}
          render={({ input }) => (
            <div className="px-3 leading-10">
              <input
                {...input}
                autoFocus
                className="w-full focus:outline-none"
                placeholder="Item name"
              />
              <FieldError name={input.name} />
            </div>
          )}
        />

        <Field
          name={`${prefix}description`}
          render={({ input }) => (
            <div className="px-3 leading-10">
              <input
                {...input}
                className="w-full focus:outline-none"
                placeholder="Description"
              />
              <FieldError name={input.name} />
            </div>
          )}
        />

        <Field
          name={`${prefix}price`}
          render={({ input }) => (
            <div className="px-3 leading-10">
              <div className="flex">
                <span
                  className={classnames("mr-1", {
                    "text-gray-600": input.value,
                    "text-gray-400": !input.value,
                  })}
                >
                  £
                </span>
                <input
                  {...input}
                  className="flex-1 focus:outline-none"
                  placeholder="0.00"
                />
              </div>
              <FieldError name={input.name} />
            </div>
          )}
        />
      </div>

      <Field
        name={`${prefix}dietary_information`}
        render={({ input }) => (
          <DietaryInformationSelect
            value={input.value}
            onChange={input.onChange}
          />
        )}
      />

      {submitError && <p className="text-brand-red">{submitError}</p>}

      <button
        type="submit"
        className="p-3 bg-brand-blue text-white text-center rounded-full cursor-pointer disabled:cursor-not-allower disabled:bg-opacity-25"
        disabled={submitting}
      >
        Save the item
      </button>
    </form>
  );
}
