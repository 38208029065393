import React from "react";
import { withRouter } from "react-router-dom";

export const SegmentAnalytics = withRouter(function SegmentAnalytics({
  location,
}) {
  React.useEffect(() => {
    // @ts-ignore
    if (window.analytics != null) {
      // @ts-ignore
      window.analytics.page(location.pathname);
    }
  }, [location.pathname]);

  return null;
});
