import React from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { Helmet } from "react-helmet";
import { object, boolean, string } from "yup";

import { login } from "actions";
import { makeValidate } from "helpers/forms";

function Login(props) {
  const { error, user, isLoggedIn, from, login } = props;

  const handleLogin = (values, form) => {
    const { email, password } = values;
    return login({ email, password }).catch(() => {
      // clear password input after failed login
      form.change("password", "");
    });
  };

  if (isLoggedIn && user.status !== 2) {
    return <Redirect exact to={from || "/dashboard"} />;
  } else if (isLoggedIn && user.status === 2) {
    return <Redirect exact to={from || "/menu"} />;
  }

  return (
    <div className="grid-x login-container">
      <Helmet>
        <title>Log in to your Hawkker Vendor Account</title>
      </Helmet>
      <div className="login cell medium-7">
        <h3>
          Sign in to your
          <br /> Hawkker vendor account
        </h3>
        {error && (
          <div className="callout alert login__alert">Error: {error}</div>
        )}

        <Form
          onSubmit={handleLogin}
          validate={validateLogin}
          initialValues={{
            tos: false,
            pointsTos: false,
          }}
          render={({ handleSubmit, valid, submitting }) => (
            <form className="login__form" onSubmit={handleSubmit}>
              <Field
                name="email"
                type="text"
                render={({ input }) => (
                  <label>
                    Email address
                    <input {...input} />
                  </label>
                )}
              />

              <Field
                name="password"
                type="password"
                render={({ input }) => (
                  <label>
                    Password
                    <input {...input} />
                  </label>
                )}
              />

              <span className="login__link">
                <Link to="/forgotten-password" className="anchor_button">
                  I've forgotten my password
                </Link>
              </span>

              <div className="tos-checkboxes">
                <Field
                  name="tos"
                  type="checkbox"
                  render={({ input }) => (
                    <>
                      <input id={input.name} {...input} />
                      <label htmlFor={input.name}>
                        I Accept The{" "}
                        <a
                          href="https://hawkker.com/terms/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms And Conditions
                        </a>{" "}
                        And{" "}
                        <a
                          href="https://hawkker.com/privacy/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        <span className="input-required">*</span>
                      </label>
                    </>
                  )}
                />

                <Field
                  name="pointsTos"
                  type="checkbox"
                  render={({ input }) => (
                    <>
                      <input id={input.name} {...input} />
                      <label htmlFor={input.name}>
                        I Accept the terms of the{" "}
                        <a
                          href="https://hawkker.com/hawkker-points-terms/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Hawkker Points System
                        </a>
                        <span className="input-required">*</span>
                      </label>
                    </>
                  )}
                />
              </div>

              <button
                type="submit"
                className="button login__button"
                disabled={!valid || submitting}
              >
                Sign in
              </button>
              <span className="login__link">
                <Link to="/sign-up" className="anchor_button">
                  New to Hawkker?{" "}
                  <span className="login__link__highlight login__underline">
                    Create Vendor Account
                  </span>
                </Link>
              </span>
            </form>
          )}
        />
      </div>
    </div>
  );
}

const validateLogin = makeValidate(
  object({
    email: string().required(),
    password: string().required(),
    tos: boolean().oneOf([true]),
    pointsTos: boolean().oneOf([true]),
  })
);

const mapStateToProps = (state) => ({
  error: state.auth.error,
  isLoggedIn: state.auth.user,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
