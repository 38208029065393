import React from "react";
import validation from "../../helpers/validation";

import MainError from "../forms/MainError";

import { changePassword } from "../../services/auth";

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      currentPassword: "",
      newPassword: "",
      showPassword: false,
      valid: true,
      error: null,
      success: false,
    };
    this.showPassword = this.showPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  showPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit.disabled = true;
    this.setState({ error: null });
    if (!validation(e.target)) {
      this.refs.submit.disabled = false;
      this.setState({ valid: false });
      return;
    }
    this.setState({ valid: true });
    const data = {
      old_password: this.state.currentPassword,
      password: this.state.newPassword,
    };
    changePassword(data)
      .then(() => {
        if (!this._isMounted) return;
        this.setState({
          success: true,
        });
        setTimeout(() => this.props.history.push("/profile"), 3000);
      })
      .catch((error) => {
        this.setState({
          error: error.data.error,
        });
        this.refs.submit.disabled = false;
      });
  }
  render() {
    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Change Password</h1>
            {this.state.error ? <MainError errorText={this.state.error} /> : ""}
            {!this.state.valid ? (
              <MainError
                errorText={
                  "Error: Please check you have entered all information correctly."
                }
              />
            ) : (
              ""
            )}
            <form noValidate onSubmit={this.handleSubmit} method="post">
              <label>
                Current Password
                <input
                  type="password"
                  value={this.state.currentPassword}
                  name="currentPassword"
                  minLength="8"
                  data-validate="current-password"
                  onChange={this.handleChange}
                  required
                />
              </label>
              <span
                className="hide validation-message"
                data-validate-message="current-password"
              >
                Error: Please provide a valid password
              </span>
              <label>
                New Password
                <div className="input-wrapper">
                  <input
                    name="newPassword"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                    data-validate="new-password"
                    minLength="8"
                    required
                  ></input>
                  <div
                    className={`password-toggle ${
                      this.state.showPassword
                        ? "password-toggle__hide"
                        : "password-toggle__show"
                    }`}
                    onClick={this.showPassword}
                  ></div>
                </div>
              </label>
              <span
                className="hide validation-message"
                data-validate-message="new-password"
              >
                Error: Please provide a valid password
              </span>
              <span className="input-hint">
                Password must contain at least 8 characters
              </span>
              <button ref="submit" className="button expanded" type="submit">
                Submit
              </button>
              <span
                className={`change-password__success-text ${
                  this.state.success ? "" : "hide"
                }`}
              >
                Successfully changed password.
              </span>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
