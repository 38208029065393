import React from "react";

const FilterOptions = (props) => {
  const {
    open,
    dishNames,
    filters,
    changeHandler,
    submitHandler,
    isFetchingFiltered,
  } = props;

  return (
    <div className={`filter-options${open ? " open" : ""}`}>
      <form className="filter-options__form" onSubmit={(e) => submitHandler(e)}>
        <div className="filter-options__form__input">
          <label
            htmlFor="time-period"
            className="filter-options__form__input__time-label"
          >
            Time Period
          </label>
          <select
            value={filters.timePeriod}
            onChange={(e) => changeHandler(e)}
            id="days"
            className="filter-options__form__input__select"
          >
            <option data-value={7}>Last 7 Days</option>
            <option data-value={30}>Last 30 Days</option>
            <option data-value="all">All Time</option>
          </select>
        </div>
        <div className="filter-options__form__input">
          <label htmlFor="rating">Rating</label>
          <select
            value={filters.rating}
            onChange={(e) => changeHandler(e)}
            id="rating"
            className="filter-options__form__input__select"
          >
            <option data-value="null">Any Rating</option>
            <option data-value={1}>1</option>
            <option data-value={2}>2</option>
            <option data-value={3}>3</option>
            <option data-value={4}>4</option>
            <option data-value={5}>5</option>
          </select>
        </div>
        <div className="filter-options__form__input">
          <label htmlFor="dish">Dish</label>
          <select
            value={filters.dish}
            onChange={(e) => changeHandler(e)}
            id="dish"
            className="filter-options__form__input__select"
          >
            <option data-value="null">Any Dish</option>
            {dishNames.map((dish) => (
              <option key={dish.id} data-value={dish.id}>
                {dish.name}
              </option>
            ))}
          </select>
        </div>
        <button
          disabled={isFetchingFiltered}
          type="submit"
          className="button filter-options__form__show-btn"
        >
          Show
        </button>
      </form>
    </div>
  );
};

export default FilterOptions;
