const initialState = {
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER_FAIL":
      return {
        error: action.error,
      };
    default:
      return state;
  }
};
