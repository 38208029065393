import React from "react";
import { Switch, Route } from "react-router-dom";

import CrumbRoute from "containers/CrumbRoute";
import { TailwindCssContainer } from "components/TailwindCssContainer";
import { Menu } from "components/menu/Menu";
import { MenuManual } from "components/menu/MenuManual";
import { MenuBuilder } from "components/menu/MenuBuilder";
import { MenuLoaderProvider } from "components/menu/useMenuLoader";
import { MenuSectionsOpsProvider } from "components/menu/useMenuSectionsOps";
import { MenuAnalytics } from "components/menu/MenuAnalytics";

export default function MenuContainer() {
  return (
    <TailwindCssContainer>
      <MenuLoaderProvider>
        <MenuSectionsOpsProvider>
          <Switch>
            <Route exact path="/menu" component={Menu} />
            <CrumbRoute
              exact
              title="Build Menu Manually"
              path="/menu/manual"
              component={MenuManual}
            />
            <CrumbRoute
              exact
              title="Menu Builder"
              path="/menu/builder"
              component={MenuBuilder}
            />
            <CrumbRoute
              exact
              title="Menu Analytics"
              path="/menu/analytics"
              component={MenuAnalytics}
            />
          </Switch>
        </MenuSectionsOpsProvider>
      </MenuLoaderProvider>
    </TailwindCssContainer>
  );
}
