import React from "react";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import OnboardingHeader from "components/onboarding/OnboardingHeader";
import RegistrationForm from "components/registration/RegistrationForm";
import RegistrationSuccess from "components/registration/RegistrationSuccess";

const RegisterContainer = (props) => (
  <>
    <Helmet>
      <title>Get your business onto Hawkker in 5 minutes</title>
    </Helmet>

    <OnboardingHeader title="Hawkker - Sign Up" />

    <Switch>
      <Route exact path={props.match.path} component={RegistrationForm} />
      <Route
        exact
        path={`${props.match.path}/success`}
        component={RegistrationSuccess}
      />
    </Switch>
  </>
);

export default RegisterContainer;
