import React from "react";

export function useToggle(initialValue = false) {
  const [value, setValue] = React.useState(initialValue);
  const enable = React.useCallback(() => setValue(true), []);
  const disable = React.useCallback(() => setValue(false), []);
  const toggle = React.useCallback(() => setValue((value) => !value), []);
  return { value, setValue, enable, disable, toggle };
}

export type UseToggleType = {
  value: boolean;
  enable: () => void;
  disable: () => void;
  toggle: () => void;
  setValue: (value: boolean) => void;
};
