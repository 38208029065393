import React from "react";

const NoTransactions = () => (
  <div className="no-transaction-detail">
    <h2 className="no-transaction-detail__title">0 Transactions</h2>
    <p>There are no transactions that match your search criteria.</p>
  </div>
);

export default NoTransactions;
