import useSWR from "swr";
import { fetcher } from "api/base";

export const dishListEndpoint = "api/v2/dish/dish/";

export function useDishes(params: UseDishesParams = {}, options: any = {}) {
  const p = new URLSearchParams({
    page_size: "100",
  });
  if (params.section) p.set("section", params.section.toString());
  if (params.expand) p.set("expand", params.expand.join(","));

  return useSWR(`${dishListEndpoint}?${p.toString()}`, fetcher, options);
}

type UseDishesParams = {
  expand?: string[];
  section?: number;
};
