import React from "react";

import QRCode from "qrcode.react";

const ShowQRCode = (props) => {
  return (
    props.auth.user.status === 3 && (
      <div className="qr_code_container">
        <div className="qr_code_column">
          <div className="qr_code_text_container">
            <p className="qr_code_title">This is your unique vendor code.</p>
            <p>
              Display this code to eaters after they've paid to capture their
              transaction for your Hawkker analytics and feedback
            </p>
          </div>
        </div>
        <div className="qr_code_column">
          <QRCode
            value={`hawkker:vendor:${props.profile.uuid}`}
            size={400}
            className="profile__qr qr_code_centered"
            level="Q"
            renderAs="svg"
          />
        </div>
        <div className="qr_code_column">
          <div className="qr_code_text_container">
            <p className="qr_code_comment_alert">
              Only show this code to customers for purchases of £5.00 or more.
            </p>
            <p>
              Get in touch with us through chat if you want a printed copy of
              your QR code.
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default ShowQRCode;
