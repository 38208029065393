import React from "react";
import { Link } from "react-router-dom";

const MenuItem = (props) => {
  const { to, closeSidebar, name, children } = props;
  const active = props.location.indexOf(to) === 0;
  const activeClass = active ? " active" : "";
  const className = props.className || name;

  if (to.startsWith("https://")) {
    return (
      <a href={to} target="_blank" rel="noreferrer noopener">
        <li
          className={`sidebar__menu__${className
            .replace(" ", "_")
            .toLowerCase()}`}
        >
          {name}
          {children}
        </li>
      </a>
    );
  }

  return (
    <Link to={to} onClick={closeSidebar}>
      <li
        className={`sidebar__menu__${className
          .replace(" ", "_")
          .toLowerCase()}${activeClass}`}
      >
        {name}
        {children}
      </li>
    </Link>
  );
};

export default MenuItem;
