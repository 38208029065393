import React from "react";
import { submitVendorLocation } from "../../services/locations";
import LocationForm, { defaultFromHour, defaultToHour } from "./LocationForm";
import MainError from "../forms/MainError";

export const prepareLocationPayload = (location) => {
  const location_type = location.location_type;

  const newOfferingIds = location.delivery_providers
    .map(({ typeId }) => typeId)
    .filter(Boolean);
  const offeringIds = [...newOfferingIds, ...location.offering_ids].filter(
    // filter unique
    (value, index, self) => self.indexOf(value) === index
  );

  const payload = {
    location_type,
    coordinates: location.coordinates,
    opening_hours: location.opening_hours,
    offering_ids: offeringIds,
    delivery_providers: location.delivery_providers.map(
      ({ _id, ...provider }) => provider
    ),
  };

  if (location_type === "fixed") {
    payload.street = location.street;
    payload.postcode = location.postcode;
  } else if (location_type === "market") {
    payload.market_id = location.market.id;
  } else if (location_type === "private" || location_type === "webshop") {
    payload.postcode_area_id = location.postcode_area.id;
  }
  return payload;
};

class AddLocation extends React.Component {
  state = {
    isFormValid: false,
    coordinates: {
      lat: null,
      lng: null,
    },
    location_type: "market",
    postcode_area: null,
    postcode: "",
    street: "",
    market: {
      id: null,
      name: "",
    },
    eat_in: false,
    offering_ids: [],
    opening_hours: [0, 1, 2, 3, 4, 5, 6].map((weekday) => ({
      weekday,
      from_hour: defaultFromHour,
      to_hour: defaultToHour,
      open: false,
      timeValid: true,
    })),
    delivery_providers: [],
  };

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    // TODO: validate

    const payload = prepareLocationPayload(this.state);

    submitVendorLocation(payload)
      .then(() => {
        // TODO: Toast with feedback
        this.props.history.push("/schedule");
      })
      .catch((error) => {
        this.setState({ error: error.data });
      });
  }

  render() {
    const {
      error,
      loading,
      opening_hours,
      coordinates,
      street,
      postcode,
      market,
      offering_ids,
      delivery_providers,
      location_type,
      postcode_area,
      eat_in,
      isFormValid,
    } = this.state;

    const nonFieldErrors = error != null && error.non_field_errors;

    if (loading) {
      return <div className="loading-placeholder menu-preload" />;
    }

    return (
      <div>
        <div className="grid-x align-center">
          <div className="grid-container menu-list__grid-container">
            <div className="menu-list__header">
              <div className="menu-list__header__left">
                <h1 className="menu-list__header__left__title">Add Location</h1>
                <h3>
                  Please provide the following information about your location
                  and opening time.
                </h3>
              </div>
            </div>

            <LocationForm
              errors={error}
              location={{
                opening_hours,
                coordinates,
                market,
                postcode,
                street,
                offering_ids,
                delivery_providers,
                location_type,
                postcode_area,
                eat_in,
              }}
              updateLocation={(location) => this.setState({ ...location })}
              onFormValidation={(isValid) =>
                this.setState({ isFormValid: isValid })
              }
            />

            {nonFieldErrors && (
              <>
                {nonFieldErrors.map((msg, i) => (
                  <MainError key={i} errorText={msg} />
                ))}
              </>
            )}

            <button
              type="submit"
              className="button expanded"
              ref="submit"
              onClick={this.onSubmit}
              disabled={!isFormValid}
            >
              Add Location
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddLocation;
