import React from "react";

import { useDishes } from "api/menu/useDishes";
import { MenuEmpty } from "components/menu/MenuEmpty";
import { useMenuSections } from "api/menu/useMenuSections";
import { MenuSectionNavigation } from "components/menu/MenuSectionNavigation";
import { MenuItemsWithSections } from "components/menu/MenuItemsWithSections";
import { MenuNavigation } from "components/menu/MenuNavigation";

export function Menu() {
  const sections = useMenuSections();
  const dishes = useDishes({ expand: ["section", "dietary_information"] });

  if (dishes.data == null || sections.data == null) return null;
  if (sections.data.length === 0) return <MenuEmpty />;

  return (
    <div>
      <MenuNavigation />

      <div className="px-8 pt-24 space-y-8">
        <MenuSectionNavigation sections={sections.data} />

        <MenuItemsWithSections sections={sections.data} items={dishes.data} />
      </div>
    </div>
  );
}
