export class APIErrorForbidden extends Error {}
export class APIErrorNotFound extends Error {}

export class APIError extends Error {
  status: number;
  response: Record<string, string>;

  constructor(
    statusText: string,
    status: number,
    response: Record<string, string>
  ) {
    super(statusText);

    this.status = status;
    this.response = response;
  }
}
