import * as onboarding from "../services/onboarding";

export const getSteps = () => {
  return (dispatch) => {
    return onboarding
      .getSteps()
      .then((response) => {
        dispatch({
          type: "GET_STEPS_SUCCESS",
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_STEPS_FAIL",
          error: error.data.error,
        });
      });
  };
};

const stageToKey = (stage) => {
  switch (stage) {
    case 1:
      return "stepOne";
    case 2:
      return "stepTwo";
    case 3:
      return "stepThree";
    case 4:
      return "StepPhotos";
    case 5:
      return "stepFive";
    case 6:
      return "stepSix";
    case 8:
      return "stepSchedule";
    default:
      return;
  }
};

export const submitStep = (data) => {
  const step = stageToKey(data.onboarding_stage);
  return (dispatch) => {
    dispatch({
      type: "SUBMIT_STEP_REQUEST",
      step,
    });
    return onboarding
      .updateStep(data)
      .then((response) => {
        dispatch({
          type: "SUBMIT_STEP_SUCCESS",
          data: response.data,
          step,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SUBMIT_STEP_FAIL",
          error: error.data.error,
          step,
        });
      });
  };
};
