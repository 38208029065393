import * as api from "./api";
import { REVIEWS } from "../api_urls";

export const getReviews = () =>
  api
    .get(REVIEWS)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getMoreReviews = (url) =>
  api
    .get(null, url)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
