import React from "react";
import moment from "moment";

class ReviewsTableRow extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
    this.toggleContent = this.toggleContent.bind(this);
  }
  toggleContent() {
    const { state } = this;
    const { container, innerContent } = this.refs;
    const { open } = this.state;

    state.open
      ? (container.style.height = "0px")
      : (container.style.height = `${innerContent.offsetHeight}px`);

    this.setState({
      open: !open,
    });
  }
  render() {
    const { toggleContent } = this;
    const { open } = this.state;
    const { review } = this.props;

    return (
      <React.Fragment>
        <thead className="reviews-table__thead">
          <tr className="reviews-table__tr" onClick={toggleContent}>
            <td>{moment(review.created_at).format("DD MMMM YYYY")}</td>
            <td>
              <span className="reviews-table__star">&#9733;</span>
              {review.overall_rating}.0
            </td>
            <td>
              £
              {review.purchase.details
                .reduce((a, b) => a + parseInt(b.price, 10), 0.0)
                .toFixed(2)}
            </td>
            <td className="reviews-table__open-btn-wrapper">
              <button
                className={`reviews-table__open-btn ${open ? "open" : ""}`}
              />
            </td>
          </tr>
        </thead>

        <tbody className="reviews-table__tbody">
          <tr>
            <td className="reviews-table__breakdown-container" colSpan="4">
              <div ref="container" className="review-table__breakdown-collapse">
                <table ref="innerContent">
                  <tbody>
                    <tr>
                      <td
                        className="reviews-table__breakdown-header"
                        colSpan="4"
                      >
                        Item(s) purchased
                      </td>
                    </tr>
                    {review.purchase.details.map((purchase) => (
                      <tr
                        key={purchase.id}
                        className="reviews-table__breakdown-row"
                      >
                        <td>{purchase.name}</td>
                        <td></td>
                        <td>£{purchase.price}</td>
                        <td></td>
                      </tr>
                    ))}
                    <tr className="reviews-table__breakdown-row">
                      <td colSpan="4">
                        <table className="reviews-table__comments">
                          <tbody>
                            <tr>
                              <td className="reviews-table__comments__title">
                                Comments
                              </td>
                            </tr>
                            <tr>
                              <td className="reviews-table__comments__text">
                                {review.text ? (
                                  review.text
                                ) : (
                                  <span className="reviews-table__comments__no-comment">
                                    This eater left no comment
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </React.Fragment>
    );
  }
}

export default ReviewsTableRow;
