import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editOffer } from "../../actions";

import InputCharCount from "../../components/forms/InputCharCount";
import Toggle from "../../components/forms/Toggle";
import MainError from "../../components/forms/MainError";

import validation from "../../helpers/validation";

class EditChalkboardOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.active,
      error: "",
      offer: this.props.offer || "",
    };
    this.toggleOffer = this.toggleOffer.bind(this);
    this.handleOfferChange = this.handleOfferChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  toggleOffer() {
    this.setState({
      active: !this.state.active,
    });
  }
  handleOfferChange(e) {
    this.setState({
      offer: e.target.value,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    const data = {
      offer: this.state.offer,
      offer_active: this.state.active,
    };
    this.refs.submit.disabled = true;
    if (!validation(e.target)) {
      this.refs.submit.disabled = false;
      window.scrollTo(0, 0);
      return;
    }
    this.props
      .editOffer(data)
      .then(() => {
        if (!this._isMounted) return;
        this.setState({
          error: "",
        });
        this.props.history.push({
          pathname: "/chalkboard",
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        this.refs.submit.disabled = false;
        this.setState({
          error: error.data.error,
        });
      });
  }
  render() {
    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Edit Chalkboard</h1>
            {this.state.error.length ? (
              <MainError errorText={this.state.error} />
            ) : (
              ""
            )}
            <form onSubmit={this.handleSubmit} noValidate>
              <Toggle
                label="Chalkboard Active?"
                clickHandler={this.toggleOffer}
                active={this.state.active}
              />
              <p>
                Setting your chalkboard to active will make it visible to
                eaters.
              </p>
              <InputCharCount
                changeHandler={this.handleOfferChange}
                errorText="Offer text must be entered if offer is active"
                label="Chalkboard"
                maxLength="140"
                required={this.state.active}
                rows="3"
                type="textarea"
                validate="chalkboard-offer"
                value={this.state.offer}
              />
              <button className="button expanded" ref="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  active: state.profile.offer_active,
  offer: state.profile.offer,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editOffer }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditChalkboardOffer);
