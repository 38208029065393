import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLocations, editLocation } from "../../actions";

import EditLocationForm from "./forms/EditLocationForm";

class EditLocation extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      submitDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    if (!this.props.locations.list.length) {
      this.props.getLocations();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleSubmit(location) {
    this.setState({ submitDisabled: true });
    const payLoad = {
      home_market: location,
    };
    this.props
      .editLocation(payLoad)
      .then(() => {
        this.props.history.push("/profile");
      })
      .catch((error) => {
        if (!this._isMounted) return;
        this.setState({
          error: error.data.error,
        });
        this.setState({ submitDisabled: false });
        window.scrollTo(0, 0);
      });
  }
  render() {
    if (!this.props.locations.list.length) {
      return <div className="loading-placeholder" />;
    }
    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Edit Location</h1>
            <EditLocationForm
              error={this.state.error}
              locations={this.props.locations}
              profile={this.props.profile}
              submitDisabled={this.state.submitDisabled}
              submitHandler={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  locations: state.markets.locations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getLocations, editLocation }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditLocation);
