import React from "react";
import { Field, useField } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { path } from "ramda";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/icons/delivery.svg";
import { ReactComponent as TakeawayIcon } from "../../assets/icons/takeaway.svg";
import { ReactComponent as EatInIcon } from "../../assets/icons/eat-in.svg";
import { ReactComponent as WebshopIcon } from "../../assets/icons/online-shop.svg";
import RadioItem from "../forms/RadioItem";

export function ServiceProvidersField({
  isPrivate,
  isWebshop,
  name,
  errors,
  offeringIdsName,
  locationOfferings,
}) {
  const eatInId = locationOfferings
    .filter((obj) => obj.key === "eat-in")
    .map((obj) => obj.id);

  const serviceProfidersField = useField(name);
  const offeringIdsField = useField(offeringIdsName);
  const [eatIn, setEatIn] = React.useState(
    () => offeringIdsField.input.value.indexOf(eatInId) !== -1
  );

  React.useEffect(() => {
    if (isPrivate) {
      offeringIdsField.input.onChange(
        locationOfferings
          .filter((obj) => obj.key === "delivery")
          .map((obj) => obj.id)
      );
    }
  }, [isPrivate]);

  React.useEffect(() => {
    if (isWebshop) {
      offeringIdsField.input.onChange(
        locationOfferings
          .filter((obj) => obj.key === "webshop")
          .map((obj) => obj.id)
      );
    }
  }, [isWebshop]);

  React.useEffect(() => {
    const offeringIds = [
      ...serviceProfidersField.input.value,
      // add eat_in option *without* a provider link
      ...(eatIn ? [{ type: "eat-in" }] : []),
    ]
      .map((provider) =>
        locationOfferings.find((offering) => offering.key === provider.type)
      )
      .filter(Boolean)
      .map((offering) => offering.id)
      // filter unique
      .filter((value, index, self) => self.indexOf(value) === index);

    if (offeringIdsField.input.value !== offeringIds) {
      offeringIdsField.input.onChange(offeringIds);
    }
  }, [serviceProfidersField.input.value, eatIn]);

  return (
    <>
      {isPrivate && (
        <p className="alert alert-warning">
          For Delivery Only Locations, you can only select Delivery. Eat-in and
          Collection are unavailable for this location type.
        </p>
      )}

      {isWebshop && (
        <p className="alert alert-warning">
          For Webshop Locations, you can only select Webshop Link.
        </p>
      )}

      <div style={{ marginBottom: 32 }}>
        <p className={isPrivate ? "text-muted" : ""}>
          Are customers able to eat the food or drink they have purchased whilst
          at this location, i.e. does this location offer “eat-in” to customers?
        </p>

        <RadioItem
          id="eat-in-disabled"
          item="Yes"
          disabled={isPrivate || isWebshop}
          checked={eatIn}
          changeHandler={() => setEatIn(true)}
        />
        <RadioItem
          id="eat-in-enabled"
          item="No"
          disabled={isPrivate || isWebshop}
          checked={!eatIn}
          changeHandler={() => setEatIn(false)}
        />
      </div>

      <FieldArray name={name}>
        {({ fields }) => (
          <div>
            <div className="service-providers__add service-providers--small">
              {["delivery", "takeaway", "eat-in", "webshop"].map((key) => {
                const Icon = providerIcons[key];
                const disabled =
                  (isPrivate && key !== "delivery") ||
                  (isWebshop && key !== "webshop");
                return (
                  <button
                    className={`service-providers__option ${
                      disabled ? "service-providers__option--disabled" : ""
                    }`}
                    key={key}
                    type="button"
                    onClick={() =>
                      fields.push({ type: key, name: "", url: "" })
                    }
                    disabled={disabled}
                  >
                    <div className="service-providers__icon">
                      {Icon != null && <Icon />}
                      <PlusIcon className="service-providers__plus-icon" />
                    </div>
                    {key === "takeaway" && "Add Collection Ordering Link"}
                    {key === "delivery" && "Add Delivery Provider Link"}
                    {key === "eat-in" && "Add Reservation Booking Link"}
                    {key === "webshop" && "Add Webshop Link"}
                  </button>
                );
              })}
            </div>

            {fields.map((fieldName, index) => (
              <div key={fieldName} className="service-provider-link">
                <div className="service-provider-link__row">
                  <div>
                    <label htmlFor={`${fieldName}.type`}>Type:</label>

                    <Field
                      id={`${fieldName}.type`}
                      name={`${fieldName}.type`}
                      render={({ input }) => {
                        const type = locationOfferings.find(
                          (offering) => offering.key === input.value
                        );
                        return (
                          <span className="readonly-input">{type?.name}</span>
                        );
                      }}
                    />
                  </div>
                  <Field
                    name={`${fieldName}.type`}
                    render={({ input: typeInput }) =>
                      typeInput?.value !== "webshop" && (
                        <div>
                          <label htmlFor={`${fieldName}.name`}>Provider:</label>
                          <Field
                            id={`${fieldName}.name`}
                            name={`${fieldName}.name`}
                            type="text"
                            placeholder="e.g. Deliveroo"
                            tabIndex={1}
                            render={({ input, meta }) => (
                              <>
                                <input {...input} />
                                {
                                  <Error
                                    error={path([index, "name"], errors)}
                                  />
                                }
                              </>
                            )}
                          />
                        </div>
                      )
                    }
                  />
                  <button
                    type="button"
                    className="dish__delete-panel__delete"
                    onClick={() => fields.remove(index)}
                  >
                    Remove
                  </button>
                </div>
                <div>
                  <label htmlFor={`${fieldName}.url`}>
                    URL Link or Phone Number:
                  </label>
                  <Field
                    id={`${fieldName}.url`}
                    name={`${fieldName}.url`}
                    type="text"
                    render={({ input, meta }) => (
                      <>
                        <input {...input} />
                        {<Error error={path([index, "url"], errors)} />}
                      </>
                    )}
                    tabIndex={1}
                  />
                </div>
                <hr />
              </div>
            ))}
          </div>
        )}
      </FieldArray>
    </>
  );
}

function Error({ error }) {
  if (error == null || !error) return null;

  return error.map((msg, index) => (
    <p key={index} className="validation-message">
      {msg}
    </p>
  ));
}

const providerIcons = {
  delivery: DeliveryIcon,
  takeaway: TakeawayIcon,
  "eat-in": EatInIcon,
  webshop: WebshopIcon,
};
