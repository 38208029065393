import * as React from "react";
import { connect } from "react-redux";
import Downshift from "downshift";

import { getPostcodeAreas } from "actions/locations";

function PurePostcodeAreaField({
  name,
  value,
  onChange,
  postcodeAreas,
  getPostcodeAreas,
  ...props
}) {
  React.useEffect(() => {
    getPostcodeAreas();
  }, []);

  const items = postcodeAreas.map((pa) => ({
    ...pa,
    postcode: pa.postcode.toLowerCase().replace(" ", ""),
    displayName: `${pa.postcode}, ${pa.region}`,
  }));

  return (
    <Downshift
      initialSelectedItem={value}
      initialInputValue={value ? `${value.postcode}, ${value.region}` : ""}
      onChange={(selection) => onChange(selection)}
      itemToString={(item) => (item ? item.displayName : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => {
        const searchValue = inputValue?.toLowerCase().replace(" ", "");
        return (
          <div className="postcodearea-field">
            <div
              style={{ display: "inline-block" }}
              {
                // @ts-ignore
                ...getRootProps({}, { suppressRefError: true })
              }
            >
              <input type="text" placeholder="e.g. NG1" {...getInputProps()} />
            </div>
            {isOpen && (
              <ul {...getMenuProps()} className="postcodearea-field-menu">
                {items
                  .filter(
                    (item) =>
                      !searchValue || item.postcode.startsWith(searchValue)
                  )
                  .slice(0, 5)
                  .map((item, index) => (
                    <li
                      className="postcodearea-field-menu-item"
                      {...getItemProps({
                        key: item.postcode,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? "lightgray" : "white",
                          fontWeight: selectedItem === item ? "bold" : "normal",
                        },
                      })}
                    >
                      {item.displayName}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        );
      }}
    </Downshift>
  );
}

export const PostcodeAreaField = connect(
  (state) => ({
    // @ts-ignore
    postcodeAreas: state.locations.postcodeAreas.list,
  }),
  { getPostcodeAreas }
)(PurePostcodeAreaField);
