import React from "react";
import moment from "moment";

const DayDetail = (props) => {
  const { latest, prev } = props;
  if (!latest || !prev) return "";
  return (
    <React.Fragment>
      <div className="modal__detail__container">
        <span className="modal__detail__container__title">Check In Time</span>
        {latest.check_in
          ? moment(latest.check_in, "HH:mm:ss.SSS").format("HH:mm")
          : "--"}
      </div>
      <div className="modal__detail__container">
        <span className="modal__detail__container__title">Check Out Time</span>
        {latest.check_out
          ? moment(latest.check_out, "HH:mm:ss.SSS").format("HH:mm")
          : "--"}
      </div>
      <div className="modal__detail__container">
        <span className="modal__detail__container__title">Transactions</span>
        {latest.transactions || "0"}
        {latest.transactions > prev.transactions ? (
          <span className="past-week__arrow up" />
        ) : (
          ""
        )}
        {latest.transactions < prev.transactions ? (
          <span className="past-week__arrow down" />
        ) : (
          ""
        )}
      </div>
      <div className="modal__detail__container">
        <span className="modal__detail__container__title">Sales</span>£
        {latest.sales || "0"}
        {latest.sales < prev.sales ? (
          <span className="past-week__arrow down" />
        ) : (
          ""
        )}
        {latest.sales > prev.sales ? (
          <span className="past-week__arrow up" />
        ) : (
          ""
        )}
      </div>
      <div className="modal__detail__container expanded">
        <span className="modal__detail__container__title">
          Average Wait Time (Mins)
        </span>
        {latest.wait_time
          ? moment(latest.wait_time, "m.s").format("m[mins] s[sec]")
          : "--"}
      </div>
    </React.Fragment>
  );
};

export default DayDetail;
