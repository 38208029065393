import * as api from "./api";

import {
  PROFILE,
  EDIT_BUSINESS_INFO,
  EDIT_ALLERGENS,
  EDIT_INGREDIENTS,
  EDIT_LOCATION,
  EDIT_IMAGES,
  EDIT_PROFILE,
  EDIT_ADDRESS,
  VENDOR_LIST,
  CHECKIN,
  CHECKOUT_TIME,
  CHECKOUT,
  GENERAL_SETTINGS,
} from "../api_urls";

export const getProfile = () =>
  api
    .get(PROFILE)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getGeneralSettings = () =>
  api
    .get(GENERAL_SETTINGS)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getVendorList = () =>
  api
    .get(VENDOR_LIST)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const editOffer = (data) =>
  api
    .patch(PROFILE, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const editBusinessInfo = (data) =>
  api
    .patch(EDIT_BUSINESS_INFO, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const editAllergens = (data) =>
  api
    .patch(EDIT_ALLERGENS, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const editIngredients = (data) =>
  api
    .patch(EDIT_INGREDIENTS, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const editLocation = (location) =>
  api
    .patch(EDIT_LOCATION, location)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const editImages = (data) =>
  api
    .patch(EDIT_IMAGES, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const editProfile = (data) =>
  api
    .patch(EDIT_PROFILE, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
export const editAddress = (data) =>
  api
    .patch(EDIT_ADDRESS, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const checkin = (data) =>
  api
    .patch(CHECKIN, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const checkoutTime = (data) =>
  api
    .patch(CHECKOUT_TIME, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const checkout = (data) =>
  api
    .patch(CHECKOUT, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
