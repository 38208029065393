import * as api from "./api";
import {
  ALLERGENS,
  CUISINES,
  DIETARY,
  DISHES,
  ACTIVE_DISHES,
  INACTIVE_DISHES,
} from "../api_urls";

export const getAllergens = () =>
  api
    .get(ALLERGENS)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getCuisines = () =>
  api
    .get(CUISINES)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getDietaryPreferences = () =>
  api
    .get(DIETARY)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

// Moved
export const submitDish = (dish) =>
  api
    .post(DISHES, dish)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

// Moved
export const getDishes = (type) =>
  api
    .get(
      `${type === "active" ? ACTIVE_DISHES : INACTIVE_DISHES}&page_size=100`,
      null,
      {
        deleted: false,
      }
    )
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getDish = (dishId) =>
  api
    .get(DISHES + dishId + "/")
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getNextDishes = (type, url) =>
  api
    .get(type === "active" ? ACTIVE_DISHES : INACTIVE_DISHES, url)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const setDishActiveStatus = (dishId, active) =>
  api
    .patch(DISHES + dishId + "/", { active })
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const setTempPrice = (dishId, price) =>
  api
    .patch(DISHES + dishId + "/", { temporary_price: price })
    .then((response) => response)
    .catch((error) => Promise.reject(error));

// Moved
export const deleteDish = (dishId) =>
  api
    .patch(DISHES + dishId + "/", { deleted: true })
    .then((response) => response)
    .catch((error) => Promise.reject(error));

// Moved
export const editDish = (dishId, data) =>
  api
    .patch(DISHES + dishId + "/", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
