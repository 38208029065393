import {
  GET_AUTH_USER,
  LOGIN,
  FORGOTTEN_PASSWORD,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  LOGOUT,
} from "../api_urls";
import * as api from "./api";

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => localStorage.getItem("token");

export const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUser = () => localStorage.getItem("user");

export const setIntercomHash = (hash) => {
  localStorage.setItem("intercomHash", hash);
};

export const getIntercomHash = () => localStorage.getItem("intercomHash");

export const isLoggedIn = () => getToken();

export const getAuthUser = () => {
  return api
    .get(GET_AUTH_USER)
    .then((response) => {
      if (response.data.user.status === 5) {
        return Promise.reject({
          data: {
            error: "Your account has been suspended.",
          },
        });
      }
      setUser(response.data.user);
      setToken(response.data.token);
      return response;
    })
    .catch((error) => Promise.reject(error));
};

export const login = (credentials) => {
  localStorage.clear();
  return api
    .post(LOGIN, {
      email: credentials.email,
      password: credentials.password,
    })
    .then((response) => {
      if (response.data.user.status === 5) {
        return Promise.reject({
          data: {
            error: "Your account has been suspended.",
          },
        });
      }
      setToken(response.data.token);
      setUser(response.data.user);
      setIntercomHash(response.data.intercom_user_hash_web);
      return response;
    })
    .catch((error) => Promise.reject(error));
};

export const logout = () =>
  api
    .del(LOGOUT)
    .then((response) => {
      localStorage.clear();
      window.Intercom("shutdown");
      return response;
    })
    .catch((error) => {
      localStorage.clear();
      window.Intercom("shutdown");
      return Promise.reject(error);
    });

export const forgottenPassword = (email) =>
  api
    .post(FORGOTTEN_PASSWORD, { email })
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const changePassword = (data) =>
  api
    .patch(CHANGE_PASSWORD, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const deleteAccount = () =>
  api
    .del(DELETE_ACCOUNT)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
