import React from "react";

export function useClickOutside(
  callback: (event: React.SyntheticEvent) => void
) {
  const ref = React.useRef<any>();

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !hasIgnoreClass(event, "ignore-outside-click") &&
      ref.current &&
      !ref.current.contains(event.target)
    ) {
      event.stopPropagation();
      event.preventDefault();
      callback((event as unknown) as React.SyntheticEvent);
    }
  };

  React.useEffect(() => {
    window.addEventListener("click", handleClickOutside, true);
    return () => {
      window.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return ref;
}

const hasIgnoreClass = (e: any, ignoreClass: string): boolean => {
  let el = e.target;

  while (el) {
    if (el.classList?.contains(ignoreClass)) return true;
    el = el.parentElement;
  }

  return false;
};
