import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editImages } from "../../actions";

import EditImagesForm from "./forms/EditImagesForm";

class EditImages extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      submitDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleSubmit(data) {
    if (data.noNewImages) {
      this.props.history.push("/profile");
    } else {
      return this.props
        .editImages({
          photos: data.photos,
          delete_photos: data.delete,
        })
        .then(() => {
          this.props.history.push("/profile");
        })
        .catch((error) => {
          if (!this._isMounted) return;
          this.setState({
            error: error.data.error,
            submitDisabled: false,
          });
          window.scrollTo(0, 0);
          return Promise.reject();
        });
    }
  }
  render() {
    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Edit Photos</h1>
            <EditImagesForm
              history={this.props.history}
              error={this.state.error}
              profile={this.props.profile}
              submitDisabled={this.state.submitDisabled}
              submitHandler={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editImages }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditImages);
