import React from "react";

import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";
import QrReader from "react-qr-reader";
import { connect } from "react-redux";

import { postTransaction } from "../../actions/transactions";
import { CameraPermissionInformationText } from "../permissions/permissionInformation";

const setError = (errorMessage) => (dispatch) => {
  dispatch({
    type: "SET_ERROR",
    errorString: errorMessage,
  });
};

class RedeemEaterReward extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "awaiting_qr",
      cameraError: "",
      delay: 300,
      qr_id: null,
      coins: null,
      amount: null,
      error: null,
      permissionInstructionsShown: false,
    };
    this.handleScanSuccess = this.handleScanSuccess.bind(this);
    this.handleScanError = this.handleScanError.bind(this);
    this.check_qr = this.check_qr.bind(this);
    this.canUseCamera = this.canUseCamera.bind(this);
    this.togglePermissionInstructions = this.togglePermissionInstructions.bind(
      this
    );
  }

  canUseCamera() {
    return this.state.cameraError === "";
  }

  check_qr() {
    this.props
      .postTransaction({
        qr_id: this.state.qr_id,
        note: "Added points for scanning Qr",
      })
      .then((response) => {
        if (response !== undefined) {
          this.setState({
            status: "points_added",
            coins: response.data.coins,
            amount: response.data.amount,
          });
        } else {
          this.setState({
            status: "error",
          });
        }
      });
  }

  handleScanSuccess(data) {
    if (data) {
      let parts = data.split(":");
      if (parts[0] !== "hawkker" || parts[1] !== "eater") {
        this.props.setError("Incorrect qr-code");
        this.setState({
          status: "error",
        });
        return;
      }

      this.setState(
        {
          qr_id: parts[2],
          status: "checking_qr",
        },
        this.check_qr
      );
    } else {
      if (!this.canUseCamera()) {
        this.props.setError("Incorrect QR code or no QR code found");
      }
    }
  }

  handleScanError(err) {
    this.setState({
      cameraError: err.name,
    });
  }

  cannotUseCameraReason() {
    switch (this.state.cameraError) {
      case "":
        return "";
      case "NotAllowedError":
        return "No permission to use the camera";
      default:
        return "Cannot use camera";
    }
  }

  openImageDialog() {
    this.refs.qrReader1.openImageDialog();
  }

  togglePermissionInstructions() {
    this.setState({
      permissionInstructionsShown: !this.state.permissionInstructionsShown,
    });
  }

  render() {
    return (
      <div>
        {this.state.status !== "points_added" &&
          this.state.status !== "checking_qr" &&
          this.canUseCamera() && (
            <div className="qr_reader_container">
              <h4>Place QR code within the guides provided</h4>

              <QrReader
                delay={this.state.delay}
                onError={this.handleScanError}
                onScan={this.handleScanSuccess}
                style={{
                  maxWidth: "400px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
          )}

        {this.state.status !== "points_added" &&
          this.state.status !== "checking_qr" &&
          !this.canUseCamera() && (
            <div className="qr_reader_container">
              <div>
                <h3 className="notification">
                  {this.cannotUseCameraReason()}
                  {this.state.cameraError === "NotAllowedError" && (
                    <span
                      className="vendor_location_give_permission_link"
                      onClick={this.togglePermissionInstructions}
                    >
                      Give permission
                    </span>
                  )}
                </h3>
                <br />
              </div>

              {this.state.permissionInstructionsShown && (
                <div className="permission_instructions_container">
                  <CameraPermissionInformationText />
                </div>
              )}

              <p>
                To process the voucher QR code, manually take a photo of the
                code. Then click the button
              </p>

              <button
                className="button"
                onClick={this.openImageDialog.bind(this)}
              >
                Submit photo of QR code
              </button>

              <QrReader
                ref="qrReader1"
                delay={this.state.delay}
                onError={this.handleScanError}
                onScan={this.handleScanSuccess}
                style={{
                  width: "250px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                legacyMode={true}
              />
            </div>
          )}

        {this.state.status === "checking_qr" && (
          <div className="qr_reader_container">
            <h3>Checking code</h3>
          </div>
        )}

        {this.state.status === "points_added" && (
          <div className="qr_reader_container">
            <h2>Reward Redeemed</h2>
            <p className="voucher_value">Voucher value</p>
            <p className="amount">£ {this.state.amount}</p>
            <h4>Discount your customer the above amount from their bill</h4>
            <p className="point_count">You earn {this.state.coins} points</p>
            <Link to="/qrcode" className="button">
              Back to Your QR Code
            </Link>
            <br />
            <div className="link_to_wallet_container">
              <Link to="/wallet" className="link_to_wallet">
                See Wallet
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ postTransaction, setError }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RedeemEaterReward);
