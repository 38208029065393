import axios from "axios";
import * as auth from "./auth";
import { SERVER } from "../api_urls";
import { logout } from "../actions";
import store from "../store";

export const get = (endpoint, url = null, params = null) => {
  return axios({
    url: url ? url : SERVER + endpoint,
    method: "get",
    params,
    responseType: "json",
    headers: {
      Authorization: `token ${auth.getToken()}`,
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch(logout());
      }
      return Promise.reject(error.response);
    });
};

export const post = (endpoint, data) => {
  return axios({
    url: SERVER + endpoint,
    method: "post",
    responseType: "json",
    headers: {
      Authorization: auth.getToken() ? `token ${auth.getToken()}` : null,
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch(logout());
      }
      return Promise.reject(error.response);
    });
};

export const patch = (endpoint, data, params = null) => {
  return axios({
    url: SERVER + endpoint,
    method: "patch",
    params,
    responseType: "json",
    headers: {
      Authorization: `token ${auth.getToken()}`,
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch(logout());
      }
      return Promise.reject(error.response);
    });
};

export const del = (endpoint, data) => {
  return axios({
    url: SERVER + endpoint,
    method: "delete",
    responseType: "json",
    headers: {
      Authorization: `token ${auth.getToken()}`,
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};
