import React from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { FORM_ERROR } from "final-form";

import { forgottenPassword } from "services/auth";
import ForgottenPasswordForm from "components/forgotten-password/ForgottenPasswordForm";
import ForgottenPasswordSuccess from "components/forgotten-password/ForgottenPasswordSuccess";

function ForgottenPasswordContainer(props) {
  const { isLoggedIn, from, history } = props;

  const [email, setEmail] = React.useState("");
  const [resendError, setResendError] = React.useState<string | null>(null);
  const [resendSuccess, setResendSuccess] = React.useState<boolean>(false);

  const handleForgottenPassword = (values) => {
    setEmail(values.email);
    return forgottenPassword(values.email)
      .then(() => {
        history.push("/forgotten-password/success");
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        return {
          [FORM_ERROR]: error.data.error,
        };
      });
  };

  const handleResend = () => {
    if (!email) {
      history.push("/forgotten-password");
      return;
    }

    forgottenPassword(email)
      .then(() => {
        setResendSuccess(true);
      })
      .catch(() => {
        setResendError("There was a problem resending the email.");
      });
  };

  if (isLoggedIn) {
    return <Redirect exact to={from || "/dashboard"} />;
  }

  return (
    <div className="grid-x forgotten-password">
      <div className="forgotten-password__inner cell medium-7">
        <Link to="/" className="step-back">
          Go back
        </Link>
        <Switch>
          <Route
            exact
            path="/forgotten-password"
            render={(props) => (
              <ForgottenPasswordForm
                {...props}
                onSubmit={handleForgottenPassword}
              />
            )}
          />
          <Route
            exact
            path="/forgotten-password/success"
            render={(props) => (
              <ForgottenPasswordSuccess
                {...props}
                email={email}
                resendSuccess={resendSuccess}
                resendError={resendError}
                resend={handleResend}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.user,
});

export default connect(mapStateToProps)(ForgottenPasswordContainer);
