import React from "react";

import PastWeekRow from "./PastWeekRow";

const PastWeek = (props) => {
  const { showDetail, latestTransactions } = props;

  return (
    <React.Fragment>
      <h4>Your Past Week at a Glance</h4>
      <table className="past-week">
        <thead>
          <tr>
            <td>Date</td>
            <td className="desktop-only">Check In Time</td>
            <td className="desktop-only">Check Out Time</td>
            <td>Transactions</td>
            <td>Sales</td>
            <td className="desktop-only">Av. Wait Time (Mins)</td>
          </tr>
        </thead>
        <tbody>
          {latestTransactions.lastWeek.map((_, i) => {
            const day = latestTransactions.lastWeek[i];
            const prevDay = latestTransactions.previousWeek[i];
            return (
              <PastWeekRow
                key={day.date}
                day={day}
                prevDay={prevDay}
                showDetail={showDetail}
              />
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default PastWeek;
