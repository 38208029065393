import * as dashboard from "../services/dashboard";

const setError = (error) => ({
  type: "SET_ERROR",
  error,
});

export const getDashboardSummary = () => (dispatch) =>
  dashboard
    .getDashboardSummary()
    .then((response) => {
      dispatch({
        type: "GET_DASHBOARD_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch(setError(error));
    });

export const getMostPurchased = () => (dispatch) =>
  dashboard
    .getMostPurchased()
    .then((response) => {
      dispatch({
        type: "GET_MOST_PURCHASED_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch(setError(error));
    });

export const getCustomerType = () => (dispatch) =>
  dashboard
    .getCustomerType()
    .then((response) => {
      dispatch({
        type: "GET_CUSTOMER_TYPE_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch(setError(error));
    });

export const getHighestRated = () => (dispatch) =>
  dashboard
    .getHighestRated()
    .then((response) => {
      dispatch({
        type: "GET_HIGHEST_RATED_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch(setError(error));
    });

export const getLatestTransactions = () => (dispatch) =>
  dashboard
    .getLatestTransactions()
    .then((response) => {
      dispatch({
        type: "GET_LATEST_TRANSACTIONS_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch(setError(error));
    });
