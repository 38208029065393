import React from "react";
import classnames from "classnames";
import { DragDropContext } from "react-beautiful-dnd";

import { CaretUpIcon, DeleteIcon, DragIcon } from "icons";
import { IconButton } from "components/generic/IconButton";
import { SectionLabel } from "components/generic/SectionLabel";
import { MenuBuilderItems } from "components/menu/MenuBuilderItems";
import { MenuBuilderItemAddButton } from "components/menu/MenuBuilderItemAddButton";
import { ConfirmationDialog } from "components/ConfirmationDialog";

export function MenuBuilderSections(props: MenuBuilderSectionsProps) {
  if (props.sections == null || props.items == null) return null;

  const handleReorder = () => {};

  return (
    <div className={classnames(props.className)}>
      <SectionLabel>Created Menu Sections</SectionLabel>

      <DragDropContext onDragEnd={handleReorder}>
        {props.sections.map((section) => (
          <div key={section.id} className="my-2">
            <div
              className={classnames(
                "mb-2 px-6 py-3 flex items-center border-l-2 space-x-2 cursor-pointer",
                {
                  "border-brand-blue": props.activeSection === section.id,
                }
              )}
              onClick={() => props.onSectionSelect(section.id)}
            >
              <IconButton variant="terniary">
                <DragIcon />
              </IconButton>

              <span>{section.name}</span>

              <div className="flex-1" />
              <ConfirmationDialog
                text={`Do you really want to delete section ${section.name}?`}
                onConfirm={() => props.onSectionDestroy(section.id)}
              >
                <IconButton variant="terniary">
                  <DeleteIcon />
                </IconButton>
              </ConfirmationDialog>

              <IconButton variant="terniary">
                <CaretUpIcon
                  className={
                    props.activeSection === section.id && "transform rotate-180"
                  }
                />
              </IconButton>
            </div>
            {props.activeSection === section.id && (
              <>
                <MenuBuilderItemAddButton />
                <MenuBuilderItems
                  className="mt-2"
                  items={props.items.filter(
                    (dish) => dish.section?.id === section.id
                  )}
                  onDelete={props.onItemDestroy}
                />
              </>
            )}
          </div>
        ))}
      </DragDropContext>
    </div>
  );
}

type MenuBuilderSectionsProps = {
  className?: string;
  sections: any[];
  activeSection: number | null;
  onSectionSelect: (id: any) => void;
  onSectionDestroy: (id: any) => void;
  items: any[];
  onItemDestroy: (id: any) => Promise<void>;
  onItemReorder: (id: any) => void;
};
