import React from "react";
import { DragDropContext } from "react-beautiful-dnd";

import { MenuItemsContainer } from "components/menu/MenuItemsContainer";
import { MenuSectionForm } from "components/menu/MenuSectionForm";
import { SectionLabel } from "components/generic/SectionLabel";
import { MenuSections } from "components/menu/MenuSections";
import { useMenuSectionsOps } from "components/menu/useMenuSectionsOps";
import { MenuNavigation } from "components/menu/MenuNavigation";

export function MenuManual() {
  const menuSectionsOps = useMenuSectionsOps();

  return (
    <div>
      <MenuNavigation />

      <div className="flex pt-20 bg-white min-h-screen">
        <div className="flex-1 flex flex-col space-y-3 p-6 bg-gray-100">
          <MenuSectionForm onSubmit={menuSectionsOps.handleCreate} />

          <SectionLabel>Your menu sections</SectionLabel>

          <DragDropContext onDragEnd={menuSectionsOps.handleReorder}>
            {menuSectionsOps.sections.data != null && (
              <MenuSections
                sections={menuSectionsOps.sections.data}
                activeSection={menuSectionsOps.activeSection}
                onSelect={menuSectionsOps.handleSelect}
                onUpdate={menuSectionsOps.handleUpdate}
                onDelete={menuSectionsOps.handleDestroy}
              />
            )}
          </DragDropContext>
        </div>

        <MenuItemsContainer
          className="flex-1 max-w-3xl mx-auto flex flex-col space-y-4 p-6"
          sections={menuSectionsOps.sections.data}
          activeSection={menuSectionsOps.activeSection}
        />
      </div>
    </div>
  );
}
