import React from "react";
import {
  deleteVendorLocation,
  getLocationOfferings,
  getVendorLocation,
  updateVendorLocation,
} from "../../services/locations";
import LocationForm from "./LocationForm";
import { prepareLocationPayload } from "./AddLocation";
import ModalPortal from "../../containers/ModalPortal";
import MainError from "../forms/MainError";

class EditLocation extends React.Component {
  state = {
    isFormValid: true,
    location: this.props.location,
    isLoading: false,
    showDeleteModal: false,
    showLocationModal: true,
    error: {},
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleLocationModal = this.toggleLocationModal.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    let location, offering;
    if (!this.state.location) {
      // Fetch location after e.g. refresh
      const locationID = this.props.match.params.locationId;

      const response = await Promise.all([
        getVendorLocation(locationID),
        getLocationOfferings(),
      ]);

      location = response[0].data;
      offering = response[1].data.results;
      this.setState({ offering });
    } else if (!this.state.offering) {
      location = this.state.location;
      // We need to fetch `locationOffering` options even when we receive location data as props.
      offering = (await getLocationOfferings()).data.results;
      this.setState({ offering });
    } else {
      offering = this.state.offering;
    }

    this.setState({
      location: {
        ...location,
        delivery_providers: location.delivery_providers.map((provider) => ({
          ...provider,
          url: provider.url.replace(/^tel:/, ""),
        })),
        eat_in: location.offering.find((name) => name === "Eat-in"),
        offering_ids: location.offering
          .map((name) => {
            const obj = offering.find((obj) => obj.name === name);
            return obj != null ? obj.id : null;
          })
          .filter(Boolean),
      },
      isLoading: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const location = this.state.location;
    const payload = prepareLocationPayload(location);

    updateVendorLocation(location.id, payload)
      .then(() => {
        // TODO: Toast with feedback
        this.props.history.push("/schedule");
      })
      .catch((error) => {
        this.setState({ error: error.data });
      });
  }

  onDelete(e) {
    e.preventDefault();

    deleteVendorLocation(this.state.location.id)
      .then(() => {
        // TODO: Toast with feedback
        this.props.history.push("/schedule");
      })
      .catch((error) => {
        // TODO: handle submit errors
        this.setState({ error: error.data });
      });
  }

  toggleDeleteModal() {
    this.setState((state) => ({ showDeleteModal: !state.showDeleteModal }));
  }

  toggleLocationModal() {
    this.setState((state) => ({ showLocationModal: !state.showLocationModal }));
  }

  render() {
    const {
      isLoading,
      location,
      showDeleteModal,
      showLocationModal,
      isFormValid,
      error,
    } = this.state;
    const hasLocationData = location && Object.keys(location).length > 0;

    const nonFieldErrors = error != null && error.non_field_errors;

    if (isLoading) {
      return <div className="loading-placeholder menu-preload" />;
    }

    return (
      <div className="grid-x align-center">
        <ModalPortal
          showModal={showDeleteModal}
          closeModal={this.toggleDeleteModal}
        >
          <h3>Are you sure you want to delete this location?</h3>
          <p>
            Deleting this location will remove it from your schedule entirely.
          </p>
          <div className="dish__delete-modal__btn-container">
            <button onClick={this.toggleDeleteModal}>Keep Location</button>
            <button
              ref="submitDeleteBtn"
              className="button dish__delete-modal__btn"
              onClick={this.onDelete}
            >
              Delete Location
            </button>
          </div>
        </ModalPortal>
        <ModalPortal
          showModal={showLocationModal}
          closeModal={this.toggleLocationModal}
        >
          <h3>Are you changing your map location?</h3>
          <p>
            If you are, delete this location using the button at the bottom of
            this page and add a new location from the Schedule Menu. If you are
            just changing opening hours or service information then go ahead.
          </p>
          <div className="dish__delete-modal__btn-container">
            <button onClick={this.toggleLocationModal}>I understand</button>
          </div>
        </ModalPortal>
        <div className="grid-container menu-list__grid-container">
          <div className="menu-list__header">
            <div className="menu-list__header__left">
              <h1 className="menu-list__header__left__title">Edit Location</h1>
              <h3>
                Please provide the following information about your location and
                opening time.
              </h3>
            </div>
          </div>

          {hasLocationData && (
            <LocationForm
              errors={error}
              location={location}
              updateLocation={(location) => this.setState({ location })}
              onFormValidation={(isValid) => {
                console.log(`isEditvalid?: ${isValid}`);
                this.setState({ isFormValid: isValid });
              }}
              initiallyValid
            />
          )}

          {nonFieldErrors != null && nonFieldErrors.length && (
            <>
              {nonFieldErrors.map((msg, i) => (
                <MainError key={i} errorText={msg} />
              ))}
            </>
          )}

          <button
            type="submit"
            className="button expanded"
            ref="submit"
            onClick={this.onSubmit}
            disabled={!isFormValid}
          >
            Update Location
          </button>

          <div className="grid-x align-center">
            <button
              className="dish__delete-panel__delete"
              onClick={this.toggleDeleteModal}
            >
              Delete Location
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default EditLocation;
