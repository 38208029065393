import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const { user } = rest;

        if (!user) {
          return <Redirect to="/" from={props.location.pathname} />;
        }

        switch (user.status) {
          case 1: // INPROGRESS
            return (
              <Redirect to="/create-profile" from={props.location.pathname} />
            );
          default:
            return <Component {...props} />;
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PrivateRoute);
