import { combineReducers } from "redux";
import auth from "./auth";
import onboarding from "./onboarding";
import dishes from "./dishes";
import markets from "./markets";
import register from "./register";
import profile from "./profile";
import global from "./global";
import transactions from "./transactions";
import dashboard from "./dashboard";
import reviews from "./reviews";
import purchases from "./purchases";
import vendors from "./vendors";
import generalSettings from "./generalSettings";
import locations from "./locations";

const appReducer = combineReducers({
  auth,
  onboarding,
  dishes,
  markets,
  register,
  profile,
  global,
  transactions,
  dashboard,
  reviews,
  purchases,
  vendors,
  generalSettings,
  locations,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
