import React from "react";

const FilterSummary = (props) => {
  const { dateRange, rating, dish, clickHandler, open } = props;

  return (
    <div className="filter-summary">
      <div className="filter-summary__left">
        <div className="filter-summary__left__currently-showing">
          <span className="filter-summary__left__currently-showing__time">
            Showing: {dateRange}
          </span>
          <span className="filter-summary__left__currently-showing__rating-dishes">
            Rating: {rating}, {dish}
          </span>
        </div>
      </div>
      <button
        onClick={clickHandler}
        className={`filter-summary__show-filters-btn${open ? " open" : ""}`}
      >
        Edit
      </button>
    </div>
  );
};

export default FilterSummary;
