import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useForm, useFormState } from "react-final-form";

import {
  CaretLeftIcon,
  CaretRightIcon,
  MinusIcon,
  PlusIcon,
  UploadIcon,
} from "icons";
import { useUploadResults } from "api/menu/useUploadResults";
import { MenuUploadButton } from "components/menu/MenuUploadButton";

export function MenuBuilderImage() {
  const uploadResults = useUploadResults();
  const [activePage, setActivePage] = React.useState(0);

  const formState = useFormState();
  const form = useForm();

  const handleClick = (value) => {
    let { active } = formState;

    if (active == null) {
      active = "name";
    }

    const field = form.getFieldState(active);
    if (field == null) return;

    const cleanPrice = (value) =>
      value.replace(/^[^\d]*(\d+([.,]\d+)?).*$/, "$1");
    if (active.endsWith("price")) {
      form.change(active, cleanPrice(value));
    } else {
      form.change(active, [field.value, value].filter(Boolean).join(" "));
    }
  };

  if (uploadResults.data == null) return null;

  const { urls, blocks } = uploadResults.data;

  return (
    <div className="w-full h-full flex flex-col">
      <TransformWrapper initialScale={0.5} minScale={0.5}>
        {({ zoomIn, zoomOut }) => (
          <>
            <TransformComponent wrapperClass="w-full flex-1 rounded-lg overflow-hidden bg-gray-200">
              <div className="relative inline-block">
                <img src={urls[activePage]} className="max-w-none" />
                <svg
                  viewBox="0 0 1 1"
                  preserveAspectRatio="none"
                  className="absolute top-0 left-0 h-full w-full"
                >
                  {blocks
                    .filter(({ Page }) => Page === activePage + 1)
                    .map(({ Id, Text, Geometry: { BoundingBox } }) => (
                      <rect
                        key={Id}
                        id={Id}
                        x={BoundingBox.Left}
                        y={BoundingBox.Top}
                        width={BoundingBox.Width}
                        height={BoundingBox.Height}
                        className="fill-none stroke-current text-brand-blue cursor-pointer"
                        style={{
                          strokeWidth: 0.002,
                          fillOpacity: 0.1,
                        }}
                        onClick={() => handleClick(Text)}
                      />
                    ))}
                </svg>
              </div>
            </TransformComponent>

            <div className="position absolute top-20 left-0 m-8">
              <MenuUploadButton onUpload={() => uploadResults.mutate()}>
                <button
                  type="button"
                  className="h-10 w-10 flex items-center justify-center text-white rounded-full bg-gray-900 hover:bg-gray-800 transition-colors"
                >
                  <UploadIcon />
                </button>
              </MenuUploadButton>
            </div>

            <div className="position absolute top-20 right-0 m-8 space-y-3">
              <button
                type="button"
                className="h-10 w-10 flex items-center justify-center text-white text-xl rounded-full bg-gray-900 hover:bg-gray-800 transition-colors"
                onClick={() => zoomIn()}
              >
                <PlusIcon />
              </button>
              <button
                type="button"
                className="h-10 w-10 flex items-center justify-center text-white text-xl rounded-full bg-gray-900 hover:bg-gray-800 transition-colors"
                onClick={() => zoomOut()}
              >
                <MinusIcon />
              </button>
            </div>
          </>
        )}
      </TransformWrapper>

      {urls.length > 1 && (
        <div className="mt-6 flex flex-shrink-0 items-center justify-center">
          <button
            disabled={activePage <= 0}
            className="disabled:text-gray-400 disabled:cursor-not-allowed"
            onClick={() => setActivePage(Math.max(activePage - 1, 0))}
          >
            <CaretLeftIcon />
          </button>
          <p className="mx-6 text-sm text-gray-700">
            {activePage + 1}/{urls.length}
          </p>
          <button
            disabled={activePage > urls.length}
            className="disabled:text-gray-400 disabled:cursor-not-allowed"
            onClick={() => setActivePage(Math.min(activePage + 1, urls.length))}
          >
            <CaretRightIcon />
          </button>
        </div>
      )}
    </div>
  );
}
