import { nanoid } from "nanoid";

const initialState = {
  errors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_ERROR":
      const errorId = nanoid().id;
      let errorText = action.error
        ? action.error.data
          ? action.error.data.error
          : ""
        : "";
      if (!errorText) errorText = "";
      if (action.errorString) errorText = action.errorString;

      if (state.errors.find((error) => error.text === errorText)) return state;

      return {
        ...state,
        errors: [
          ...state.errors,
          {
            id: errorId,
            text: errorText.toString(),
          },
        ],
      };
    case "DELETE_ERROR":
      return {
        ...state,
        errors: state.errors.filter((error) => error.id !== action.id),
      };
    case "DELETE_ALL_ERRORS":
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};
