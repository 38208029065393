import React from "react";
import capitalise from "../../../helpers/capitalise";

import MainError from "../../forms/MainError";

class EditIngredientsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      valid: true,
      value: "",
      ingredients: props.profile.ingredients || [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitIngredient = this.handleSubmitIngredient.bind(this);
    this.removeIngredient = this.removeIngredient.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState({ value: e.target.value });
  }
  handleSubmitIngredient(e) {
    e.preventDefault();
    const ingredient = this.state.value.toLowerCase().trim();
    if (!ingredient) return;
    if (this.state.ingredients.includes(ingredient)) {
      this.setState({
        error: `You've already added
        '${capitalise(this.state.value)}'.
        Please try entering a different ingredient.
        `,
      });
      return;
    }
    this.setState({ error: "" });
    this.setState({ ingredients: [...this.state.ingredients, ingredient] });
    this.setState({ value: "" });
  }
  listIngredients() {
    return this.state.ingredients.map((ingredient) => (
      <div key={ingredient} className="create-profile__ingredient-pill">
        {capitalise(ingredient)}
        <button
          name={ingredient}
          onClick={this.removeIngredient}
          className="create-profile__ingredident-pill__btn"
        ></button>
      </div>
    ));
  }
  removeIngredient(e) {
    this.setState({
      ingredients: this.state.ingredients.filter((ingredient) => {
        return ingredient !== e.target.name;
      }),
    });
  }
  errorAlert() {
    if (this.state.error) {
      return (
        <div className="callout warning create-profile__ingredients-error">
          {this.state.error}
        </div>
      );
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit.disabled = true;
    // Validate
    if (this.state.ingredients.length < 3) {
      this.setState({ valid: false });
      this.refs.submit.disabled = false;
      return;
    } else {
      this.setState({ valid: true });
    }
    const data = this.state.ingredients;
    this.props.submitHandler(data);
  }
  render() {
    return (
      <div>
        {this.props.error ? <MainError errorText={this.props.error} /> : ""}
        {!this.state.valid ? (
          <MainError
            errorText={"Error: Please enter at least three ingredients."}
          />
        ) : (
          ""
        )}
        <p>
          Tell us the key ingredients in your dishes so we can send you info and
          news about relevant suppliers.
        </p>
        <p>
          Once you've typed out an ingredient, hit <b>return/enter &#9166;</b>{" "}
          on the keyboard to add it to the list.
        </p>
        <p>
          You can add as many ingredients as you want - your ingredient list
          won't be visible to eaters or other vendors.
        </p>
        <p>
          Once you are happy with your list, hit '
          {this.props.buttonText || "Save"}'.
        </p>
        <form onSubmit={this.handleSubmitIngredient} noValidate>
          <input
            type="text"
            value={this.state.value}
            placeholder="Enter an ingredient name"
            onChange={this.handleChange}
          ></input>
          {this.errorAlert()}
        </form>
        <div className="create-profile__ingredients-container">
          {this.listIngredients()}
        </div>
        <button
          type="submit"
          ref="submit"
          className="button expanded"
          onClick={this.handleSubmit}
          disabled={this.props.submitDisabled}
        >
          {this.props.buttonText || "Save"}
        </button>
      </div>
    );
  }
}

export default EditIngredientsForm;
