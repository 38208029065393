import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { updateUserType } from "actions";

function VendorApprovedContainer(props) {
  const { user, history, updateUserType } = props;

  React.useEffect(() => {
    if (user) {
      updateUserType().then(() => {
        history.push("/");
      });
    }
  }, []);

  if (!user) return <Redirect exact to="/" />;

  return <div className="loading-placeholder" />;
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const actions = { updateUserType };

export default connect(mapStateToProps, actions)(VendorApprovedContainer);
