import React from "react";

const CustomerType = (props) => {
  const { title, description, new: newCustomers, returning } = props;

  return (
    <div className="customer-type">
      <h4>{title}</h4>
      <p className="customer-type__description">{description}</p>
      <div className="customer-type__figures-container">
        <span className="customer-type__figure new">
          {newCustomers}%
          <span className="customer-type__figure__title">New customers</span>
        </span>
        <span className="customer-type__figure returning">
          {returning}%
          <span className="customer-type__figure__title">
            Returning customers
          </span>
        </span>
      </div>
      <div className="customer-type__line-graph">
        <div
          className="customer-type__line-graph__new"
          style={{ width: `${newCustomers}%` }}
        />
      </div>
    </div>
  );
};

export default CustomerType;
