export const SERVER = `${
  process.env.REACT_APP_SERVER || "https://api.dev.hawkker.com/"
}api/v1/web/`;

export const GET_AUTH_USER = "vendor/auth_user/";
export const LOGIN = "vendor/login/";
export const LOGOUT = "vendor/logout";
export const FORGOTTEN_PASSWORD = "vendor/forgot_password/";
export const CHANGE_PASSWORD = "vendor/change_password/";
export const SIGNUP = "vendor/sign_up/";
export const ONBOARD = "vendor/onboarding/";
export const MARKETS = "vendor/markets/";
export const PROFILE = "vendor/profile/";
export const GENERAL_SETTINGS = "vendor/general_settings/";
export const VENDOR_LIST = "vendor/vendors";
export const TRANSACTIONS = "vendor/transaction/";
export const PURCHASES = "vendor/purchases/";
export const DELETE_ACCOUNT = "vendor/delete/";
export const LOCATIONS = "vendor/locations/";
export const LOCATION_OFFERINGS = "vendor/location_offerings/";
export const POSTCODE_AREAS = "vendor/postcode_areas/";
export const REFERRAL_CODE = "vendor/referral/";

export const BUSINESS_INFO = "vendor/update_business/";
export const EDIT_LOCATION = "vendor/update_location/";
export const EDIT_BUSINESS_INFO = "vendor/update_business/";
export const EDIT_ALLERGENS = "vendor/update_allergens/";
export const EDIT_INGREDIENTS = "vendor/update_ingredients/";
export const EDIT_IMAGES = "vendor/update_photos/";
export const EDIT_PROFILE = "vendor/update_profile/";
export const EDIT_ADDRESS = "vendor/update_address/";

export const DISHES = "vendor/dish/";
export const ACTIVE_DISHES = "vendor/dish/?active=true";
export const INACTIVE_DISHES = "vendor/dish/?active=false";
export const ALLERGENS = "dish/allergens/";
export const CUISINES = "dish/cuisines/";
export const DIETARY = "dish/dietary/";
export const DISH_NAMES = "vendor/all_dishes/?deleted=false";

export const CONVERSATIONS = "vendor/chat/";
export const UNREAD_MESSAGE_COUNT = "vendor/chat/details/";

export const DASHBOARD = "vendor/dashboard/";
export const MOST_PURCHASED = "vendor/most_purchased_dishes/";
export const CUSTOMER_TYPE = "vendor/customer_type/";
export const HIGHEST_RATED = "vendor/highest_rated_dishes/";
export const LATEST_TRANSACTIONS = "vendor/latest_transactions/";

export const REVIEWS = "vendor/rating/";

export const CHECKIN = "vendor/checkin/";
export const CHECKOUT_TIME = "vendor/checkout_time/";
export const CHECKOUT = "vendor/checkout/";
