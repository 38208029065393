import * as api from "./api";
import { LOCATIONS, LOCATION_OFFERINGS, POSTCODE_AREAS } from "../api_urls";

export const getVendorLocations = () =>
  api
    .get(`${LOCATIONS}?page_size=100`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getVendorLocation = (locationId) =>
  api
    .get(`${LOCATIONS}${locationId}/`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const submitVendorLocation = (locationPayload) =>
  api
    .post(LOCATIONS, locationPayload)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const updateVendorLocation = (locationID, locationPayload) =>
  api
    .patch(`${LOCATIONS}${locationID}/`, locationPayload)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const deleteVendorLocation = (locationID) =>
  api
    .del(`${LOCATIONS}${locationID}/`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getLocationOfferings = () =>
  api
    .get(LOCATION_OFFERINGS)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getPostcodeAreas = () =>
  api
    .get(POSTCODE_AREAS)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
