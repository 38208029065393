const initialState = {
  isFetching: true,
  dishNamesFetching: true,
  isFetchingFiltered: false,
  isFetchingNext: false,
  results: [],
  dishNames: [],
  filtered: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_PURCHASES_SUCCESS":
      return {
        ...state,
        ...action.data,
        isFetching: false,
      };
    case "GET_NEXT_PURCHASES_REQUEST":
      return {
        ...state,
        isFetchingNext: true,
      };
    case "GET_NEXT_PURCHASES_SUCCESS":
      return {
        ...state,
        ...action.data,
        isFetchingNext: false,
        results: [...state.results, ...action.data.results],
      };
    case "GET_DISH_NAMES_SUCCESS":
      return {
        ...state,
        dishNamesFetching: false,
        dishNames: action.data,
      };
    case "GET_FILTERED_PURCHASES_REQUEST":
      return {
        ...state,
        isFetchingFiltered: true,
      };
    case "GET_FILTERED_PURCHASES_SUCCESS":
      return {
        ...state,
        isFetchingFiltered: false,
        filtered: action.data,
      };
    case "GET_NEXT_FILTERED_PURCHASES_SUCCESS":
      return {
        ...state,
        ...action.data,
        isFetchingNext: false,
        filtered: {
          ...state.filtered,
          ...action.data,
          results: [...state.filtered.results, ...action.data.results],
        },
      };
    default:
      return state;
  }
};
