import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTransactions } from "../actions/transactions";

import GenericError from "../components/errors/GenericError";
import Points from "../components/wallet/Points.js";
import Transactions from "../components/wallet/Transactions.js";

class WalletContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
    };
  }
  componentDidMount() {
    if (this.props.transactionsLoading) {
      this.props.getTransactions().catch(() => {
        this.setState({ error: true });
      });
    }
  }
  render() {
    if (this.state.error) return <GenericError />;
    if (this.props.transactionsLoading || this.props.profileLoading)
      return <div className="loading-placeholder" />;
    return (
      <div className="grid-container wallet">
        <Points pointsCount={this.props.profile.coins} />
        <Transactions transactions={this.props.transactions} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getTransactions }, dispatch);

const mapStateToProps = (state) => ({
  transactionsLoading: state.transactions.loading,
  profileLoading: state.profile.loading,
  transactions: state.transactions,
  profile: state.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletContainer);
