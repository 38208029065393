import * as purchases from "../services/purchases";

export const getPurchases = () => (dispatch) =>
  purchases
    .getPurchases()
    .then((response) => {
      dispatch({
        type: "GET_PURCHASES_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
      return Promise.reject();
    });

export const getFilteredPurchases = (filters) => (dispatch) => {
  dispatch({
    type: "GET_FILTERED_PURCHASES_REQUEST",
  });
  purchases
    .getFilteredPurchases(filters)
    .then((response) => {
      dispatch({
        type: "GET_FILTERED_PURCHASES_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });
};

export const getNextPurchases = (url) => (dispatch) => {
  dispatch({
    type: "GET_NEXT_PURCHASES_REQUEST",
  });
  return purchases
    .getNextPurchases(url)
    .then((response) => {
      dispatch({
        type: "GET_NEXT_PURCHASES_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });
};

export const getNextFilteredPurchases = (url) => (dispatch) => {
  dispatch({
    type: "GET_NEXT_PURCHASES_REQUEST",
  });
  return purchases
    .getNextPurchases(url)
    .then((response) => {
      dispatch({
        type: "GET_NEXT_FILTERED_PURCHASES_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });
};

export const getDishNames = () => (dispatch) => {
  purchases
    .getDishNames()
    .then((response) => {
      dispatch({
        type: "GET_DISH_NAMES_SUCCESS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });
};
