import React from "react";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import { googleAPIKey } from "../../credentials/credentials";

import location_permissions from "../../assets/images/location_permissions.png";
import { LocationPermissionInformationText } from "../permissions/permissionInformation";

const {
  StandaloneSearchBox,
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

/**
 *  This is a simplified component, extracted from VendorLocation.js in case we ever need
 *  to go back to reading GPS off of browser.
 */

class VendorLocationNoBrowserGPS extends React.Component {
  constructor(props) {
    super(props);

    // If no start location specified, start in Trafalgar Square
    // and zoom out a little, to give some perspective
    const defaultLat = this.props.defaultLat || 51.50809;
    const defaultLng = this.props.defaultLng || -0.1302322;
    const defaultZoomLevel = 14;

    this.state = {
      centreLocation: {
        lat: defaultLat,
        lng: defaultLng,
      },
      zoomLevel: defaultZoomLevel,
      showMap: false,
      showStatus: false,
      googlePlaceAcquired: false,

      selectedPlaceDescription: "",
    };
    this.props.onLocationUpdate({
      lat: defaultLat,
      lng: defaultLng,
    });
    this.togglePermissionInstructions = this.togglePermissionInstructions.bind(
      this
    );
  }

  componentWillMount() {
    const refs = {};

    // Google maps marker dragged to another location
    const onPositionChanged = () => {
      const position = refs.marker.getPosition();
      this.props.onLocationUpdate({
        lat: position.lat(),
        lng: position.lng(),
      });
    };

    // A location was selected from the Google places search box
    const onPlacesChanged = () => {
      const location = refs.searchBox.getPlaces()[0].geometry.location;

      this.setState({
        centreLocation: {
          lat: location.lat(),
          lng: location.lng(),
        },
        zoomLevel: 18,
      });
      this.props.onLocationUpdate({
        lat: location.lat(),
        lng: location.lng(),
      });
    };

    // Vendor has confirmed (Next button) the Google places search box entry
    const googlePlaceConfirmed = () => {
      this.setState({
        googlePlaceAcquired: true,
      });
      this.props.onLocationSet();
    };

    this.setState({
      onMarkerMounted: (ref) => {
        refs.marker = ref;
      },
      onSearchBoxMounted: (ref) => {
        refs.searchBox = ref;
      },
      onPositionChanged: onPositionChanged.bind(this),
      onPlacesChanged: onPlacesChanged.bind(this),
      googlePlaceConfirmed: googlePlaceConfirmed.bind(this),
    });
  }

  componentDidMount() {
    // The map and status message may not be needed (yet or at all)
    // But it may take a little while to get the status right, i.e. to hide them
    // This could result in the map or status flashing up and then disappearing again
    // To fix this, briefly delay showing them
    setTimeout(
      () =>
        this.setState({
          showMap: true,
          showStatus: true,
        }),
      500
    );

    // Try to get the vendor's current GPS location
    if (this.state.geoLocationStatus === "awaiting_permission") {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          this.setState({
            centreLocation: this.props.ignoreBrowserLocation
              ? this.state.centreLocation
              : {
                  lat: lat,
                  lng: lng,
                },
            geoLocationStatus: "location_acquired",
            zoomLevel: 18,
          });
          this.props.onLocationUpdate(
            this.props.ignoreBrowserLocation
              ? this.state.centreLocation
              : {
                  lat: lat,
                  lng: lng,
                }
          );
          this.props.onLocationSet();
        },
        (positionError) => {
          if (positionError.code === 1) {
            this.setState({
              geoLocationStatus: "unavailable",
            });
          } else {
            this.setState({
              geoLocationStatus: "unavailable",
            });
          }
        }
      );
    }
  }

  togglePermissionInstructions() {
    this.setState({
      permissionInstructionsShown: !this.state.permissionInstructionsShown,
    });
  }

  render() {
    const google_map_url =
      "https://maps.googleapis.com/maps/api/js?key=" +
      googleAPIKey +
      "&v=3.exp&libraries=geometry,drawing,places";

    return (
      <div>
        <div className="box">
          <h4>
            Drag pointer to your exact location and hit "Next" below the map
          </h4>

          {/*  <div>*/}
          {/*    <MySearchBox*/}
          {/*      googleMapURL={google_map_url}*/}
          {/*      loadingElement={<div style={{ height: `100%` }}/>}*/}
          {/*      containerElement={<div style={{ height: `400px` }}/>}*/}
          {/*      onSearchBoxMounted={this.state.onSearchBoxMounted}*/}
          {/*      onPlacesChanged={this.state.onPlacesChanged}*/}
          {/*    />*/}

          {/*    /!* Confirm search *!/*/}
          {/*    <button onClick={this.state.googlePlaceConfirmed}*/}
          {/*            className="button vendor_place_next_button">Next*/}
          {/*    </button>*/}
          {/*  </div>*/}
        </div>

        <MyMapComponent
          googleMapURL={google_map_url}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          centreLocation={this.state.centreLocation}
          zoomLevel={this.state.zoomLevel}
          onPositionChanged={this.state.onPositionChanged}
          onMarkerMounted={this.state.onMarkerMounted}
        />
      </div>
    );
  }
}

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={props.zoomLevel}
      defaultCenter={{
        lat: props.centreLocation.lat,
        lng: props.centreLocation.lng,
      }}
      defaultOptions={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      <Marker
        draggable={true}
        defaultPosition={{
          lat: props.centreLocation.lat,
          lng: props.centreLocation.lng,
        }}
        ref={props.onMarkerMounted}
        onPositionChanged={props.onPositionChanged}
      />
    </GoogleMap>
  ))
);

const MySearchBox = withScriptjs((props) => {
  return (
    <div data-standalone-searchbox="">
      <StandaloneSearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input type="text" placeholder="Enter your current location" />
      </StandaloneSearchBox>
    </div>
  );
});

export default VendorLocationNoBrowserGPS;
