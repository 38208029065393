import React from "react";
import { connect } from "react-redux";

import { getLocationOfferings } from "../../actions/locations";
import WeekdayHours from "./WeekdayHours";
import LocationDetails from "./LocationDetails";
import cross from "../../assets/icons/cross.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/icons/delivery.svg";
import { ReactComponent as TakeawayIcon } from "../../assets/icons/takeaway.svg";
import { ReactComponent as EatInIcon } from "../../assets/icons/eat-in.svg";
import { ReactComponent as WebshopIcon } from "../../assets/icons/online-shop.svg";
import RadioItem from "../forms/RadioItem";

const providerIcons = {
  delivery: DeliveryIcon,
  takeaway: TakeawayIcon,
  "eat-in": EatInIcon,
  webshop: WebshopIcon,
};

export const defaultFromHour = "09:00";
export const defaultToHour = "17:00";

class LocationForm extends React.Component {
  state = {
    hasFineTuned: this.props.initiallyValid || false,
    isFormValid: this.props.initiallyValid || false,
  };

  constructor(props) {
    super(props);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.onIsClosedChange = this.onIsClosedChange.bind(this);
    this.onLocationTypeChange = this.onLocationTypeChange.bind(this);
    this.onFixedDetailsChange = this.onFixedDetailsChange.bind(this);
    this.onChangeMarket = this.onChangeMarket.bind(this);
    this.onChangePostcodeArea = this.onChangePostcodeArea.bind(this);
    this.onFineTuneLocationChange = this.onFineTuneLocationChange.bind(this);
    this._getUpdatedOpeningHours = this._getUpdatedOpeningHours.bind(this);
    this.onAddDeliveryOption = this.onAddDeliveryOption.bind(this);
    this.onDeliveryProvidersChange = this.onDeliveryProvidersChange.bind(this);
  }

  componentDidMount() {
    this.props.getLocationOfferings();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const isFormValid = this.validate();

    if (isFormValid !== prevState.isFormValid) {
      this.setState({ isFormValid });
      this.props.onFormValidation(isFormValid);
    }
  }

  _getUpdatedOpeningHours(currentOpeningHours, weekday, payload) {
    const day = currentOpeningHours.find((hour) => hour.weekday === weekday);
    const i = currentOpeningHours.findIndex((hour) => hour.weekday === weekday);

    return [
      ...currentOpeningHours.slice(0, i),
      {
        ...day,
        ...payload,
      },
      ...currentOpeningHours.slice(i + 1),
    ];
  }

  onChangeTime(weekday, time_key, time) {
    const currentLocation = this.props.location;
    const newOpeningHours = this._getUpdatedOpeningHours(
      currentLocation.opening_hours,
      weekday,
      {
        [time_key]: time,
      }
    );

    const timeValid = this.isTimeValidForWeekday(weekday, newOpeningHours);

    this.props.updateLocation({
      ...currentLocation,
      opening_hours: this._getUpdatedOpeningHours(newOpeningHours, weekday, {
        timeValid,
      }),
    });
    this.validate();
  }

  onIsClosedChange(weekday, isClosed) {
    const currentLocation = this.props.location;
    const opening_hours = this._getUpdatedOpeningHours(
      currentLocation.opening_hours,
      weekday,
      {
        open: !isClosed,
        from_hour: defaultFromHour,
        to_hour: defaultToHour,
        timeValid: true,
      }
    );

    this.props.updateLocation({
      ...currentLocation,
      opening_hours,
    });
  }

  onLocationTypeChange(locationType) {
    const currentLocation = this.props.location;

    let updateOfferings = {};
    if (locationType === "private") {
      updateOfferings = {
        offering_ids: this.props.locationOfferings
          .filter((obj) => obj.key === "delivery")
          .map((obj) => obj.id),
      };
    } else if (locationType === "webshop") {
      updateOfferings = {
        offering_ids: this.props.locationOfferings
          .filter((obj) => obj.key === "webshop")
          .map((obj) => obj.id),
      };
    }

    this.setState({ hasFineTuned: false });
    this.props.updateLocation({
      ...currentLocation,
      ...updateOfferings,
      eat_in: locationType === "private" ? false : currentLocation.eat_in,
      location_type: locationType,
      street: locationType === "fixed" ? currentLocation.street : "",
      postcode: locationType === "fixed" ? currentLocation.postcode : "",
      market: locationType === "market" ? {} : currentLocation.market,
    });
  }

  onChangeMarket(market) {
    const currentLocation = this.props.location;

    // Force re-tuning of GPS coordinates once market is changed
    this.setState({ hasFineTuned: false });

    this.props.updateLocation({
      ...currentLocation,
      market: {
        id: market.id,
        name: market.market_name,
      },
      coordinates: market.coordinates,
    });
  }

  onChangePostcodeArea(postcode) {
    this.props.updateLocation({
      ...this.props.location,
      postcode_area: {
        id: postcode.id,
        postcode: postcode.postcode,
      },
      coordinates: postcode.coordinates,
    });
  }

  onFineTuneLocationChange(location) {
    const currentLocation = this.props.location;

    this.setState({ hasFineTuned: true });

    this.props.updateLocation({
      ...currentLocation,
      coordinates: location,
    });
  }

  onFixedDetailsChange(fieldName, value) {
    const currentLocation = this.props.location;

    this.props.updateLocation({
      ...currentLocation,
      [fieldName]: value,
    });
  }

  onDeliveryProvidersChange(value, eatIn) {
    const currentLocation = this.props.location;

    if (eatIn == null) eatIn = currentLocation.eat_in;

    const offering_ids = [
      ...value,
      // add eat_in option *without* a provider link
      ...(eatIn ? [{ type: "eat-in" }] : []),
    ]
      .map((provider) =>
        this.props.locationOfferings.find(
          (offering) => offering.key === provider.type
        )
      )
      .filter(Boolean)
      .map((offering) => offering.id)
      // filter unique
      .filter((value, index, self) => self.indexOf(value) === index);

    this.props.updateLocation({
      ...currentLocation,
      eat_in: eatIn,
      offering_ids,
      delivery_providers: value,
    });
  }

  isTimeValidForWeekday = (weekday, openingHours) => {
    const i = openingHours.findIndex((hour) => hour.weekday === weekday);

    if (openingHours[i].to_hour.indexOf("00") === 0) {
      return true;
    }
    return !(
      openingHours[i].from_hour >= openingHours[i].to_hour &&
      openingHours[i].open
    );
  };

  onAddDeliveryOption(type) {
    return () => {
      const { delivery_providers } = this.props.location;

      const lastId =
        delivery_providers
          .map((provider) => provider._id)
          .filter((value) => value != null)
          .reverse()[0] ?? -1;

      this.onDeliveryProvidersChange([
        {
          _id: lastId,
          type,
          name: "",
          url: "",
        },
        ...delivery_providers,
      ]);
    };
  }

  validate = () => {
    const currentLocation = this.props.location;
    const hasCoordinates =
      currentLocation.coordinates &&
      Object.keys(currentLocation.coordinates).length > 0 &&
      currentLocation.coordinates.lat &&
      currentLocation.coordinates.lng;
    const areAllHoursValid = currentLocation.opening_hours.every((hour) =>
      this.isTimeValidForWeekday(hour.weekday, currentLocation.opening_hours)
    );

    if (currentLocation.location_type === "market") {
      return (
        currentLocation.market &&
        currentLocation.market.id &&
        this.state.hasFineTuned &&
        hasCoordinates &&
        areAllHoursValid
      );
    } else if (currentLocation.location_type === "fixed") {
      return this.state.hasFineTuned && hasCoordinates && areAllHoursValid;
    } else if (
      currentLocation.location_type === "private" ||
      currentLocation.location_type === "webshop"
    ) {
      return true;
    }

    return false;
  };

  render() {
    const {
      location: {
        opening_hours,
        coordinates,
        street,
        postcode,
        location_type,
        postcode_area,
        market,
        eat_in,
        delivery_providers,
      },
      markets,
      errors,
      locationOfferings,
    } = this.props;

    return (
      <div className="cell medium-9 large-8 menu-list">
        <div>
          <div className="section-heading">
            <img src={require("../../assets/icons/location.svg")} alt="" />
            <h3>
              Location Type<span className="input-required">*</span>
            </h3>
          </div>

          <p className="alert alert-warning">
            All the information you provide below is made publicly available. If
            you are operating from a location which{" "}
            <strong>you do not want to be made publicly visible</strong> (e.g. a
            licensed residential kitchen), please select{" "}
            <strong>Delivery Only</strong> location or <strong>Webshop</strong>{" "}
            below.
          </p>

          <LocationDetails
            coordinates={coordinates}
            locationType={location_type}
            street={street}
            postcode={postcode}
            market={market}
            availableMarkets={markets.list || []}
            postcodeArea={postcode_area}
            onLocationTypeChange={this.onLocationTypeChange}
            onFixedDetailsChange={this.onFixedDetailsChange}
            onChangeMarket={this.onChangeMarket}
            onFineTuneLocationChange={this.onFineTuneLocationChange}
            onChangePostcodeArea={this.onChangePostcodeArea}
          />

          {location_type !== "webshop" && (
            <>
              <div className="section-heading">
                <img src={require("../../assets/icons/calendar.svg")} alt="" />
                <h3>
                  Opening Hours<span className="input-required">*</span>
                </h3>
              </div>

              {(opening_hours || [])
                .sort((a, b) => (a.weekday < b.weekday ? -1 : 1))
                .map((openingHoursDay) => (
                  <WeekdayHours
                    key={openingHoursDay.weekday}
                    openingHoursDay={openingHoursDay}
                    onIsClosedChange={this.onIsClosedChange}
                    onChangeTime={this.onChangeTime}
                  />
                ))}
            </>
          )}

          <div className="section-heading">
            <div className="onboarding__location-type-image onboarding__location-type-image--purchase-options">
              <img
                src={require("../../assets/icons/purchase-options.svg")}
                alt=""
              />
            </div>
            <h3>
              Purchase options<span className="input-required">*</span>
            </h3>
          </div>

          {location_type === "private" && (
            <p className="alert alert-warning">
              For Delivery Only Locations, you can only select Delivery. Eat-in
              and Collection are unavailable for this location type.
            </p>
          )}

          {location_type === "webshop" && (
            <p className="alert alert-warning">
              For Webshop Locations, you can only select Webshop Link.
            </p>
          )}

          <div style={{ marginBottom: 32 }}>
            <h3 className={location_type === "private" ? "text-muted" : ""}>
              Are customers able to eat the food or drink they have purchased
              whilst at this location, i.e. does this location offer “eat-in” to
              customers?
            </h3>

            <RadioItem
              id="eat-in-disabled"
              item="Yes, customers can eat-in here"
              checked={eat_in}
              changeHandler={() => {
                this.onDeliveryProvidersChange(delivery_providers, true);
              }}
              disabled={
                location_type === "private" || location_type === "webshop"
              }
            />
            <RadioItem
              id="eat-in-enabled"
              item="No"
              checked={!eat_in}
              changeHandler={() => {
                this.onDeliveryProvidersChange(delivery_providers, false);
              }}
              disabled={
                location_type === "private" || location_type === "webshop"
              }
            />
          </div>

          <div className="service-providers__add">
            {["delivery", "takeaway", "eat-in", "webshop"].map((key) => {
              const Icon = providerIcons[key];
              const disabled =
                (location_type === "private" && key !== "delivery") ||
                (location_type === "webshop" && key !== "webshop");
              return (
                <button
                  className={`service-providers__option ${
                    disabled ? "service-providers__option--disabled" : ""
                  }`}
                  key={key}
                  onClick={this.onAddDeliveryOption(key)}
                  disabled={disabled}
                >
                  <div className="service-providers__icon">
                    {Icon != null && <Icon />}
                    <PlusIcon className="service-providers__plus-icon" />
                  </div>
                  {key === "takeaway" && "Add Collection Ordering Link"}
                  {key === "delivery" && "Add Delivery Provider Link"}
                  {key === "eat-in" && "Add Reservation Booking Link"}
                  {key === "webshop" && "Add Webshop Link"}
                </button>
              );
            })}
          </div>

          <DeliveryProvidersField
            errors={errors != null ? errors.delivery_providers : []}
            name="delivery_providers"
            value={delivery_providers}
            onChange={(value) => this.onDeliveryProvidersChange(value)}
            locationOfferings={locationOfferings}
          />
        </div>
      </div>
    );
  }
}

function DeliveryProvidersField({
  name,
  value,
  onChange,
  errors,
  locationOfferings,
}) {
  const onRemoveProvider = (index) => () => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  const handleChange = (index, field) => (e) => {
    const newValue = [...value];
    newValue[index][field] = e.target.value;
    onChange(newValue);
  };

  return (
    <>
      {value.map((provider, index) => {
        const id = provider.id || provider._id;
        const error = (errors != null && errors[index]) || {};
        const type = locationOfferings.find(
          (offering) => offering.key === provider.type
        );
        return (
          <div key={id} className="service-provider-link">
            <div className="service-provider-link__row">
              <div>
                <label>Type:</label>
                <span className="readonly-input">{type && type.name}</span>
              </div>
              {provider.type !== "webshop" && (
                <div>
                  <label htmlFor={`${name}.${id}.name`}>Provider:</label>
                  <input
                    id={`${name}.${id}.name`}
                    type="text"
                    value={provider.name}
                    onChange={handleChange(index, "name")}
                    placeholder="e.g. Deliveroo"
                    tabIndex={1}
                  />
                  {error.name && (
                    <p className="validation-message">{error.name}</p>
                  )}
                </div>
              )}
              <button
                className="dish__delete-panel__delete"
                style={{ paddingTop: "2.6em", alignSelf: "start" }}
                onClick={onRemoveProvider(index)}
              >
                <img src={cross} alt="Remove delivery provider" />
              </button>
            </div>
            <div>
              <div>
                <label htmlFor={`${name}.${id}.url`}>
                  URL Link or Phone Number:
                </label>
                <input
                  id={`${name}.${id}.url`}
                  type="text"
                  value={provider.url}
                  onChange={handleChange(index, "url")}
                  tabIndex={1}
                />
                {error.url && <p className="validation-message">{error.url}</p>}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

const mapStateToProps = (state) => ({
  markets: state.markets.locations,
  locationOfferings: state.locations.locationOfferings.list,
});

export default connect(mapStateToProps, { getLocationOfferings })(LocationForm);
