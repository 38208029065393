import React from "react";

import MainError from "../../forms/MainError";

export interface FurtherInfo {
  cash: boolean;
  card: boolean;
  private_hire_email: string;
  website_link: string;
}

interface Props {
  profile: FurtherInfo;
  submitHandler?: (payload: FurtherInfo) => void;
  submitDisabled?: boolean;
  error?: boolean;
}

interface State {
  cash: boolean;
  card: boolean;
  paymentValid: boolean;
  private_hire_email: string;
  privateHireEmailValid: boolean;
  websiteLinkValid: boolean;
  website_link: string;
  valid: boolean;
}

class EditTradingInfoForm extends React.Component<Props, State> {
  state = {
    valid: true,
    cash: this.props.profile.cash,
    card: this.props.profile.card,
    website_link: this.props.profile.website_link,
    websiteLinkValid: true,
    private_hire_email: this.props.profile.private_hire_email,
    privateHireEmailValid: true,
    paymentValid: true,
  };

  handlePaymentChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      // @ts-ignore
      {
        [e.target.name]: e.target.checked,
      },
      () => this.paymentTypeValid(true)
    );
  }

  paymentTypeValid(checkSubmit?: boolean) {
    const { cash, card } = this.state;
    if (checkSubmit) return;
    if (!cash && !card) {
      this.setState({ paymentValid: false });
      return false;
    } else {
      this.setState({ paymentValid: true });
      return true;
    }
  }

  websiteLinkValid(checkSubmit?: boolean) {
    const { website_link: websiteLink } = this.state;

    this.setState({ websiteLinkValid: true });
    return true;
  }

  privateHireEmailValid(checkSubmit?: boolean) {
    const { private_hire_email: privateHireEmail } = this.state;

    if (checkSubmit) return;

    const isValidEmail =
      !privateHireEmail ||
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,63}$/.test(
        privateHireEmail.toLowerCase()
      );

    this.setState({ privateHireEmailValid: isValidEmail });
    return isValidEmail;
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // Validate
    // @ts-ignore
    const paymentValid = this.paymentTypeValid();
    const websiteLinkValid = this.websiteLinkValid();
    const privateHireEmailValid = this.privateHireEmailValid();
    if (!paymentValid || !websiteLinkValid || !privateHireEmailValid) {
      this.setState({ valid: false });
      window.scrollTo(0, 0);
      return;
    } else {
      this.setState({ valid: true });
    }
    // Data composed and submitted in callback function
    const data = this.state;
    this.props.submitHandler(data);
  }
  render() {
    return (
      <div>
        {this.props.error ? <MainError errorText={this.props.error} /> : ""}
        {!this.state.valid ? (
          <MainError
            errorText={
              "Error: Please check you have entered all information correctly."
            }
          />
        ) : (
          ""
        )}
        <form onSubmit={(e) => this.handleSubmit(e)} noValidate>
          <fieldset>
            <p>Provide a link to your website so customers can find out more</p>
            <div className="cell">
              <label htmlFor="websiteLink">Website Link</label>
              <input
                className={this.state.websiteLinkValid ? "" : "invalid"}
                type="text"
                name="websiteLink"
                id="websiteLink"
                placeholder="full URL, e.g. https://hawkker.com/"
                onChange={(e) =>
                  this.setState({ website_link: e.target.value })
                }
                value={this.state.website_link}
              />
            </div>
            <p>
              Provide an email address for private enquires (note that this
              email address is made public)
            </p>
            <div className="cell">
              <label htmlFor="privateHireEmail">Private Hire Email</label>
              <input
                className={this.state.privateHireEmailValid ? "" : "invalid"}
                type="email"
                name="privateHireEmail"
                id="privateHireEmail"
                placeholder="e.g. hawkky@hawkker.com"
                onChange={(e) =>
                  this.setState({ private_hire_email: e.target.value })
                }
                value={this.state.private_hire_email}
              />
              <span
                className={`validation-message ${
                  this.state.privateHireEmailValid ? "hide" : ""
                }`}
              >
                Please provide a valid email address
              </span>
            </div>
          </fieldset>
          <fieldset>
            <legend>Payment Options</legend>
            <p>Select which payment options you offer.</p>
            <div className="cell">
              <input
                className={this.state.paymentValid ? "" : "invalid"}
                type="checkbox"
                name="cash"
                id="cash"
                onChange={(e) => this.handlePaymentChange(e)}
                checked={this.state.cash}
              />
              <label htmlFor="cash">Cash</label>
            </div>
            <div className="cell">
              <input
                className={this.state.paymentValid ? "" : "invalid"}
                type="checkbox"
                name="card"
                id="card"
                onChange={(e) => this.handlePaymentChange(e)}
                checked={this.state.card}
              />
              <label htmlFor="card">Card</label>
            </div>
          </fieldset>
          <span
            className={`validation-message ${
              this.state.paymentValid ? "hide" : ""
            }`}
          >
            Please select at least one type of payment method
          </span>
          <button
            type="submit"
            className="button expanded"
            disabled={this.props.submitDisabled}
          >
            Save
          </button>
        </form>
      </div>
    );
  }
}

export default EditTradingInfoForm;
