// Import External Dependencies
import React from "react";
import { Route } from "react-router-dom";

// Import Components
import { Breadcrumb } from "react-breadcrumbs";

// Create and export the component
export default ({
  component: Component = null,
  includeSearch = false,
  render,
  ...props
}: any) => (
  <Route
    {...props}
    render={(routeProps) => (
      <Breadcrumb
        data={{
          title: props.title,
          pathname: routeProps.match.url,
          search: includeSearch ? routeProps.location.search : null,
        }}
      >
        {Component ? <Component {...routeProps} /> : render(routeProps)}
      </Breadcrumb>
    )}
  />
);
