import * as dishes from "../services/dishes";

export const getAllergens = () => {
  return (dispatch) => {
    return dishes
      .getAllergens()
      .then((response) => {
        dispatch({
          type: "SET_ALLERGENS",
          allergens: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          error,
        });
      });
  };
};

export const getCuisines = () => {
  return (dispatch) => {
    return dishes
      .getCuisines()
      .then((response) => {
        dispatch({
          type: "SET_CUISINES",
          cuisines: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          error,
        });
      });
  };
};

export const getDietaryPreferences = () => {
  return (dispatch) => {
    return dishes
      .getDietaryPreferences()
      .then((response) => {
        dispatch({
          type: "SET_DIETARY_PREFERENCES",
          dietaryPreferences: response.data.results,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          error,
        });
      });
  };
};

export const getDishes = (type) => {
  return (dispatch) => {
    return dishes
      .getDishes(type)
      .then((response) => {
        dispatch({
          type: type === "active" ? "SET_ACTIVE_DISHES" : "SET_INACTIVE_DISHES",
          dishes: response.data.results,
          count: response.data.count,
          next: response.data.next,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          error,
        });
        return Promise.reject();
      });
  };
};

export const getDish = (id) => {
  return (dispatch) => {
    return dishes.getDish(id).catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
      return Promise.reject();
    });
  };
};

export const getNextDishes = (type, url) => {
  return (dispatch) => {
    return dishes
      .getNextDishes(type, url)
      .then((response) => {
        dispatch({
          type:
            type === "active"
              ? "SET_NEXT_ACTIVE_DISHES"
              : "SET_NEXT_INACTIVE_DISHES",
          dishes: response.data.results,
          next: response.data.next,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          error,
        });
      });
  };
};
