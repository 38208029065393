import React from "react";
import { connect } from "react-redux";
import { Breadcrumbs } from "react-breadcrumbs";
import hamburger from "../../assets/icons/hamburger.svg";

const Header = (props) => (
  <header className="header top-bar">
    <div className="header__hamburger-wrapper" onClick={props.openSidebar}>
      <img className="header__hamburger" src={hamburger} alt="menu" />
    </div>
    <Breadcrumbs className="breadcrumbs-wrapper" />
  </header>
);

const mapStateToProps = (state) => ({
  pageTitle: state.global.pageTitle,
});

export default connect(mapStateToProps)(Header);
