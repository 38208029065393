import React from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { object, boolean, string } from "yup";

import InputCharCount from "components/forms/InputCharCount";
import MainError from "components/forms/MainError";
import { register } from "actions/signup";
import { makeValidate } from "helpers/forms";
import { getAuthUser, getProfile } from "actions";
import { setToken } from "services/auth";

function RegistrationForm(props) {
  const {
    isLoggedIn,
    user,
    profile,
    error,
    register,
    getAuthUser,
    history,
    location,
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const [menuBuilderUser, setMenuBuilderUser] = React.useState(null);
  const [initialEmail, setInitialEmail] = React.useState("");

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    if (token) {
      setToken(token);
      getAuthUser();
    }
    getProfile();

    const email = urlParams.get("email");
    if (email) {
      setInitialEmail(email);
    }
  }, [location.search]);

  React.useEffect(() => {
    if (user == null) return;

    if (user.user_type === 1 && user.status === 7) {
      setMenuBuilderUser(user);
    }
  }, [user]);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleRegistration = (values) => {
    // Compose Data
    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      business_name: values.businessName,
    };

    // Submit to API
    return register(data)
      .then(() => {
        history.push("/sign-up/success");
      })
      .catch(() => {
        window.scrollTo(0, 0);
      });
  };

  const isMenuBuilder = user?.status === 7;

  if (isLoggedIn && !isMenuBuilder) {
    return <Redirect exact to="/dashboard" from="/sign-up" />;
  }

  return (
    <div className="registration__container">
      <div className="registration">
        <div className="registration__intro">
          {isMenuBuilder ? (
            <>
              <h1>Your menu is looking awesome</h1>
              <p>
                Now let's get it and your business in front of as many customers
                as possible. Build a Hawkker profile and get discovered on the
                worlds first search engine for food!
              </p>
            </>
          ) : (
            <>
              <h1>Focus on the food, we'll handle the marketing</h1>
              <p>
                Free technology for independent food vendors for times like
                these. Engage customers, stay adaptable, keep selling.
              </p>
            </>
          )}
        </div>

        <div className="registration__video">
          <iframe
            src="https://player.vimeo.com/video/418515132?background=1"
            style={{
              width: "100%",
              height: "100%",
            }}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>

        <Form
          validate={validateRegistration}
          onSubmit={handleRegistration}
          initialValues={{
            email: menuBuilderUser?.email ?? initialEmail,
          }}
          render={({ handleSubmit, valid, submitting, submitFailed }) => (
            <form onSubmit={handleSubmit} className="registration__form">
              {error ? <MainError errorText={error} /> : ""}

              {submitFailed && (
                <MainError
                  errorText={
                    "Error: Please check you have entered all information correctly."
                  }
                />
              )}

              <fieldset>
                <Field
                  name="name"
                  type="text"
                  render={({ input, meta }) => (
                    <>
                      <label>
                        Your Name
                        <input {...input} />
                      </label>
                      {meta.touched && meta.error && (
                        <span className="validation-message">
                          Error: Please provide a name
                        </span>
                      )}
                    </>
                  )}
                />

                <Field
                  name="businessName"
                  type="text"
                  render={({ input }) => (
                    <InputCharCount
                      label="Business Name"
                      maxLength="30"
                      type={input.type}
                      name={input.name}
                      value={input.value}
                      changeHandler={input.onChange}
                    />
                  )}
                />

                <Field
                  name="email"
                  type="email"
                  render={({ input, meta }) => (
                    <>
                      <label>
                        Email Address
                        <input {...input} disabled={menuBuilderUser?.email} />
                      </label>
                      {meta.touched && meta.error && (
                        <span className="validation-message">
                          Error: Please provide a valid email address
                        </span>
                      )}
                    </>
                  )}
                />

                <Field
                  name="password"
                  type={showPassword ? "text" : "password"}
                  render={({ input, meta }) => (
                    <>
                      <label>
                        Password
                        <div className="input-wrapper">
                          <input {...input} />
                          <div
                            className={`password-toggle ${
                              showPassword
                                ? "password-toggle__hide"
                                : "password-toggle__show"
                            }`}
                            onClick={toggleShowPassword}
                          />
                        </div>
                      </label>
                      {meta.touched && meta.error && (
                        <span className="validation-message">
                          Error: Please provide a valid password
                        </span>
                      )}
                      <span className="input-hint">
                        Password must contain at least 8 characters
                      </span>
                    </>
                  )}
                />

                <Field
                  name="tos"
                  type="checkbox"
                  render={({ input, meta }) => (
                    <>
                      <input id="acceptTerms" {...input} />
                      <label
                        htmlFor="acceptTerms"
                        className="registration__terms"
                      >
                        I accept the{" "}
                        <a
                          href="https://hawkker.com/terms/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms and Conditions
                        </a>
                        {" and "}
                        <a
                          href="https://hawkker.com/privacy/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </label>
                      {meta.touched && meta.error && (
                        <span className="validation-message registration__terms-validation-message">
                          Error: You must accept the terms and conditions and
                          privacy policy to create an account
                        </span>
                      )}
                    </>
                  )}
                />

                <Field
                  name="pointsTos"
                  type="checkbox"
                  render={({ input, meta }) => (
                    <>
                      <input id="pointsToS-checkbox" {...input} />
                      <label
                        htmlFor="pointsToS-checkbox"
                        className="registration__terms"
                      >
                        I Accept the terms of the{" "}
                        <a
                          href="https://hawkker.com/hawkker-points-terms/"
                          target="_blank"
                        >
                          Hawkker Points System
                        </a>
                      </label>
                      {meta.touched && meta.error && (
                        <span className="validation-message registration__terms-validation-message">
                          Error: You must accept the terms of the Hawkker Points
                          System to create an account
                        </span>
                      )}
                    </>
                  )}
                />
              </fieldset>

              <div className="registration__actions">
                <button
                  type="submit"
                  className="button"
                  disabled={!valid || submitting}
                >
                  Create account{" "}
                  <img src={require("assets/icons/arrow-right.svg")} alt="" />
                </button>

                <a
                  href="https://hawkker.com/vendorapp"
                  className="registration__more"
                >
                  Learn more
                </a>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
}

const validateRegistration = makeValidate(
  object({
    name: string().required(),
    businessName: string().required(),
    email: string().required().email(),
    password: string().required().min(8),
    tos: boolean().required().oneOf([true]),
    pointsTos: boolean().required().oneOf([true]),
  })
);

const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.profile,
  isLoggedIn: state.auth.user,
  error: state.register.error,
});

const actions = { register, getAuthUser, getProfile };

export default connect(mapStateToProps, actions)(RegistrationForm);
