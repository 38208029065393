import React from "react";
import { ReactComponent as LoadingSpinner } from "components/menu/loading-spinner.svg";
import { useSafeContext } from "components/useSafeContext";

export type MenuLoaderContext = {
  loading: boolean;
  setLoading: (loading?: boolean, message?: string) => void;
};

export function MenuLoaderProvider({ children }) {
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const handleSetLoading = (loading = true, message = null) => {
    setLoading(loading);
    setMessage(message);
  };

  const ctx = React.useMemo(
    () => ({
      loading,
      setLoading: handleSetLoading,
    }),
    [loading]
  );
  return (
    <MenuLoaderContext.Provider value={ctx}>
      {loading && (
        <div className="fixed inset-0 min-h-screen flex flex-col items-center justify-center bg-white">
          <LoadingSpinner className="text-gray-900 h-16 w-16" />
          {message && <p className="mt-6 mb-32 text-xl">{message}</p>}
        </div>
      )}
      <div className={loading ? "hidden" : ""}>{children}</div>
    </MenuLoaderContext.Provider>
  );
}

const MenuLoaderContext = React.createContext<MenuLoaderContext>(null);

export function useMenuLoader() {
  return useSafeContext(
    MenuLoaderContext,
    "useMenuLoader",
    "MenuLoaderProvider"
  );
}
