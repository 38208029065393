import React from "react";

const Toggle = (props) => (
  <div className="toggle-container">
    <label>{props.label}</label>
    <span
      className={`toggle ${props.active ? "active" : ""} ${
        props.loading ? "loading" : ""
      }`}
      onClick={props.clickHandler}
    ></span>
  </div>
);

export default Toggle;
