import * as markets from "../services/markets";

export const getLocations = () => {
  return (dispatch) => {
    return markets
      .getLocations()
      .then((response) => {
        dispatch({
          type: "SET_LOCATIONS",
          locations: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          error,
        });
      });
  };
};
