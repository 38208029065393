import React from "react";
import classnames from "classnames";

export function IconButton({
  type = "button",
  onClick,
  variant = "primary",
  color = "blue",
  children,
}: IconButtonProps) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={classnames(
        "w-6 h-6 flex items-center justify-center rounded-full cursor-pointer hover:bg-opacity-75",
        {
          "bg-brand-blue text-white": color === "blue" && variant === "primary",
          "bg-brand-red text-white": color === "red" && variant === "primary",
          "hover:bg-gray-200": variant === "terniary",
        }
      )}
    >
      {children}
    </button>
  );
}

export type IconButtonProps = {
  type?: "button" | "submit" | "reset";
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: "primary" | "terniary";
  color?: "blue" | "red";
  children: React.ReactNode;
};
