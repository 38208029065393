import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editUserInformation } from "../../actions";

import EditUserInformationForm from "./forms/EditUserInformationForm";

class EditUserInformation extends React.Component {
  constructor() {
    super();
    this.state = {
      submitDisabled: false,
      error: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleSubmit(data) {
    this.setState({ submitDisabled: true });
    const payLoad = {
      name: data.name,
      email: data.email,
    };
    this.props
      .editUserInformation(payLoad)
      .then(() => {
        this.props.history.push("/profile");
      })
      .catch((error) => {
        if (!this._isMounted) return;
        this.setState({
          error: error.data.error,
          submitDisabled: false,
        });
        window.scrollTo(0, 0);
      });
  }
  render() {
    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Edit User Information</h1>
            <EditUserInformationForm
              profile={this.props.profile}
              auth={this.props.auth}
              submitDisabled={this.state.submitDisabled}
              submitHandler={this.handleSubmit}
              error={this.state.error}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editUserInformation }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserInformation);
