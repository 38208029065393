import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_DEFAULT_REGION,
});

var s3 = new AWS.S3();
const now = new Date();
const time_stamp = now.getTime();
const name = (id, folder) => `${folder}/image_${time_stamp}_${id}.jpg`;
const bucket = process.env.REACT_APP_S3_BUCKET;

export const uploadImage = (image, folder) => {
  return new Promise((resolve, reject) => {
    const imageObj = {
      Bucket: bucket,
      Key: `media/${name(image.id, folder)}`,
      ContentType: image.file.type,
      Body: image.file,
      ACL: "public-read",
    };
    s3.putObject(imageObj, (error) => {
      if (error) return reject(error);
      return resolve(name(image.id, folder));
    });
  });
};

export const deleteImages = (urls) => {
  var params = {
    Bucket: bucket,
    Delete: {
      Objects: urls.map((url) => ({
        Key: url.substring(url.indexOf(".com/") + 5),
      })),
    },
  };
  return new Promise((resolve, reject) =>
    s3.deleteObjects(params, (error) => {
      if (error) return reject.error;
      return resolve();
    })
  );
};
