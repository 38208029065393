import React from "react";
import { useField } from "react-final-form";

export function FieldError({ name }) {
  const { meta } = useField(name);

  const error = meta.submitError || meta.error;
  if (!meta.touched || !error) {
    return null;
  }

  return <p className="text-brand-red">{error}</p>;
}
