import React from "react";
import classnames from "classnames";
import { useToggle } from "components/useToggle";
import { useDietary } from "api/menu/useDietary";
import { useClickOutside } from "components/useClickOutside";

export function DietaryInformationSelect(props: DietaryInformationSelectProps) {
  const dietary = useDietary();

  const dialog = useToggle(false);
  const ref = useClickOutside(() => dialog.disable());

  const dietaryItems = dietary.data ?? [];

  const handleSelect = (newId) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.value.find((id) => id === newId)) {
      props.onChange(props.value.filter((id) => id !== newId));
    } else {
      props.onChange([...props.value, newId]);
    }
  };

  const displayValue = dietaryItems
    .filter((item) => props.value.includes(item.id))
    .map((item) => item.name)
    .join(", ");

  return (
    <div className="relative">
      <input
        className={classnames(
          props.className,
          "w-full px-3 leading-10 bg-white rounded-lg cursor-text truncate"
        )}
        placeholder="Dietary preferences"
        value={displayValue}
        readOnly
        onClick={() => dialog.enable()}
      />

      {dialog.value && !!dietaryItems.length && (
        <ul
          ref={ref}
          className="absolute top-8 left-0 py-4 bg-white border border-gray-200 rounded-lg shadow-lg"
        >
          {dietaryItems.map((dietary) => {
            const selected = props.value.find((id) => dietary.id === id);
            return (
              <li key={dietary.id}>
                <button
                  type="button"
                  className={classnames("w-full px-4 py-1 text-left", {
                    "bg-brand-blue hover:bg-opacity-80 text-white": selected,
                    "hover:bg-gray-100": !selected,
                  })}
                  onClick={handleSelect(dietary.id)}
                >
                  {dietary.name}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

type DietaryInformationSelectProps = {
  value: any[];
  onChange: (value: any[]) => void;
  className?: string;
};
