import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { submitStep } from "../../actions";

import EditTradingInfoForm from "../profile/forms/EditFurtherInfoForm";
import { ProgressBar } from "../ProgressBar";

class CreateProfileStepTradingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate(prevState) {
    const { changeStep, isSubmitting, error } = this.props;
    if (prevState.isSubmitting && !isSubmitting) {
      if (error) {
        this.setState({ submitDisabled: false });
        window.scrollTo(0, 0);
      } else {
        changeStep(3);
        window.scrollTo(0, 0);
      }
    }
  }
  handleSubmit(data) {
    this.setState({ submitDisabled: true });
    const payLoad = {
      card: data.card,
      cash: data.cash,
      opening_hours: data.opening_hours,
      private_hire_email: data.private_hire_email,
      website_link: data.website_link,
      onboarding_stage: 2,
    };
    const { submitStep } = this.props;
    submitStep(payLoad);
  }
  render() {
    const {
      changeStep,
      onboarding,
      error,
      currentStep,
      stepCount,
    } = this.props;
    const { submitDisabled } = this.state;
    const { handleSubmit } = this;

    return (
      <Fragment>
        <div className="create-profile__breadcrumbs">
          <a onClick={() => changeStep(currentStep - 1)} className="step-back">
            Step back
          </a>

          <ProgressBar value={((currentStep / stepCount) * 100).toFixed(0)} />
        </div>

        <h2>Trading Info</h2>
        <EditTradingInfoForm
          buttonText="Continue"
          error={error}
          profile={{
            cash: onboarding.cash ? onboarding.cash : false,
            card: onboarding.card ? onboarding.card : false,
          }}
          locations={this.props.locations}
          submitDisabled={submitDisabled}
          submitHandler={handleSubmit}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  onboarding: state.onboarding.business,
  locations: state.markets.locations,
  isSubmitting: state.onboarding.stepTwo.isSubmitting,
  error: state.onboarding.stepTwo.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ submitStep }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProfileStepTradingInfo);
