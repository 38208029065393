import React from "react";
import { Link } from "react-router-dom";

import { Button } from "components/Button";
import { MenuUploadButton } from "components/menu/MenuUploadButton";

export function MenuEmpty() {
  return (
    <div className="min-h-screen flex bg-gray-200">
      <div className="w-full max-w-3xl flex items-center justify-center bg-gray-100">
        <div className="max-w-lg">
          <h1 className="mb-4 text-2xl font-bold">
            Create your menu with <br /> Menu Builder
          </h1>
          <p className="mb-6 text-gray-700">
            Want to save your time? Let’s try our menu builder. It’s easy and
            efficent way to create your menu without any typing.
          </p>

          <div className="flex items-center space-x-3">
            <MenuUploadButton>
              <Button>Upload your menu</Button>
            </MenuUploadButton>
            <p className="text-gray-600 text-xs uppercase">or</p>
            <Button color="gray" component={Link} to="/menu/manual">
              Build menu manually
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
