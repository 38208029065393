import React from "react";
import validation from "../../../helpers/validation";

import MainError from "../../forms/MainError";

class EditUserInformationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      name: props.auth.user.name,
      email: props.auth.user.email,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit.disabled = true;
    if (!validation(e.target)) {
      this.refs.submit.disabled = false;
      this.setState({ valid: false });
      window.scrollTo(0, 0);
    } else {
      this.setState({ valid: true });
    }
    const data = this.state;
    this.props.submitHandler(data);
  }
  render() {
    return (
      <div>
        {this.props.error ? <MainError errorText={this.props.error} /> : ""}
        {!this.state.valid ? (
          <MainError
            errorText={
              "Error: Please check you have entered all information correctly."
            }
          />
        ) : (
          ""
        )}
        <form noValidate onSubmit={this.handleSubmit}>
          <fieldset>
            <label>
              Your Name
              <input
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
                data-validate="name"
                required
              />
            </label>
            <span
              className="hide validation-message"
              data-validate-message="name"
            >
              Error: Please provide a valid name
            </span>
            <label>
              Email Address
              <input
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
                data-validate="email"
                required
              />
            </label>
            <span
              className="hide validation-message"
              data-validate-message="email"
            >
              Error: Please provide a valid email address
            </span>
          </fieldset>
          <button
            ref="submit"
            className="button expanded"
            type="submit"
            disabled={this.props.submitDisabled}
          >
            Save
          </button>
        </form>
      </div>
    );
  }
}

export default EditUserInformationForm;
