const initialState = {
  stepOne: {
    isSubmitting: false,
    error: null,
  },
  stepTwo: {
    isSubmitting: false,
    error: null,
  },
  stepThree: {
    isSubmitting: false,
    error: null,
  },
  stepPhotos: {
    isSubmitting: false,
    error: null,
  },
  stepFive: {
    isSubmitting: false,
    error: null,
  },
  stepSix: {
    isSubmitting: false,
    error: null,
  },
  stepSchedule: {
    isSubmitting: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_STEPS_SUCCESS":
      return {
        ...state,
        ...action.data,
      };
    case "GET_STEPS_FAIL":
      return {
        ...state,
        error: action.error,
      };
    case "SUBMIT_STEP_REQUEST":
      return {
        ...state,
        [action.step]: {
          error: null,
          isSubmitting: true,
        },
      };
    case "SUBMIT_STEP_SUCCESS":
      return {
        ...state,
        [action.step]: {
          error: null,
          isSubmitting: false,
        },
        ...action.data,
      };
    case "SUBMIT_STEP_FAIL":
      return {
        ...state,
        [action.step]: {
          error: action.error,
          isSubmitting: false,
        },
      };
    default:
      return state;
  }
};
