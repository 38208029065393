import React from "react";

const Modal = (props) => (
  <div
    className={`modal-bg ${props.showModal ? "modal-bg__show" : ""} ${
      props.mobileOnly ? "modal-bg__mobile-only" : ""
    } ${props.fullscreen ? "fullscreen" : ""}`}
    onClick={props.closeModal}
  >
    <div
      className={`modal ${props.fullscreen ? "fullscreen" : ""} ${
        props.halfscreen ? "halfscreen" : ""
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      {props.fullscreen || props.halfscreen ? (
        <div className="modal__header">
          <h2 className="modal__header__title">{props.title}</h2>
          <div className="modal__header__close" onClick={props.closeModal} />
        </div>
      ) : (
        ""
      )}
      <div className="modal__content">{props.children}</div>
    </div>
  </div>
);

export default Modal;
