import React from "react";
import MenuItem from "./MenuItem";

const SidebarMenu = (props) => {
  const { status, location, closeSidebar, checkedInStatus } = props;

  return (
    <nav>
      <ul className="sidebar__menu">
        {status === 2 ? (
          ""
        ) : (
          <MenuItem
            name="Dashboard"
            location={location}
            to="/dashboard"
            closeSidebar={closeSidebar}
          />
        )}

        {status === 2
          ? ""
          : checkedInStatus && (
              <MenuItem
                name="Check Out"
                location={location}
                to="/checkout"
                closeSidebar={closeSidebar}
              />
            )}

        <MenuItem
          name="Schedule"
          className="Check In"
          location={location}
          to="/schedule"
          closeSidebar={closeSidebar}
        />

        <MenuItem
          name="Menu"
          location={location}
          to="/menu"
          closeSidebar={closeSidebar}
        />

        <MenuItem
          name="Chalkboard"
          location={location}
          to="/chalkboard"
          closeSidebar={closeSidebar}
        />

        {/*See: https://github.com/HawkkerApp/hawkker-web/issues/72*/}
        {/*<MenuItem name="Messages" location={location} to="/messages" closeSidebar={closeSidebar}>*/}
        {/*  {unreadMessageCount > 0*/}
        {/*    ? <span className="unread-messages-badge sidebar__menu__unread">*/}
        {/*        {unreadMessageCount}*/}
        {/*      </span>*/}
        {/*    : ''*/}
        {/*  }*/}
        {/*</MenuItem>*/}

        <MenuItem
          name="Profile"
          location={location}
          to="/profile"
          closeSidebar={closeSidebar}
        />

        <MenuItem
          name="Help"
          location={location}
          to="https://hawkker.com/help/"
          closeSidebar={closeSidebar}
        />
      </ul>
    </nav>
  );
};

export default SidebarMenu;
