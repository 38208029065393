import React from "react";

import RadioItem from "../forms/RadioItem";
import InputCharCount from "../forms/InputCharCount";
import VendorLocationNoBrowserGPS from "./VendorLocationNoBrowserGPS";
import { PostcodeAreaField } from "./PostcodeAreaField";
import { withGoogleMaps } from "../withGoogleMaps";

class LocationDetails extends React.Component {
  state = {
    area: (this.props.market && this.props.market.area) || null,
    coordinates: this.props.coordinates || {},
    isFineTuningLocation: false,
  };

  constructor(props) {
    super(props);

    this.onChangeArea = this.onChangeArea.bind(this);
    this.onLocationUpdate = this.onLocationUpdate.bind(this);
    this.handleFineTuneToggle = this.handleFineTuneToggle.bind(this);
  }

  onChangeArea(e) {
    const area = e.currentTarget.value;
    this.setState({ area, isFineTuningLocation: false, coordinates: {} });
    this.props.onChangeMarket({});
  }

  onLocationUpdate = (location) => {
    this.props.onFineTuneLocationChange({
      lat: location.lat,
      lng: location.lng,
    });

    this.setState({
      coordinates: {
        lat: location.lat,
        lng: location.lng,
      },
    });
  };

  handleFineTuneToggle() {
    const mapExists =
      window.google && window.google.maps && window.google.maps.Geocoder;

    // Try to geocode location only when opening map and when fine-tune location isn't set
    if (
      mapExists &&
      !this.state.isFineTuningLocation &&
      (this.state.coordinates == null ||
        !this.state.coordinates.lat ||
        !this.state.coordinates.lng)
    ) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address: [this.props.street, this.props.postcode].join(" ") },
        (results, status) => {
          if (status !== "OK") {
            // Just toggle in case of error
            this.setState({
              isFineTuningLocation: !this.state.isFineTuningLocation,
            });
            return;
          }

          const coordinates = results[0].geometry.location;
          this.setState({
            coordinates: {
              lat: coordinates.lat(),
              lng: coordinates.lng(),
            },
            isFineTuningLocation: true,
          });
        }
      );
    } else {
      // Toggle fine-tune map
      this.setState({ isFineTuningLocation: !this.state.isFineTuningLocation });
    }
  }

  render() {
    const {
      locationType,
      street,
      postcode,
      postcodeArea,
      market,
      availableMarkets,
      onLocationTypeChange,
      onFixedDetailsChange,
      onChangeMarket,
      onChangePostcodeArea,
    } = this.props;
    const { area, isFineTuningLocation, coordinates } = this.state;

    const areas = Array.from(
      new Set(availableMarkets.map((m) => m.area && m.area).filter(Boolean))
    );
    const markets = availableMarkets
      .filter((m) => m.area === area && m.market_name)
      .map((m) => m.market_name);

    return (
      <div>
        <RadioItem
          id="market_stall"
          item="Market Stall/Pitch"
          name="location_type-market"
          checked={locationType === "market"}
          changeHandler={(e) => {
            onLocationTypeChange("market");
            this.setState({ isFineTuningLocation: false });
          }}
        />
        <RadioItem
          id="fixed_location"
          item="Fixed Location"
          name="location_type-fixed"
          checked={locationType === "fixed"}
          changeHandler={(e) => {
            onLocationTypeChange("fixed");
            this.setState({ isFineTuningLocation: false });
          }}
        />
        <RadioItem
          id="private_location"
          item="Delivery Only Location"
          name="location_type-private"
          checked={locationType === "private"}
          changeHandler={(e) => {
            onLocationTypeChange("private");
            this.setState({ isFineTuningLocation: false });
          }}
        />
        <RadioItem
          id="webshop_location"
          item="Webshop Only"
          name="location_type-webshop"
          checked={locationType === "webshop"}
          changeHandler={(e) => {
            onLocationTypeChange("webshop");
            this.setState({ isFineTuningLocation: false });
          }}
        />

        {locationType === "fixed" && (
          <div>
            <InputCharCount
              changeHandler={(e) =>
                onFixedDetailsChange(e.target.name, e.target.value)
              }
              label="Postcode"
              type="text"
              maxLength="10"
              name="postcode"
              value={postcode}
              validate="name"
              required
              showRequiredMarker
            />
            <InputCharCount
              changeHandler={(e) =>
                onFixedDetailsChange(e.target.name, e.target.value)
              }
              label="Street Name"
              type="text"
              maxLength="150"
              name="street"
              value={street}
              validate="name"
              required
              showRequiredMarker
            />
          </div>
        )}
        {locationType === "market" && (
          <div>
            <label htmlFor="area">
              Area<span className="input-required">*</span>
            </label>
            <select
              id="area"
              name="area"
              onChange={this.onChangeArea}
              required
              value={area}
            >
              <option value="">{`Please select area`}</option>
              {areas
                .sort((a, b) => (a < b ? -1 : 1))
                .map((area, i) => (
                  <option key={i} value={area}>
                    {area}
                  </option>
                ))}
            </select>

            <label htmlFor="market">
              Market Name<span className="input-required">*</span>
            </label>
            <select
              id="market"
              name="market"
              disabled={!area}
              onChange={(e) => {
                const chosenMarket = availableMarkets.find(
                  (m) => m.market_name === e.currentTarget.value
                );
                onChangeMarket(chosenMarket);
                this.setState({
                  coordinates: chosenMarket.coordinates,
                  isFineTuningLocation: false,
                });
              }}
              required
              value={(market && market.market_name) || null}
            >
              <option value="">{`Please select market`}</option>
              {markets
                .sort((a, b) => (a < b ? -1 : 1))
                .map((m, i) => (
                  <option key={i} value={m}>
                    {m}
                  </option>
                ))}
            </select>
          </div>
        )}
        {(locationType === "private" || locationType === "webshop") && (
          <div>
            <label htmlFor="postcode_area">
              Postcode Area<span className="input-required">*</span>
            </label>

            <PostcodeAreaField
              id="postcode_area"
              value={postcodeArea}
              onChange={(value) => onChangePostcodeArea(value)}
            />
          </div>
        )}

        {locationType !== "private" && locationType !== "webshop" && (
          <>
            <h4>
              Fine Tune Location (helps eaters find exactly where you are)
              <span className="input-required">*</span>
            </h4>

            <button
              className="button expanded"
              onClick={this.handleFineTuneToggle}
            >
              {isFineTuningLocation ? "Close Map" : "Fine Tune"}
            </button>

            {isFineTuningLocation && (
              <VendorLocationNoBrowserGPS
                onLocationUpdate={this.onLocationUpdate}
                onLocationSet={() => {}}
                ignoreBrowserLocation
                defaultLat={coordinates && coordinates.lat}
                defaultLng={coordinates && coordinates.lng}
              />
            )}

            <input
              name="latitude"
              disabled
              type="text"
              placeholder={"Latitude"}
              value={(coordinates && coordinates.lat) || ""}
              required
            />

            <input
              name="longitude"
              disabled
              type="text"
              placeholder={"Longitude"}
              value={(coordinates && coordinates.lng) || ""}
              required
            />
          </>
        )}
      </div>
    );
  }
}

export default withGoogleMaps(LocationDetails);
