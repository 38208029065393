import * as auth from "../services/auth";

export const loginSuccess = (token, user, intercomUserhash) => {
  if (user != null && window.analytics != null) {
    window.analytics.identify(user.id, {
      name: user.name,
      email: user.email,
    });
  }

  return {
    type: "LOGIN_SUCCESS",
    token,
    user,
    intercomUserhash,
  };
};

export const login = (credentials) => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
    });
    return auth
      .login(credentials)
      .then((response) => {
        dispatch(
          loginSuccess(
            response.data.token,
            response.data.user,
            response.data.intercom_user_hash_web
          )
        );
      })
      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
          error: error.data.error,
        });
        return Promise.reject();
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    return auth
      .logout()
      .then(() => {
        localStorage.clear();
        dispatch({
          type: "LOGOUT",
        });
        // force page reload to clear all cache
        window.location.href = "/";
      })
      .catch(() => {
        localStorage.clear();
        dispatch({
          type: "LOGOUT",
        });
        // force page reload to clear all cache
        window.location.href = "/";
      });
  };
};

export const logoutOnboarding = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch({
      type: "LOGOUT",
    });
    // force page reload to clear all cache
    window.location.href = "/";
  };
};

export const getAuthUser = () => {
  return (dispatch) => {
    return auth
      .getAuthUser()
      .then((response) => {
        dispatch(
          loginSuccess(
            response.data.token,
            response.data.user,
            response.data.intercom_user_hash_web
          )
        );
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deleteAccount = () => {
  return (dispatch) => {
    return auth
      .deleteAccount()
      .then(() => {
        dispatch({
          type: "LOGOUT",
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          error,
        });
      });
  };
};
