import React from "react";

import comingSoon from "assets/images/coming-soon.svg";

const RegistrationSuccess = () => (
  <div className="onboarding-container grid-x">
    <div className="onboarding cell medium-9">
      <div className="grid-x align-center registration-success">
        <img src={comingSoon} alt="Coming Soon" />
        <span className="registration-success__pending-title">
          You have successfully created a <br />
          Hawkker account
        </span>
        <p className="text-center registration-success__pending-blurb">
          The next step is to build your business profile - we've sent a link to
          the email address you provided. We recommend building your business
          profile on a computer, but you can do it on your mobile device too!
        </p>
      </div>
    </div>
  </div>
);

export default RegistrationSuccess;
