import React from "react";

import ReviewsTableRow from "./ReviewsTableRow";

const ReviewsTable = (props) => {
  const { reviews } = props;
  return (
    <table className="reviews-table">
      <thead className="reviews-table__main-thead">
        <tr>
          <td>Date</td>
          <td>Rating</td>
          <td>Amount</td>
          <td></td>
        </tr>
      </thead>
      {reviews.results.map((review) => (
        <ReviewsTableRow key={review.id} review={review} />
      ))}
    </table>
  );
};

export default ReviewsTable;
