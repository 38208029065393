import { ReactComponent as GlutenFree } from "./gluten-free.svg";
import { ReactComponent as Halal } from "./halal.svg";
import { ReactComponent as Kosher } from "./kosher.svg";
import { ReactComponent as LactoseFree } from "./lactose-free.svg";
import { ReactComponent as LowCarb } from "./low-carb.svg";
import { ReactComponent as Raw } from "./raw.svg";
import { ReactComponent as Vegan } from "./vegan.svg";
import { ReactComponent as Vegetarian } from "./vegetarian.svg";

export const dietaryIcons = {
  glutenfree: GlutenFree,
  halal: Halal,
  kosher: Kosher,
  lactosefree: LactoseFree,
  lowcarb: LowCarb,
  raw: Raw,
  vegan: Vegan,
  vegetarian: Vegetarian,
};
