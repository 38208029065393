import React from "react";
import { useMenuSections } from "api/menu/useMenuSections";
import { useCreateMenuSection } from "api/menu/useCreateMenuSection";
import { useUpdateMenuSection } from "api/menu/useUpdateMenuSection";
import { useRemoveMenuSection } from "api/menu/useRemoveMenuSection";
import { useSafeContext } from "components/useSafeContext";

export type MenuSectionsOpsContextType = {
  sections: any;
  activeSection: number | null;
  handleCreate: (values: any) => void;
  handleUpdate: (id: number, values: any) => void;
  handleDestroy: (id: number) => void;
  handleReorder: (dragResult: any) => void;
  handleSelect: (id: number | null) => void;
};

const MenuSectionsOpsContext = React.createContext(null);

export function MenuSectionsOpsProvider({ children }) {
  const [activeSection, setActiveSection] = React.useState<number | null>(null);

  const sections = useMenuSections({ expand: ["dish_count"] });
  const createSection = useCreateMenuSection();
  const updateSection = useUpdateMenuSection();
  const destroySection = useRemoveMenuSection();

  const handleCreate = async (values) => {
    const response = await createSection(values);
    await sections.mutate();

    if (activeSection == null) {
      setActiveSection(response["id"]);
    }
  };

  const handleUpdate = async (id, values) => {
    await updateSection(id, values);
    await sections.mutate();
  };

  const handleDestroy = async (id) => {
    await destroySection(id);
    await sections.mutate();

    if (id === activeSection) {
      setActiveSection(null);
    }
  };

  const handleReorder = async (result) => {
    // dropped outside the list
    if (!result.destination) return;

    await updateSection(result.draggableId, {
      insert_before: result.destination.index,
    });
    await sections.mutate();
  };

  const handleSelect = async (newActiveSection) => {
    setActiveSection((activeSection) =>
      activeSection === newActiveSection ? null : newActiveSection
    );
  };

  const ctx = React.useMemo(
    () => ({
      sections,
      activeSection,
      handleCreate,
      handleUpdate,
      handleDestroy,
      handleReorder,
      handleSelect,
    }),
    [JSON.stringify(sections), activeSection]
  );

  return (
    <MenuSectionsOpsContext.Provider value={ctx}>
      {children}
    </MenuSectionsOpsContext.Provider>
  );
}

export function useMenuSectionsOps() {
  return useSafeContext(
    MenuSectionsOpsContext,
    "useMenuSectionsOps",
    "MenuSectionsOpsProvider"
  );
}
