import * as transactions from "../services/transactions";

export const getTransactions = () => (dispatch) =>
  transactions
    .getTransactions()
    .then((response) => {
      dispatch({
        type: "SET_TRANSACTIONS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
      return Promise.reject();
    });

export const getNextTransactions = (url) => (dispatch) =>
  transactions
    .getNextTransactions(url)
    .then((response) => {
      dispatch({
        type: "SET_NEXT_TRANSACTIONS",
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });

export const postTransaction = (transaction) => (dispatch) =>
  transactions
    .postTransaction(transaction)
    .then((response) => {
      dispatch({
        type: "DELETE_ALL_ERRORS",
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });
