// Import IE Polyfills
import "core-js/es6/array";
import "core-js/es6/symbol";
import "core-js/es6/map";
import "core-js/es6/math";
import URLSearchParams from "@ungap/url-search-params";

// Import React Dependencies
import React from "react";
import { render } from "react-dom";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";

// Import third-party plugins
import smoothscroll from "smoothscroll-polyfill";
import * as Sentry from "@sentry/browser";

// Import Components
import { version, environment } from "../package.json";
import LoginContainer from "./containers/LoginContainer";
import RegisterContainer from "./containers/RegisterContainer";
import PrivateRoute from "./containers/PrivateRoute";
import CreateProfileContainer from "./containers/CreateProfileContainer";
import MainLayout from "./containers/MainLayout";
import ForgottenPasswordContainer from "./containers/ForgottenPasswordContainer";
import VendorApprovedContainer from "./containers/VendorApprovedContainer";
import { SegmentAnalytics } from "./components/SegmentAnalytics";

// Import Services
import * as auth from "./services/auth";
import { unregister } from "./registerServiceWorker";

// Import Actions
import { loginSuccess } from "./actions";

// Import Styles
import "./styles/main.css";

// Import Data Store
import store, { history } from "./store";

Sentry.init({
  dsn:
    environment === "dev"
      ? ""
      : "https://1777e61be2b84cc588cff343e591d582@sentry.io/1388797",
  release: `hawkker-web@${version}`,
  debug: environment === "dev",
  environment,
});

// Integrate Router and Store into React
const router = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <SegmentAnalytics />
        <Switch>
          <Route exact path="/" component={LoginContainer} />
          <Route path="/sign-up" component={RegisterContainer} />
          <Route
            path="/forgotten-password"
            component={ForgottenPasswordContainer}
          />
          <Route path="/create-profile" component={CreateProfileContainer} />
          <Route path="/vendor-approved" component={VendorApprovedContainer} />
          <PrivateRoute component={MainLayout} />
        </Switch>
      </div>
    </ConnectedRouter>
  </Provider>
);

// If user details in local storage, login
if (auth.isLoggedIn()) {
  const user = auth.getUser();
  const token = auth.getToken();
  const intercomHash = auth.getIntercomHash();
  store.dispatch(loginSuccess(token, JSON.parse(user) || null, intercomHash));
}

if (window.location.search) {
  const params = new URLSearchParams(window.location.search);
  const referralCode = params.get("referral");
  if (referralCode) {
    window.localStorage.setItem("hawkker-referral", referralCode);
    window.history.replaceState({}, "", window.location.href.split("?")[0]);
  }
}

render(router, document.getElementById("root"));

smoothscroll.polyfill();

// unregister service worker
unregister();
