import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import capitalise from "../../helpers/capitalise";

import ProfileCard from "./ProfileCard";
import facebook from "../../assets/icons/facebook.svg";
import twitter from "../../assets/icons/twitter.svg";
import instagram from "../../assets/icons/instagram.svg";

class ProfileOverview extends React.Component {
  constructor() {
    super();
    this.state = {
      currentTab: "business",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  renderList(list) {
    return list.map((item, i) =>
      i + 1 === list.length ? `${item.name}.` : `${item.name}, `
    );
  }
  renderSocialMediaLinks() {
    const socialLinks = this.props.profile.social_links;

    return (
      <div>
        <div className="profile__social-link">
          <img src={facebook} alt="facebook" />
          <div className="profile__social-link__info">
            <span className="profile__card__info-label">Facebook</span>
            <p>
              {socialLinks && socialLinks.facebook ? (
                socialLinks.facebook
              ) : (
                <span className="profile__no-info">Not provided</span>
              )}
            </p>
          </div>
        </div>
        <div className="profile__social-link">
          <img src={twitter} alt="twitter" />
          <div className="profile__social-link__info">
            <span className="profile__card__info-label">Twitter</span>
            <p>
              {socialLinks && socialLinks.twitter ? (
                socialLinks.twitter
              ) : (
                <span className="profile__no-info">Not provided</span>
              )}
            </p>
          </div>
        </div>
        <div className="profile__social-link">
          <img src={instagram} alt="instagram" />
          <div className="profile__social-link__info">
            <span className="profile__card__info-label">Instagram</span>
            <p>
              {socialLinks && socialLinks.instagram ? (
                socialLinks.instagram
              ) : (
                <span className="profile__no-info">Not provided</span>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderIngredients() {
    if (this.props.profile.ingredients) {
      return this.props.profile.ingredients.map((ingredient, i) => {
        let name;
        i === this.props.profile.ingredients.length - 1
          ? (name = `${capitalise(ingredient)}.`)
          : (name = `${capitalise(ingredient)}, `);
        return name;
      });
    }
    return <span className="profile__no-info">Unspecified</span>;
  }

  toggleTab(tab) {
    this.setState({
      currentTab: tab,
    });
  }

  render() {
    const { profile } = this.props;

    const privateHireEmail = profile.private_hire_email;
    const websiteLink = profile.website_link;
    const prefix = "http://";
    let websiteLinkWithProtocol = websiteLink;

    if (websiteLink && websiteLink.substr(0, prefix.length) !== prefix) {
      websiteLinkWithProtocol = prefix + websiteLink;
    }

    const business = () => (
      <div className="grid-x grid-margin-x">
        <div className="cell medium-6 large-4 profile__cell">
          <ProfileCard
            title="Business Info"
            editLink={"profile/edit-business-info"}
          >
            <span className="profile__card__info-label">Business Name</span>
            <p>{this.props.profile.name}</p>
            <span className="profile__card__info-label">Tagline</span>
            <p>{this.props.profile.tagline}</p>
            <span className="profile__card__info-label">Biography</span>
            <p>{this.props.profile.biography}</p>
            <span className="profile__card__info-label">Cuisine Types</span>
            <p>{this.renderList(this.props.profile.cuisine)}</p>
            {this.renderSocialMediaLinks()}
          </ProfileCard>
          <ProfileCard
            title="Ingredients"
            editLink={"profile/edit-ingredients"}
          >
            <p>{this.renderIngredients()}</p>
          </ProfileCard>
        </div>

        <div className="cell medium-6 large-4 profile__cell">
          <ProfileCard title="Your Invite Code">
            <h1>{this.props.profile.vendor.referral_code}</h1>
            <p>
              <a
                href="https://hawkker.com/invite_code/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Find out more
              </a>
            </p>
          </ProfileCard>

          <ProfileCard
            title="Edit Further Info"
            editLink={"profile/edit-further-info"}
          >
            <p>
              Website Link:{" "}
              {websiteLink && (
                <a
                  href={websiteLinkWithProtocol}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {websiteLink}
                </a>
              )}
            </p>
            <p>
              Private Hire Email:{" "}
              {privateHireEmail && (
                <a href={`mailto:${privateHireEmail}`} target="_blank">
                  {privateHireEmail}
                </a>
              )}
            </p>

            <span className="profile__card__info-label">Payment Options</span>
            <p>
              {this.props.profile.cash ? "Cash" : ""}{" "}
              {this.props.profile.cash && this.props.profile.card ? "and" : ""}{" "}
              {this.props.profile.card ? "Card" : ""}
            </p>
          </ProfileCard>
          <ProfileCard title="Allergens" editLink={"profile/edit-allergens"}>
            <p>{this.renderList(this.props.profile.allergens)}</p>
            {!this.props.profile.allergens.length ? (
              <span className="profile__no-info">
                You haven't specified any allergens
              </span>
            ) : (
              ""
            )}
          </ProfileCard>
        </div>

        <div className="cell medium-6 large-4 profile__cell">
          <ProfileCard
            title="Business Address"
            editLink={"profile/edit-address"}
          >
            {profile.vendor.address_line_1 ? (
              <React.Fragment>
                <span className="profile__card__info-label">
                  Address Line 1
                </span>
                <p>{profile.vendor.address_line_1}</p>
                <span className="profile__card__info-label">
                  Address Line 2
                </span>
                <p>{profile.vendor.address_line_2}</p>
                <span className="profile__card__info-label">City</span>
                <p>{profile.vendor.city}</p>
                <span className="profile__card__info-label">County</span>
                <p>{profile.vendor.county}</p>
                <span className="profile__card__info-label">Postcode</span>
                <p>{profile.vendor.postcode}</p>
              </React.Fragment>
            ) : (
              <Link to="profile/edit-address">Add Your Business Address</Link>
            )}
          </ProfileCard>
          <ProfileCard title="Photos" editLink={"profile/edit-images"}>
            <div className="grid-x grid-margin-x grid-margin-y profile__card__images">
              {!this.props.profile.photos.length ? (
                <div className="cell">
                  <span className="profile__no-info">
                    You haven't added any photos yet
                  </span>
                </div>
              ) : (
                this.props.profile.photos
                  .sort((a, b) => b.hero - a.hero)
                  .map((image) => (
                    <div
                      key={image.id}
                      className={`cell ${image.hero ? "small-12" : "small-6"}`}
                    >
                      <div
                        className="edit-image__image-preview"
                        style={{ backgroundImage: `url(${image.image})` }}
                      />
                    </div>
                  ))
              )}
            </div>
          </ProfileCard>
        </div>
      </div>
    );
    const user = () => (
      <div className="grid-x grid-margin-x align-center">
        <div className="cell medium-6 large-5 profile__cell">
          <ProfileCard
            title="User Information"
            editLink={"profile/edit-user-information"}
          >
            <span className="profile__card__info-label">Name</span>
            <p>{this.props.auth.user.name}</p>
            <span className="profile__card__info-label">Email</span>
            <p>{this.props.auth.user.email}</p>
            <span className="profile__card__info-label">Password</span>
            <Link to="/profile/change-password">Change Password</Link>
            <span className="profile__card__delete-link-wrapper">
              <Link
                className="profile__card__delete-link"
                to="profile/delete-account"
              >
                Delete Account
              </Link>
            </span>
          </ProfileCard>
        </div>
      </div>
    );
    return (
      <div>
        <div className="tab-bar align-center">
          <button
            className={`tab-bar__btn ${
              this.state.currentTab === "business" ? "active" : ""
            }`}
            onClick={() => this.toggleTab("business")}
          >
            Business
          </button>
          <button
            className={`tab-bar__btn ${
              this.state.currentTab === "business" ? "" : "active"
            }`}
            onClick={() => this.toggleTab("user")}
          >
            User
          </button>
        </div>
        <div className="grid-container profile">
          {this.state.currentTab === "business" ? business() : user()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  status: state.auth.user.status,
  checkedInStatus: state.profile.open,
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileOverview);
