const initialState = {
  token: null,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        token: action.token,
        user: action.user,
        intercomUserHash: action.intercomUserhash,
        error: null,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        error: action.error,
      };
    case "EDIT_USER":
      return {
        ...state,
        user: {
          ...state.user,
          name: action.data.name,
          email: action.data.email,
        },
      };
    case "UPDATE_USER_TYPE":
      return {
        ...state,
        user: {
          ...state.user,
          status: action.data.vendor.status,
        },
      };
    default:
      return state;
  }
};
