import React from "react";
import DashboardDishList from "./DashboardDishList";

const FullDishList = (props) => {
  const { dishes, isLoading } = props;

  if (isLoading) return <div className="loading-placeholder" />;

  return (
    <div className="grid-x align-center">
      <div className="cell single-col-layout">
        <DashboardDishList
          title="Most Purchased Dishes"
          largeTitle={true}
          description="Past 30 days."
          dishes={dishes}
        />
      </div>
    </div>
  );
};

export default FullDishList;
