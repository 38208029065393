import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

class WeekdayHours extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeOpen = this.handleChangeOpen.bind(this);
    this.handleChangeClose = this.handleChangeClose.bind(this);
  }

  handleChangeOpen(e) {
    const openingTime = `${this.refs.openingHour.value}:${this.refs.openingMinute.value}`;
    this.props.onChangeTime(
      this.props.openingHoursDay.weekday,
      "from_hour",
      openingTime
    );
  }

  handleChangeClose(e) {
    const closingTime = `${this.refs.closingHour.value}:${this.refs.closingMinute.value}`;
    this.props.onChangeTime(
      this.props.openingHoursDay.weekday,
      "to_hour",
      closingTime
    );
  }

  renderHourOptions() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i.toString());
    }
    return hours.map((hour, i) => {
      return (
        <option key={i}>
          {hour.toString().length < 2 ? `0${hour}` : hour}
        </option>
      );
    });
  }

  renderMinutesOptions() {
    const minutes = ["00", "15", "30", "45"];
    return minutes.map((min, i) => <option key={i}>{min}</option>);
  }

  render() {
    const {
      openingHoursDay: { open, from_hour, to_hour, weekday, timeValid },
      onIsClosedChange,
    } = this.props;
    const weekdayName = moment()
      .set({ isoWeekday: weekday + 1 })
      .format("ddd");

    return (
      <div className="time-range">
        <div className="time-range__top">
          <span className="time-range__top__label">{weekdayName}</span>
          <span>
            <input
              checked={!open}
              data-toggle-required-target={`${weekdayName}`}
              data-validate="opening-time"
              data-weekday={weekday}
              id={`${weekdayName}-checkbox`}
              name={weekdayName}
              onChange={(e) => onIsClosedChange(weekday, e.target.checked)}
              type="checkbox"
            />
            <label htmlFor={`${weekdayName}-checkbox`}>closed</label>
          </span>
        </div>
        <div className="time-range__middle">
          <select
            className={`time-range__input ${
              timeValid === false ? "invalid" : ""
            }`}
            data-weekday={weekday}
            data-validate="opening-time"
            disabled={!open}
            name={`${weekdayName}-hour-from`}
            onChange={this.handleChangeOpen}
            ref="openingHour"
            required={open}
            value={from_hour.substr(0, 2)}
          >
            {this.renderHourOptions()}
          </select>
          :
          <select
            className={`time-range__input ${
              timeValid === false ? "invalid" : ""
            }`}
            data-weekday={weekday}
            data-validate="opening-time"
            disabled={!open}
            name={`${weekdayName}-minute-from`}
            onChange={this.handleChangeOpen}
            ref="openingMinute"
            required={open}
            value={from_hour.substr(3, 2)}
          >
            {this.renderMinutesOptions()}
          </select>
          &#8211;
          <select
            className={`time-range__input ${
              timeValid === false ? "invalid" : ""
            }`}
            data-weekday={weekday}
            data-validate="opening-time"
            disabled={!open}
            name={`${weekdayName}-hour-to`}
            onChange={this.handleChangeClose}
            ref="closingHour"
            required={open}
            value={to_hour.substr(0, 2)}
          >
            {this.renderHourOptions()}
          </select>
          :
          <select
            className={`time-range__input ${
              timeValid === false ? "invalid" : ""
            }`}
            data-weekday={weekday}
            data-validate="opening-time"
            disabled={!open}
            name={`${weekdayName}-minute-to`}
            onChange={this.handleChangeClose}
            ref="closingMinute"
            required={open}
            value={to_hour.substr(3, 2)}
          >
            {this.renderMinutesOptions()}
          </select>
        </div>
        <span
          className={`validation-message create-profile__time-range__error ${
            timeValid === false ? "" : "hide"
          }`}
        >
          Please ensure closing times occur later than opening times.
        </span>
      </div>
    );
  }
}

WeekdayHours.propTypes = {
  onChangeTime: PropTypes.func.isRequired,
  onIsClosedChange: PropTypes.func.isRequired,
  openingHoursDay: PropTypes.object.isRequired,
};

WeekdayHours.defaultProps = {};

export default WeekdayHours;
