import React from "react";

/* eslint-disable import/named */
import RSelect from "react-select";
import makeAnimated from "react-select/animated";
// eslint-disable-next-line import/namespace
import { OptionsType, ValueType } from "react-select/src/types";

const animatedComponents = makeAnimated();

type OptionValue = string;
export interface OptionType {
  value: OptionValue;
  label: string;
}

interface Props {
  name: string;
  options: OptionsType<OptionType>;
  isMulti?: boolean;
  defaultValue?: OptionType;
  value?: OptionType;
  closeMenuOnSelect?: boolean;
  onChange?: (value: ValueType<OptionType> | OptionType[]) => void;
}

// TODO: Pass down props by extending RSelect props
const Select: React.FC<Props> = ({
  name,
  options,
  isMulti = false,
  value,
  defaultValue,
  onChange,
  closeMenuOnSelect = true,
}) => (
  <RSelect
    name={name}
    className="react-select"
    placeholder="Choose"
    closeMenuOnSelect={closeMenuOnSelect}
    // @ts-ignore
    components={animatedComponents}
    value={value}
    defaultValue={defaultValue}
    isMulti={isMulti}
    options={options}
    onChange={(value: ValueType<OptionType> | OptionType[]) =>
      onChange && onChange(value)
    }
    styles={{
      container: (base) => ({
        ...base,
        marginBottom: "1.5rem",
      }),
      control: (base) => ({
        ...base,
        borderColor: "#3e4144",
      }),
      placeholder: (base) => ({
        ...base,
        color: "#3e4144",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: "none",
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: "#3e4144",
      }),
      multiValue: (base) => ({
        ...base,
        backgroundColor: "hsl(14, 100%, 57%)",
        borderRadius: "2rem",
      }),
      multiValueLabel: (base) => ({
        ...base,
        color: "#fff",
      }),
      multiValueRemove: (base) => ({
        ...base,
        color: "#fff",
      }),
      valueContainer: (base) => ({
        ...base,
        overflow: "visible",
      }),
    }}
  />
);

export default Select;
