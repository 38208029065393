import React from "react";
import { connect } from "react-redux";

import GenericError from "../components/errors/GenericError";

import RedeemEaterReward from "../components/qr_code/RedeemEaterReward";

const QRCodeContainer = (props) => {
  if (props.error) return <GenericError />;

  if (props.loading) return <div className="loading-placeholder" />;

  return <RedeemEaterReward />;
};

const mapStateToProps = (state) => ({
  loading: state.profile.loading,
  error: state.profile.error,
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps)(QRCodeContainer);
