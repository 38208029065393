import React from "react";

import { CheckboxToggle } from "components/forms/CheckboxToggle";
import { useDish } from "api/menu/useDish";
import { useUpdateDish } from "api/menu/useUpdateDish";
import { useClickOutside } from "components/useClickOutside";

export function MenuItemActionsDropdown(props: MenuItemActionsDropdownProps) {
  const ref = useClickOutside(props.onClose);
  const dish = useDish(props.id);
  const updateDish = useUpdateDish();

  const handleChange = (field) => (value) => {
    updateDish(props.id, { [field]: value });
  };

  if (dish.data == null) return null;

  return (
    <ul
      ref={ref}
      className="absolute top-7 right-0 p-4 space-y-4 whitespace-nowrap bg-white border border-gray-200 rounded-lg"
    >
      <li>
        <CheckboxToggle
          name={`special-${props.id}`}
          value={dish.data.special}
          onChange={handleChange("special")}
        >
          Special dish
        </CheckboxToggle>
      </li>
      <li>
        <CheckboxToggle
          name={`active-${props.id}`}
          value={dish.data.active}
          onChange={handleChange("active")}
        >
          Active dish
        </CheckboxToggle>
      </li>
    </ul>
  );
}

type MenuItemActionsDropdownProps = {
  id: number;
  onClose: () => void;
};
