import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteError } from "../../actions";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import errorIcon from "../../assets/icons/exclamation-white.svg";

const GlobalErrors = (props) => {
  const { errors, deleteError } = props;

  return (
    <div className="errors">
      <TransitionGroup>
        {errors.map((error) => (
          <CSSTransition key={error.id} timeout={300} classNames={"height"}>
            <div className="errors__error">
              <span className="errors__error__left">
                <img
                  src={errorIcon}
                  alt="error"
                  className="errors__error__icon"
                />
                <span>
                  <b>Something went wrong:</b>{" "}
                  {error.text ? error.text : " Unable to retrieve data"}
                </span>
              </span>
              <button
                className="errors__error__close-btn"
                onClick={() => deleteError(error.id)}
              />
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.global.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteError }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalErrors);
