import classnames from "classnames";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { CustomerSupportIcon, DisketteIcon, WitchHatIcon } from "icons";

export const MenuNavigation = withRouter(function MenuNavigation(
  props: MenuNavigationProps
) {
  return (
    <ul
      className={classnames(
        props.className,
        "fixed z-50 left-sidebar right-0 px-8 py-4 h-20 flex items-center space-x-8 bg-gray-100"
      )}
    >
      {navigation.map((item) => (
        <li key={item.href}>
          <Link
            to={item.href}
            className={classnames(
              "-mb-2 pb-2 flex items-center space-x-2 text-lg font-normal text-gray-900 hover:text-gray-900 border-b-2",
              {
                "border-brand-blue font-bold":
                  props.location.pathname === item.href,
                "border-transparent": props.location.pathname !== item.href,
              }
            )}
          >
            {item.icon != null && <item.icon />}
            <span>{item.label}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
});

const navigation = [
  {
    label: "Wizard Builder",
    href: "/menu/builder",
    icon: WitchHatIcon,
  },
  {
    label: "Manual Builder",
    href: "/menu/manual",
    icon: CustomerSupportIcon,
  },
  {
    label: "Review & Save",
    href: "/menu",
    icon: DisketteIcon,
  },
  {
    label: "Analytics",
    href: "/menu/analytics",
  },
];

type MenuNavigationProps = {
  className?: string;
  location?: any;
};
