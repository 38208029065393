import React from "react";
import sideScrollIcon from "../../assets/icons/side-scroll.svg";

class SideScrollExample extends React.Component {
  constructor() {
    super();
    this.state = {
      show: true,
    };
  }
  componentDidMount() {
    setTimeout(() => this.setState({ show: false }), 3000);
  }
  render() {
    const { show } = this.state;

    return (
      <div className={`side-scroll-example${show ? " show" : ""}`}>
        <img src={sideScrollIcon} alt="Horizontal Scroll" />
      </div>
    );
  }
}

export default SideScrollExample;
