import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import GenericError from "../components/errors/GenericError";

import ShowQRCode from "../components/qr_code/ShowQRCode";

const QRCodeContainer = (props) => {
  if (props.error) return <GenericError />;

  if (props.loading) return <div className="loading-placeholder" />;

  return (
    <div>
      {ShowQRCode(props)}
      <div className="qr_code_container">
        <div className="qr_code_text_container">
          <p className="qr_code_title">
            Does an eater have a reward to redeem?
          </p>
          <Link className="button" to="/qrcode/redeem">
            Redeem eater's reward
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.profile.loading,
  error: state.profile.error,
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps)(QRCodeContainer);
