import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import { getNextTransactions } from "../../actions/transactions";

import ModalPortal from "../../containers/ModalPortal";
import ScrollToTop from "../layout/ScrollToTop";

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: this.groupTransactionsByDate(props.transactions.results),
      fetchingTransactions: false,
      showModal: false,
      currentDetail: {},
    };
    this.fetchMoreItems = this.fetchMoreItems.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showTransactionModal = this.showTransactionModal.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.fetchMoreItems);
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.fetchMoreItems);
  }
  fetchMoreItems() {
    if (
      !(
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 50
      )
    )
      return;
    if (!this.props.transactions.next) return;
    if (this.state.fetchingTransactions) return;
    this.setState({ fetchingTransactions: true });
    this.props
      .getNextTransactions(this.props.transactions.next)
      .then((response) => {
        if (!this._isMounted) return;
        const responseByDate = this.groupTransactionsByDate(
          response.data.results
        );
        this.setState({
          transactions: this.combineTransactions(
            responseByDate,
            this.state.transactions
          ),
          fetchingTransactions: false,
        });
      });
  }
  combineTransactions(newTrans, oldTrans) {
    const newTransactions = oldTrans;

    for (const dateGroup in newTrans) {
      if (oldTrans[dateGroup]) {
        newTransactions[dateGroup] = [
          ...oldTrans[dateGroup],
          ...newTrans[dateGroup],
        ];
      } else {
        newTransactions[dateGroup] = newTrans[dateGroup];
      }
    }

    return newTransactions;
  }
  groupTransactionsByDate(transactions) {
    const byDate = {};

    transactions.forEach((transaction) => {
      const date = moment(transaction.created_at).format("DD MMMM YYYY");
      if (!byDate[date]) byDate[date] = [];
      byDate[date] = [...byDate[date], transaction];
    });

    return byDate;
  }
  openModal() {
    this.setState({
      showModal: true,
    });
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }
  showTransactionModal(transaction) {
    const { description } = this;
    this.setState(
      {
        currentDetail: {
          date: moment(transaction.created_at).format("DD MMMM YYYY"),
          time: moment(transaction.created_at).format("HH:mm"),
          id: transaction.id,
          pointsDiff: transaction.coins,
          balance: transaction.balance,
          description: description(transaction.reason),
          type: transaction.type,
        },
      },
      () => this.openModal()
    );
  }
  description = (reason) => {
    switch (reason) {
      case 0:
        return "Eater Reward";
      case 1:
        return "Points Expenditure";
      case 2:
        return "Review";
      case 3:
        return "QR Scan";
      case 4:
        return "Points Expenditure";
      case 5:
        return "Points Adjustment by Hawkker";
      case 6:
        return "Earned your First Points with Hawkker";
      default:
        return "Transaction";
    }
  };
  render() {
    const { description } = this;

    const renderTransactions = (dateGroup) =>
      this.state.transactions[dateGroup].map((transaction) => (
        <tr
          key={transaction.id}
          className="transactions__table__transactions-row"
          onClick={() => this.showTransactionModal(transaction)}
        >
          <td className="transactions__table__transactions-row__desktop-only transactions__table__transactions-row__time">
            {moment(transaction.created_at).format("HH:mm")}
          </td>
          <td className="transactions__table__transactions-row__desktop-only">
            0{transaction.id}
          </td>
          <td>{description(transaction.reason)}</td>
          <td className="transactions__table__transactions-row__mobile-only">
            {transaction.type === 0 ? "-" : "+"}
            {transaction.coins}
          </td>
          <td className="transactions__table__transactions-row__desktop-only">
            {transaction.type === 1 ? `+${transaction.coins}` : ""}
          </td>
          <td className="transactions__table__transactions-row__desktop-only">
            {transaction.type === 0 ? `-${transaction.coins}` : ""}
          </td>
          <td>{transaction.balance}</td>
        </tr>
      ));

    const renderDateGroup = () =>
      Object.keys(this.state.transactions).map((date) => {
        return (
          <tbody key={date}>
            <tr className="transactions__table__header-row">
              <td className="transactions__table__header-row__date">{date}</td>
              <td className="transactions__table__header-row__title">Amount</td>
              <td className="transactions__table__header-row__title">
                Balance
              </td>
              <td
                className="transactions__table__header-row__date-desktop"
                colSpan="6"
              >
                {date}
              </td>
            </tr>
            {renderTransactions(date)}
          </tbody>
        );
      });
    if (!this.props.transactions.results.length)
      return (
        <div className="no-transactions">
          <h2 className="no-transactions__title text-center">
            You have 0 transactions
          </h2>
          <p className="text-center">
            This page is a transaction log of points. Any points you obtain from
            eater rewards and spending points will shown here.
          </p>
        </div>
      );
    return (
      <div className="transactions">
        <ScrollToTop />
        <ModalPortal
          title="Transaction"
          showModal={this.state.showModal}
          closeModal={this.closeModal}
          halfscreen={true}
          mobileOnly={true}
        >
          <div className="modal__detail">
            <div className="modal__detail__container">
              <span className="modal__detail__container__title">Date</span>
              {this.state.currentDetail.date}
            </div>
            <div className="modal__detail__container">
              <span className="modal__detail__container__title">Time</span>
              {this.state.currentDetail.time}
            </div>
            <div className="modal__detail__container expanded">
              <span className="modal__detail__container__title">
                Transaction No.
              </span>
              0{this.state.currentDetail.id}
            </div>
            <div className="modal__detail__container">
              <span className="modal__detail__container__title">
                Points {this.state.currentDetail.type === 0 ? "Out" : "In"}
              </span>
              {this.state.currentDetail.type === 0 ? "-" : "+"}
              {this.state.currentDetail.pointsDiff}
            </div>
            <div className="modal__detail__container">
              <span className="modal__detail__container__title">Balance</span>
              {this.state.currentDetail.balance}
            </div>
            <div className="modal__detail__container expanded">
              <span className="modal__detail__container__title">
                Description
              </span>
              {this.state.currentDetail.description}
            </div>
          </div>
        </ModalPortal>
        <h4 className="transactions__title">All Transactions</h4>
        <table className="transactions__table">
          <tbody>
            <tr className="transactions__table__main-header-row">
              <td></td>
              <td>Transaction No.</td>
              <td>Description</td>
              <td>Points In</td>
              <td>Points Out</td>
              <td>Balance</td>
            </tr>
          </tbody>
          {renderDateGroup()}
        </table>
        {this.state.fetchingTransactions ? (
          <div className="transactions__loading-transactions" />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getNextTransactions }, dispatch);

export default connect(null, mapDispatchToProps)(Transactions);
