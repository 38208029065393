import React from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

import { useMenuLoader } from "components/menu/useMenuLoader";
import { useUploadMenu } from "components/menu/useUploadMenu";

export const MenuUploadButton = withRouter(function (
  props: MenuUploadButtonProps
) {
  const menuLoader = useMenuLoader();
  const uploadMenu = useUploadMenu({
    onSuccess: () => {
      // @ts-ignore: location injected by `withRouter`
      if (props.location.pathname != "/menu/builder") {
        // @ts-ignore: history injected by `withRouter`
        props.history.push("/menu/builder");
      }
    },
  });
  const uploadInput = React.useRef(null);
  const handleOpenFileDialog = () => uploadInput.current?.click();
  const handleUpload = async (e) => {
    menuLoader.setLoading(true, "Uploading your menu…");
    await uploadMenu.upload({ file: e.target.files[0] });
    props.onUpload();
  };

  return (
    <div className={classnames(props.className)}>
      {React.cloneElement(props.children, { onClick: handleOpenFileDialog })}
      <input
        ref={uploadInput}
        className="hidden"
        type="file"
        onChange={handleUpload}
      />
    </div>
  );
});

type MenuUploadButtonProps = {
  className?: string;
  children: React.ReactElement;
  onUpload?: () => void;
};
