import React from "react";
import { Field, Form, useField } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { connect } from "react-redux";

import { submitStep } from "../../actions";
import { getLocationOfferings } from "../../actions/locations";
import { withGoogleMaps } from "../withGoogleMaps";
import RadioItemField from "../forms/RadioItemField";
import InputCharCount from "../forms/InputCharCount";
import { ServiceProvidersField } from "../schedule/ServiceProvidersField";
import { PostcodeAreaField } from "../schedule/PostcodeAreaField";
import { MarketAreasField } from "../schedule/MarketAreasField";
import { MarketsField } from "../schedule/MarketsField";
import VendorLocationNoBrowserGPS from "../schedule/VendorLocationNoBrowserGPS";
import { defaultFromHour, defaultToHour } from "../schedule/LocationForm";
import WeekdayHours from "../schedule/WeekdayHours";
import { ProgressBar } from "../ProgressBar";

function CreateProfileStepSchedule({
  currentStep,
  stepCount,
  submitStep,
  changeStep,
  initialData,
  locationOfferings,
  getLocationOfferings,
  isSubmitting,
  error,
}) {
  const [scheduleStep, setScheduleStep] = React.useState(1);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    getLocationOfferings();
  }, []);

  React.useEffect(() => {
    const noErrors =
      error == null ||
      (typeof error === "object" && !Object.keys(error).length);

    if (!isSubmitting && submitted && noErrors) {
      changeStep(5);
    }
  }, [isSubmitting, submitted, error]);

  const nextStep = () => {
    setScheduleStep(scheduleStep + 1);
  };
  const prevStep = () => setScheduleStep(Math.max(scheduleStep - 1, 0));

  const onSubmit = (values) => {
    let errors = {};
    if (scheduleStep === 1) {
      errors = validateLocationType(values);
    } else if (scheduleStep === 3) {
      errors = validateOpeningHours(values);
    } else if (scheduleStep === 4) {
      errors = validateProviders(values);
    }

    if (Object.keys(errors).length) {
      return errors;
    }

    if (scheduleStep === 1 && values.location_type === "private") {
      // Skip fine-tunning for private locations
      setScheduleStep(3);
      return;
    } else if (scheduleStep === 1 && values.location_type === "webshop") {
      // Skip fine-tunning and opening hours for webshop locations
      setScheduleStep(4);
      return;
    } else if (scheduleStep < 4) {
      nextStep();
      return;
    }

    const { postcode_area, ...data } = values;
    setSubmitted(true);

    delete data.business;
    delete data.created_at;
    delete data.updated_at;
    delete data.distance;
    delete data.is_fixed;
    delete data.offering;
    delete data.average_rating;
    delete data.total_ratings;

    if (data.location_type !== "fixed") {
      delete data.postcode;
      delete data.street;
    }

    if (data.location_type === "market") {
      data.market_id = data.market?.id;
    }
    delete data.market;

    if (data.location_type !== "private" && data.location_type !== "webshop") {
      delete data.postcode_area_id;
    }

    submitStep({
      vendor_location: {
        ...data,
        postcode_area_id: postcode_area?.id,
      },
      onboarding_stage: 8,
    });
  };

  if (!locationOfferings.length) return null;

  return (
    <Form
      onSubmit={onSubmit}
      initialValuesEqual={() => true}
      initialValues={{
        opening_hours: openingHours,
        delivery_providers: [],
        offering_ids:
          initialData.offering
            ?.map(
              (offeringName) =>
                locationOfferings.find(
                  (offering) => offering.name === offeringName
                )?.id
            )
            .filter(Boolean) ?? [],
        ...initialData,
      }}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, values, submitErrors }) => {
        return (
          <>
            <div className="create-profile__breadcrumbs">
              <a
                onClick={() => {
                  if (scheduleStep <= 1) {
                    changeStep(currentStep - 1);
                  } else {
                    if (
                      (values.location_type === "private" ||
                        values.location_type === "webshop") &&
                      scheduleStep === 3
                    ) {
                      setScheduleStep(1);
                    } else {
                      prevStep();
                    }
                  }
                }}
                className="step-back"
              >
                Step back
              </a>

              <ProgressBar
                value={(
                  ((currentStep + scheduleStep - 1) / stepCount) *
                  100
                ).toFixed(0)}
              />
            </div>

            <form onSubmit={handleSubmit}>
              {scheduleStep === 1 && <LocationTypeForm values={values} />}
              {scheduleStep === 2 && (
                <FineTuneMap values={values} nextStep={nextStep} />
              )}
              {scheduleStep === 3 && (
                <OpeningHoursForm values={values} nextStep={nextStep} />
              )}
              {scheduleStep === 4 && (
                <ServiceProvidersForm
                  values={values}
                  error={{ ...error, ...submitErrors }}
                  locationOfferings={locationOfferings}
                />
              )}
            </form>
          </>
        );
      }}
    />
  );
}

function LocationTypeForm({ values }) {
  const coordinatesField = useField("coordinates");

  React.useEffect(() => {
    if (coordinatesField.input.value) return;

    let coordinates;
    if (
      values.location_type === "private" ||
      values.location_type === "webshop"
    ) {
      coordinates = values.postcode_area?.coordinates;
    } else if (values.location_type === "market") {
      coordinates = values.market?.coordinates;
    }

    if (coordinates != null) {
      coordinatesField.input.onChange(coordinates);
    }
  }, [values.postcode_area, values.market]);

  return (
    <fieldset>
      <h2>What type of location do you operate from?</h2>

      <div className="onboarding__location-type-image">
        <img src={require("../../assets/icons/location.svg")} alt="" />
      </div>

      <dl className="onboarding__location-type-help">
        <div>
          <dt>Market Stall/Pitch:</dt>
          <dd>
            You operate from a non-permanent location at a market, festival or
            event.
          </dd>
        </div>

        <div>
          <dt>Fixed Location:</dt>
          <dd>
            You operate from a permanent location, e.g. a shop, fixed stall in a
            food hall or a concession.
          </dd>
        </div>

        <div>
          <dt>Delivery Only:</dt>
          <dd>
            You sell food for home delivery via same-day food delivery services,
            customers cannot visit this location. Your exact location will not
            be shown to customers.
          </dd>
        </div>

        <div>
          <dt>Webshop Only:</dt>
          <dd>
            Online e-commerce store only, customers cannot visit this location.
            You sell items for delivery via postal services. Your exact location
            will not be shown to customers.
          </dd>
        </div>
      </dl>

      <p>
        <em>
          If your business has multiple locations, you can add these later in
          your Vendor Admin Schedule Area.
        </em>
      </p>

      <h2>Choose one:</h2>

      <div className="onboarding__location-type-choices">
        <RadioItemField
          id="market_stall"
          item="Market Stall/Pitch"
          name="location_type"
          value="market"
        />
        <RadioItemField
          id="fixed_location"
          item="Fixed Location"
          name="location_type"
          value="fixed"
        />
        <RadioItemField
          id="private_location"
          item="Delivery Only Location"
          name="location_type"
          value="private"
        />
        <RadioItemField
          id="webshop_location"
          item="Webshop only"
          name="location_type"
          value="webshop"
        />
      </div>

      {values.location_type === "market" && (
        <div>
          <label htmlFor="area">
            Area<span className="input-required">*</span>
          </label>

          <Field
            id="area"
            name="area"
            render={({ input }) => <MarketAreasField {...input} />}
          />

          <label htmlFor="market">
            Market Name<span className="input-required">*</span>
          </label>

          <Field
            id="market"
            name="market"
            render={({ input }) => (
              <MarketsField
                {...input}
                area={values.area}
                disabled={!values.area}
              />
            )}
          />
          <FieldError name="market" />
        </div>
      )}

      {values.location_type === "fixed" && (
        <div>
          <Field
            type="text"
            name="postcode"
            render={({ input }) => (
              <InputCharCount
                {...input}
                label="Postcode"
                maxLength="10"
                validate="name"
                required
                showRequiredMarker
              />
            )}
          />
          <FieldError name="postcode" />
          <Field
            type="text"
            name="street"
            render={({ input }) => (
              <InputCharCount
                {...input}
                label="Street Name"
                maxLength="150"
                validate="name"
                required
                showRequiredMarker
              />
            )}
          />
          <FieldError name="street" />
        </div>
      )}

      {(values.location_type === "private" ||
        values.location_type === "webshop") && (
        <div>
          <label htmlFor="postcode_area">
            Your Approximate Location<span className="input-required">*</span>
          </label>

          <Field
            id="postcode_area"
            name="postcode_area"
            render={({ input }) => <PostcodeAreaField {...input} />}
          />
          <FieldError name="postcode_area" />
        </div>
      )}

      <button type="submit" className="button expanded">
        Next
      </button>

      <a
        className="onboarding__location-type-help-link"
        href="https://hawkker.com/help-docs/i-need-help-choosing-a-location-type/"
        target="_blank"
        rel="noopener noreferrer"
      >
        I need help choosing a location type
      </a>

      <a
        className="onboarding__location-type-help-link"
        href="https://hawkker.com/help-docs/what-to-do-if-my-market-isnt-listed/"
        target="_blank"
        rel="noopener noreferrer"
      >
        My market name is not listed
      </a>
    </fieldset>
  );
}

const FineTuneMap = withGoogleMaps(function FineTuneMap({ values }) {
  const [showMap, setShowMap] = React.useState(false);
  const coordinatesField = useField("coordinates");

  React.useEffect(() => {
    if (
      !window.google?.maps?.Geocoder ||
      coordinatesField.input.value ||
      values.location_type !== "fixed"
    ) {
      setShowMap(true);
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { address: [values.address, values.postcode].join(" ") },
      (results) => {
        const result = results[0];

        if (result) {
          coordinatesField.input.onChange({
            lng: result.geometry.location.lng(),
            lat: result.geometry.location.lat(),
          });
        }

        setShowMap(true);
      }
    );
  }, []);

  return (
    <fieldset>
      <h2>Fine tune</h2>
      <h4>
        Fine Tune Location (helps eaters find exactly where you are)
        <span className="input-required">*</span>
      </h4>

      <Field
        name="coordinates"
        render={({ input }) =>
          showMap && (
            <VendorLocationNoBrowserGPS
              onLocationUpdate={(location) => {
                input.onChange(location);
              }}
              onLocationSet={() => {}}
              ignoreBrowserLocation
              defaultLat={input.value?.lat || ""}
              defaultLng={input.value?.lng || ""}
            />
          )
        }
      />

      <div className="onboarding__coordinates">
        <Field
          name="coordinates.lat"
          placeholder="Latitude"
          disabled
          component="input"
        />
        <Field
          name="coordinates.lng"
          placeholder="Longitude"
          disabled
          component="input"
        />
      </div>

      <button type="submit" className="button expanded">
        Next
      </button>
    </fieldset>
  );
});

function OpeningHoursForm() {
  return (
    <fieldset>
      <div className="onboarding__location-type-image">
        <img src={require("../../assets/icons/calendar.svg")} alt="" />
      </div>
      <h2>Opening hours</h2>
      {openingHours.map((openingHoursDay) => (
        <React.Fragment key={openingHoursDay.weekday}>
          <Field
            name={`opening_hours.${openingHoursDay.weekday}`}
            render={({ input }) => (
              <WeekdayHours
                openingHoursDay={input.value}
                onIsClosedChange={() => {
                  input.onChange({
                    ...input.value,
                    open: !input.value.open,
                  });
                }}
                onChangeTime={(weekDay, key, value) => {
                  input.onChange({
                    ...input.value,
                    [key]: value,
                  });
                }}
              />
            )}
          />
          <FieldError
            name={`opening_hours.${openingHoursDay.weekday}.to_hour`}
          />
        </React.Fragment>
      ))}

      <button type="submit" className="button expanded">
        Next
      </button>
    </fieldset>
  );
}

function ServiceProvidersForm({ values, error, locationOfferings }) {
  return (
    <fieldset>
      <div className="onboarding__location-type-image onboarding__location-type-image--purchase-options">
        <img src={require("../../assets/icons/purchase-options.svg")} alt="" />
      </div>
      <h2>Customer Purchase Options</h2>

      <ServiceProvidersField
        isPrivate={values.location_type === "private"}
        isWebshop={values.location_type === "webshop"}
        name="delivery_providers"
        errors={error.delivery_providers}
        locationOfferings={locationOfferings}
        offeringIdsName="offering_ids"
      />

      {typeof error.delivery_providers === "string" && (
        <p className="validation-message">{error.delivery_providers}</p>
      )}

      {error.non_field_errors &&
        error.non_field_errors.map((msg, i) => (
          <p key={i} className="validation-message">
            {msg}
          </p>
        ))}

      <button type="submit" className="button expanded">
        Next
      </button>

      <a
        className="onboarding__location-type-help-link"
        href="https://hawkker.com/help-docs/i-need-help-choosing-my-customer-purchase-options/"
        target="_blank"
        rel="noopener noreferrer"
      >
        I need help choosing my purchase options
      </a>
    </fieldset>
  );
}

function validateLocationType(values) {
  const errors = {};

  if (!values.location_type) {
    errors.location_type = "Please select your location type";
  }

  if (values.location_type === "fixed") {
    if (!values.street) {
      errors.street = "Please enter your street";
    }

    if (!values.postcode) {
      errors.postcode = "Please enter your postcode";
    }
  } else if (values.location_type === "market") {
    if (!values.market) {
      errors.market = "Please select one market";
    }
  } else if (
    values.location_type === "private" ||
    values.location_type === "webshop"
  ) {
    if (!values.postcode_area) {
      errors.postcode_area = "Please select your postcode area";
    }
  }

  return errors;
}

function validateOpeningHours(values) {
  const errors = {};

  values.opening_hours.forEach((openingHour, index) => {
    if (openingHour.open && openingHour.from_hour >= openingHour.to_hour) {
      if (!errors.opening_hours) {
        errors.opening_hours = [];
      }

      errors.opening_hours[index] = {
        to_hour: "Opening hour must be set before closing hour",
      };
    }
  });

  return errors;
}

function validateProviders(values) {
  const errors = {};

  if (values.location_type === "fixed") {
    //   if (!values.street) {
    //     errors.street = "Please enter your street";
    //   }
    //
    //   if (!values.postcode) {
    //     errors.postcode = "Please enter your postcode";
    //   }
    // } else if (values.location_type === "market") {
    //   if (!values.market) {
    //     errors.market = "Please select one market";
    //   }
  } else if (values.location_type === "private") {
    if (!values.delivery_providers.length) {
      errors.delivery_providers =
        "Please select at least one delivery provider";
    }
  }

  return errors;
}

export default connect(
  (state) => ({
    isSubmitting: state.onboarding.stepSchedule.isSubmitting,
    error: state.onboarding.stepSchedule.error?.vendor_location || {},
    locationOfferings: state.locations.locationOfferings.list,
    initialData: state.onboarding.vendor_location ?? {},
  }),
  { getLocationOfferings, submitStep }
)(CreateProfileStepSchedule);

const openingHours = [0, 1, 2, 3, 4, 5, 6].map((weekday) => ({
  weekday,
  from_hour: defaultFromHour,
  to_hour: defaultToHour,
  open: false,
  timeValid: true,
}));

function FieldError({ name }) {
  const { meta } = useField(name);

  if (!meta.error && !meta.submitError) {
    return null;
  }

  return <p className="validation-message">{meta.submitError || meta.error}</p>;
}
