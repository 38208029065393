import React from "react";
import classnames from "classnames";
import { withRouter } from "react-router-dom";

export const MenuSectionNavigation = withRouter(function MenuSectionNavigation({
  className = "",
  sections,
  location,
}) {
  const activeSection =
    location.hash.split("-")[1] ?? sections?.[0]?.id?.toString();

  return (
    <ul className={classnames("flex items-center space-x-6", className)}>
      {sections.map((section) => (
        <li key={section.id}>
          <a
            href={`#section-${section.id}`}
            data-text={section.name}
            className={classnames(
              "menusection-link font-normal hover:text-gray-900 hover:font-bold",
              {
                "menusection-link--active":
                  activeSection === section.id.toString(),
              }
            )}
          >
            {section.name}
          </a>
        </li>
      ))}
    </ul>
  );
});
