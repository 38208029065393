import React from "react";
import { range } from "ramda";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveFunnel } from "@nivo/funnel";
import { ResponsiveRadar } from "@nivo/radar";

import { MenuNavigation } from "components/menu/MenuNavigation";
import { useMenuAnalytics } from "api/menu/useMenuAnalytics";
import { dietaryOrder } from "api/menu/useDietary";
import { formatPrice, round } from "core/l10n";
import { dietaryIcons } from "icons/dietary";
import capitalise from "helpers/capitalise";

export function MenuAnalytics() {
  const analytics = useMenuAnalytics();

  const data = analytics.data ?? {
    dietary: [],
    counts: {},
    prices: {},
    average_price_per_cuisine: [],
    swarm_prices: [],
    swarm_averages: [],
  };

  const lengthDataLabel = (id) => {
    if (id === "my") {
      return "My menu";
    }
    if (id === "all") {
      return "Avg. from all";
    }

    return `Avg. for ${capitalise(id)}`;
  };

  const lengthData = Object.keys(data.counts).map((id) => ({
    id: lengthDataLabel(id),
    value: data.counts[id],
  }));

  const swarmData = [
    ...data.swarm_prices.map((node) => ({
      id: `my-${node.price_category}`,
      group: "my",
      price: node.price_category,
      volume: node.count,
    })),
    ...data.swarm_averages.map((node) => ({
      id: `other-${node.price_category}`,
      group: "other",
      price: node.price_category,
      volume: node.count,
    })),
  ];

  const maxSwarmCountMy = swarmData.length
    ? Math.max(
        ...swarmData
          .filter((node) => node.group === "my")
          .map((node) => node.volume)
      )
    : 1;
  const maxSwarmCountOther = swarmData.length
    ? Math.max(
        ...swarmData
          .filter((node) => node.group === "other")
          .map((node) => node.volume)
      )
    : 1;

  const myFunnel = {};
  data.swarm_prices.forEach((node) => {
    myFunnel[node.price_category] = {
      id: `my-${node.price_category}`,
      label: node.price_category.toString(),
      value: node.count,
    };
  });

  const otherFunnel = {};
  data.swarm_averages.forEach((node) => {
    otherFunnel[node.price_category] = {
      id: `other-${node.price_category}`,
      label: node.price_category.toString(),
      value: node.count,
    };
  });

  return (
    <div>
      <MenuNavigation />

      <main className="container mx-auto pt-24 space-y-4">
        <div className="w-full max-w-xl mx-auto p-4 bg-white rounded-lg shadow">
          <header className="text-lg font-bold">Menu item counts</header>

          <p className="my-2 text-gray-600">
            Compare the length of your menu with average menu lengths
          </p>

          <div className="h-60">
            <ResponsiveBar
              data={lengthData}
              layout="horizontal"
              margin={{ left: 120, bottom: 50, right: 20 }}
              padding={0.3}
              enableLabel={false}
              colors={{ scheme: "paired" }}
              tooltip={(d) => (
                <div className="px-2 py-1 text-sm bg-white rounded shadow">
                  {d.indexValue}: <strong>{round(d.value, 1)}</strong>
                </div>
              )}
            />
          </div>
        </div>

        <div className="w-full max-w-xl mx-auto p-4 bg-white rounded-lg shadow">
          <header className="text-lg font-bold">Price swarm chart</header>

          <p className="my-2 text-gray-600">
            Visualy compare prices used in your menu with average menu prices.
          </p>

          <div className="flex h-96">
            <ResponsiveFunnel
              data={range(0, 10).map((level) => {
                const lvl = 30 - level * 3;
                return (
                  myFunnel[lvl] ?? {
                    id: `my-${lvl}`,
                    label: lvl.toString(),
                    value: 0,
                  }
                );
              })}
              margin={{ top: 50, left: 50, right: 50, bottom: 50 }}
              enableLabel
              labelColor={{ from: "color", modifiers: [["darker", 3]] }}
              beforeSeparatorLength={100}
              beforeSeparatorOffset={20}
              afterSeparatorLength={100}
              afterSeparatorOffset={20}
              currentBorderWidth={18}
              shapeBlending={0}
              interpolation="linear"
              valueFormat={(value) => (value <= 0 ? "" : value.toString())}
            />

            <ResponsiveFunnel
              data={range(0, 10).map((level) => {
                const lvl = 30 - level * 3;
                return (
                  otherFunnel[lvl] ?? {
                    id: `other-${lvl}`,
                    label: lvl.toString(),
                    value: 0,
                  }
                );
              })}
              margin={{ top: 50, left: 50, right: 50, bottom: 50 }}
              enableLabel
              labelColor={{ from: "color", modifiers: [["darker", 3]] }}
              beforeSeparatorLength={100}
              beforeSeparatorOffset={20}
              afterSeparatorLength={100}
              afterSeparatorOffset={20}
              currentBorderWidth={18}
              shapeBlending={0}
              interpolation="linear"
              valueFormat=" >-.4s"
            />
            {/*<ResponsiveSwarmPlot*/}
            {/*  data={swarmData}*/}
            {/*  size={(node) => {*/}
            {/*    let scale;*/}
            {/*    if (node.group === "my") {*/}
            {/*      scale = maxSwarmCountMy;*/}
            {/*    } else {*/}
            {/*      scale = maxSwarmCountOther;*/}
            {/*    }*/}

            {/*    return Math.max(*/}
            {/*      (Math.log(2 * node.volume) / Math.log(scale)) * 20,*/}
            {/*      5*/}
            {/*    );*/}
            {/*  }}*/}
            {/*  margin={{ top: 50, left: 50, right: 50, bottom: 50 }}*/}
            {/*  colors={{ scheme: "paired" }}*/}
            {/*  groups={["my", "other"]}*/}
            {/*  value="price"*/}
            {/*  valueFormat="$.2f"*/}
            {/*  axisLeft={{*/}
            {/*    tickValues: [0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30],*/}
            {/*    format: (value) =>*/}
            {/*      value >= 30 ? "£30+" : `£${value}-${value + 3}`,*/}
            {/*  }}*/}
            {/*  axisRight={null}*/}
            {/*/>*/}
          </div>
        </div>

        <div className="p-4 max-w-4xl mx-auto bg-white rounded-lg shadow">
          <header className="text-lg font-bold">Price swarm chart</header>

          <p className="my-2 text-gray-600">
            Compare counts of dietary information used in your menu with average
            menus.
          </p>

          <AverageDietary values={data.dietary} />
        </div>

        <div className="space-x-4 flex justify-center pb-4">
          <div className="p-4 flex-1 max-w-xl bg-white rounded-lg shadow  h-64">
            <ResponsiveBar
              data={data.average_price_per_cuisine}
              keys={["avg_price"]}
              indexBy="cuisine"
              margin={{ left: 30, bottom: 100 }}
              padding={0.3}
              colors={{ scheme: "paired" }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              minValue={0}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legend: "Average price per cuisine",
                legendPosition: "middle",
                legendOffset: -180,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: 2,
                legend: "average price",
                legendPosition: "middle",
                legendOffset: -40,
                format: "~s",
              }}
              tooltip={(d) => (
                <>{`${d.indexValue}: £${formatPrice(d.value)}`}</>
              )}
              enableLabel={false}
              enableGridY={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              animate={true}
            />
          </div>
        </div>
      </main>
    </div>
  );
}

function AverageCounts({
  title,
  value,
  average,
  cuisines,
  formatValue = (id) => id,
}) {
  return (
    <div className="flex-1 p-4 max-w-sm bg-white rounded-lg shadow">
      <div className="mb-4 flex justify-between items-center">
        <span className="font-bold">{title}</span>
        <span className="text-4xl">{formatValue(value)}</span>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <span>All Cuisines</span>
        <span className="font-bold">{formatValue(average)}</span>
      </div>

      {Object.keys(cuisines).length > 0 && (
        <>
          <p className="mb-1 uppercase text-xs font-bold text-grey-500">
            Your Cuisines
          </p>
          <div className="space-y-2">
            {Object.keys(cuisines).map((key) => (
              <div key={key} className="flex justify-between items-center">
                <span>{key}</span>
                <span className="font-bold">{formatValue(cuisines[key])}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

function AverageDietary({ values }) {
  const dietaryByKey = {};
  values.map(({ key, ...dietary }) => {
    dietaryByKey[key] = dietary;
  });

  const data = dietaryOrder
    .map((key) => dietaryByKey[key])
    .filter(Boolean)
    .map((d) => ({
      ...d,
      "Your menu": d.my_ratio,
      "All menus": d.all_ratio,
    }));

  return (
    <div className="flex items-center">
      <div className="h-96 flex-1">
        <ResponsiveRadar
          data={data}
          indexBy="name"
          keys={["Your menu", "All menus"]}
          margin={{ left: 80, bottom: 50, right: 80, top: 30 }}
          colors={{ scheme: "paired" }}
          valueFormat=">-.1%"
        />
      </div>

      <div className="ml-8 flex-1">
        <div className="mb-2 flex items-center font-bold space-x-2">
          <span className="w-4/12">Dietary</span>
          <span className="w-4/12 text-center">Your menu</span>
        </div>
        {dietaryOrder.map((key) => {
          const dietary = dietaryByKey[key];
          if (dietary == null) return null;

          return (
            <div key={key} className="mb-1 flex items-center space-x-2">
              <span className="w-4/12 flex items-center">
                <div className="mr-2">
                  {React.createElement(dietaryIcons[key])}
                </div>
                {dietary.name}
              </span>
              <span className="w-4/12 text-center">{dietary.count}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
