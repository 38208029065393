import React from "react";
import { Link } from "react-router-dom";

const EmptyLocations = (props) => (
  <div className="grid-container">
    <div className="grid-x align-center">
      <div className="cell small-11 medium-6 large-5 align-center text-center menu-empty">
        <img
          src={require("../../assets/images/empty-state-schedule.svg")}
          className="empty-state-schedule"
        />
        <h1 className="text-center menu-empty__title">Add Your Locations</h1>
        <p className="menu-empty__blurb">
          Add your location(s) here and let customers know where you are, when
          you’re open and how they can purchase your food.
        </p>
        <Link className="button" to={`${props.location}/add-location`}>
          Add location
        </Link>
      </div>
    </div>
  </div>
);

export default EmptyLocations;
