import * as React from "react";
import { connect } from "react-redux";
import { getLocations } from "../../actions";

function PureMarketsField({
  name,
  value,
  onChange,
  area,
  markets,
  getLocations,
  ...props
}) {
  React.useEffect(() => {
    if (markets == null) {
      getLocations();
    }
  }, []);

  const marketsInArea: string[] = React.useMemo(() => {
    if (markets == null || !Array.isArray(markets)) return [];

    return markets
      .filter((m) => m.area === area && m.market_name)
      .map((m) => m.market_name.trim())
      .sort();
  }, [markets, area]);

  return (
    <select
      {...props}
      name={name}
      value={value.market_name?.trim()}
      onChange={(e) => {
        onChange(markets.find((m) => m.market_name.trim() === e.target.value));
      }}
    >
      <option value={null}>Please select market</option>
      {marketsInArea.map((marketName) => (
        <option key={marketName} value={marketName}>
          {marketName}
        </option>
      ))}
    </select>
  );
}

export const MarketsField = connect(
  (state) => ({
    // @ts-ignore
    markets: state.markets.locations?.list,
  }),
  { getLocations }
)(PureMarketsField);
