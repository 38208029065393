import React from "react";

const StarRating = (props) => {
  const { rating } = props;

  const width = rating * 10 * 2 + "%";

  return (
    <span className="star-rating">
      <span
        style={{ width: `calc(${width} - .3rem)` }}
        className="stars-full"
      />
      <span className="stars-empty" />
    </span>
  );
};

export default StarRating;
