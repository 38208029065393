import React from "react";

class InputCharCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLength: this.props.value.length,
    };
    this.charCount = this.charCount.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  charCount(e) {
    this.setState({ currentLength: e.target.value.length });
  }
  handleChange(e) {
    this.charCount(e);

    // Backward compatibility with old fields API
    if (this.props.onChange == null && this.props.changeHandler) {
      this.props.changeHandler(e);
    } else {
      this.props.onChange(e);
    }
  }
  input() {
    if (this.props.type === "textarea") {
      return (
        <textarea
          name={this.props.name}
          maxLength={this.props.maxLength}
          type={this.props.type}
          placeholder={this.props.placeholder}
          rows={this.props.rows}
          value={this.props.value}
          onChange={this.handleChange}
          required={this.props.required}
          data-validate={this.props.validate}
        />
      );
    } else {
      return (
        <input
          name={this.props.name}
          maxLength={this.props.maxLength}
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.handleChange}
          required={this.props.required}
          data-validate={this.props.validate}
        />
      );
    }
  }
  render() {
    const { showRequiredMarker } = this.props;

    return (
      <div>
        <label>
          {this.props.label}
          <span className="char-count">
            {this.state.currentLength}/{this.props.maxLength}
          </span>
          {showRequiredMarker && <span className="input-required">*</span>}
          {this.input()}
        </label>
        <span
          className="hide validation-message"
          data-validate-message={this.props.validate}
        >
          {this.props.errorText
            ? this.props.errorText
            : `Error: Please provide a ${this.props.label.toLowerCase()}`}
        </span>
      </div>
    );
  }
}

InputCharCount.defaultProps = {
  showRequiredMarker: false,
};

export default InputCharCount;
