import React from "react";

class TruncatedText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textExpanded: false,
    };
    const { text, maxLength } = props;
    this.textPreview = text.slice(0, maxLength);
  }
  expandText = () => {
    this.setState({
      textExpanded: true,
    });
  };
  collapseText = () => {
    this.setState({
      textExpanded: false,
    });
  };
  render() {
    const { text, maxLength } = this.props;
    const { textPreview, expandText, collapseText } = this;
    const { textExpanded } = this.state;

    if (text.length < maxLength) return <span>{text}</span>;

    return (
      <span>
        {textExpanded ? `${text} ` : `${textPreview}...`}
        {textExpanded ? (
          <button className="truncated-text__expand-btn" onClick={collapseText}>
            Hide
          </button>
        ) : (
          <button className="truncated-text__expand-btn" onClick={expandText}>
            Show more
          </button>
        )}
      </span>
    );
  }
}

export default TruncatedText;
