import React from "react";
import { Redirect } from "react-router-dom";

import emailIcon from "assets/images/email.svg";
import MainError from "components/forms/MainError";

const ForgottenPasswordSuccess = (props) => {
  const { email, resendError, resendSuccess, resend } = props;

  if (!email) {
    return <Redirect exact to={"/forgotten-password"} />;
  }

  return (
    <div>
      {resendError ? <MainError errorText={resendError} /> : ""}
      <div className="forgotten-password__inner__email-graphic__wrapper">
        <img
          src={emailIcon}
          alt="email"
          className="forgotten-password__inner__email-graphic"
        />
      </div>
      <h3 className="forgotten-password__inner__heading text-center">
        We've sent you an email.
      </h3>
      <p className="text-center forgotten-password__inner__blurb">
        Open your emails and you’ll be able to reset your password from there.
      </p>
      <p>Email sent to:</p>
      <span className="forgotten-password__inner__email-address">{email}</span>
      <span className="forgotten-password__inner__resend-link" onClick={resend}>
        Not got the email? <b>Resend</b>
      </span>
      <span
        className={`forgotten-password__inner__resent-text ${
          resendSuccess ? "show" : ""
        }`}
      >
        Email resent
      </span>
    </div>
  );
};

export default ForgottenPasswordSuccess;
