import React from "react";
import classnames from "classnames";

export function Button({
  icon,
  color = "blue",
  variant = "rounded",
  component: Component = "button",
  children,
  ...props
}: ButtonProps) {
  return (
    <Component
      {...props}
      className={classnames(
        "px-6 py-2 text-sm font-bold focus:outline-none focus:ring cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed",
        props.className,
        {
          "text-white bg-brand-blue ring-brand-blue": color === "blue",
          "text-gray-900 bg-gray-200 ring-gray-800": color === "gray",
          "text-white bg-brand-red ring-brand-red": color === "red",
          "text-gray-900 hover:bg-gray-200 ring-gray-300":
            color === "transparent",
          "rounded-full": variant == "rounded",
          "rounded-md": variant == "clean",
          "inline-flex items-center": icon != null,
        }
      )}
    >
      {icon != null && React.cloneElement(icon, { className: "mr-2" })}
      {children}
    </Component>
  );
}

export type ButtonProps = {
  icon?: React.ReactElement;
  color?: "blue" | "red" | "gray" | "transparent";
  variant?: "rounded" | "clean";
  children?: React.ReactNode;
  className?: string;
  component: React.ComponentType<any> | string;
} & any;
