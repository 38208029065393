import React from "react";

const LeaveModal = (props) => {
  const { closeModal, logout } = props;

  return (
    <React.Fragment>
      <h2>Are you sure you want to leave?</h2>
      {props.children}
      <div className="logout-button-container">
        <button className="logout-button-back" onClick={closeModal}>
          No, stay
        </button>
        <button onClick={logout} className="button">
          Leave
        </button>
      </div>
    </React.Fragment>
  );
};

export default LeaveModal;
