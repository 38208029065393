import React, { Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { submitStep } from "../../actions";

import EditImagesForm from "../profile/forms/EditImagesForm";
import { ProgressBar } from "../ProgressBar";

class CreateProfileStepPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate(prevState) {
    const { changeStep, isSubmitting, error } = this.props;
    if (prevState.isSubmitting && !isSubmitting) {
      if (error) {
        this.setState({ submitDisabled: false });
        window.scrollTo(0, 0);
      } else {
        changeStep(4);
        window.scrollTo(0, 0);
      }
    }
  }
  handleSubmit(data) {
    const { changeStep } = this.props;
    if (data.noNewImages) {
      changeStep(4);
      return;
    }
    this.setState({ submitDisabled: true });
    const payLoad = {
      photos: data.photos,
      delete_photos: data.delete,
      onboarding_stage: 4,
    };
    const { submitStep } = this.props;
    return submitStep(payLoad).catch((error) => {
      this.setState({ error });
      return Promise.reject();
    });
  }
  render() {
    const { submitDisabled } = this.state;
    const { handleSubmit } = this;
    const {
      changeStep,
      error,
      onboarding,
      currentStep,
      stepCount,
    } = this.props;

    return (
      <React.Fragment>
        <div className="create-profile__breadcrumbs">
          <a onClick={() => changeStep(currentStep - 1)} className="step-back">
            Step back
          </a>

          <ProgressBar value={((currentStep / stepCount) * 100).toFixed(0)} />
        </div>

        <h2>Photos</h2>
        <EditImagesForm
          buttonText="Continue"
          error={error}
          profile={{
            photos: onboarding.photos,
          }}
          submitDisabled={submitDisabled}
          submitHandler={handleSubmit}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  onboarding: state.onboarding.business,
  isSubmitting: state.onboarding.stepPhotos.isSubmitting,
  error: state.onboarding.stepPhotos.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ submitStep }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProfileStepPhotos);
