import React from "react";

class DownloadTransactions extends React.Component {
  exportTable = () => {
    const { downloadCSV } = this;
    const { table } = this.props;
    const csv = [];
    const rows = table().querySelectorAll("tr");

    for (const row of rows) {
      let line = [],
        cols = row.querySelectorAll("td");
      for (const col of cols) {
        line.push(col.innerText.replace(/,/g, ""));
      }
      csv.push(line.join(","));
    }

    downloadCSV(csv.join("\r\n"));
  };
  downloadCSV = (csv) => {
    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = "export.csv";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };
  render() {
    const { exportTable } = this;
    return (
      <button className="download-transactions-btn" onClick={exportTable}>
        Export current view as CSV
      </button>
    );
  }
}

export default DownloadTransactions;
