import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { submitStep } from "../../actions";

import EditBusinessInfoForm from "../profile/forms/EditBusinessInfoForm";
import { ProgressBar } from "../ProgressBar";

class CreateProfileStepBusinessInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate(prevState) {
    const { changeStep, isSubmitting, error } = this.props;
    if (prevState.isSubmitting && !isSubmitting) {
      if (error) {
        this.setState({ submitDisabled: false });
        window.scrollTo(0, 0);
      } else {
        changeStep(2);
        window.scrollTo(0, 0);
      }
    }
  }
  handleSubmit(data) {
    this.setState({ submitDisabled: true });
    const payLoad = {
      name: data.name,
      tagline: data.tagline,
      biography: data.biography,
      cuisine: data.cuisines.selected,
      social_links: data.social_links,
      referred_by_code: data.referred_by_code,
      onboarding_stage: 1,
    };
    const { submitStep } = this.props;
    submitStep(payLoad);
  }
  render() {
    const { submitDisabled } = this.state;
    const {
      cuisines,
      onboarding,
      error,
      referred_by_code,
      currentStep,
      stepCount,
    } = this.props;
    const { handleSubmit } = this;

    const referralCode = window.localStorage.getItem("hawkker-referral") || "";

    return (
      <React.Fragment>
        <div className="create-profile__breadcrumbs create-profile__breadcrumbs--no-back">
          <ProgressBar value={((currentStep / stepCount) * 100).toFixed(0)} />
        </div>

        <h2>Business Info</h2>
        <p>Please provide us with the following information.</p>
        <EditBusinessInfoForm
          buttonText="Continue"
          cuisines={cuisines}
          error={error}
          profile={{
            name: onboarding.name ? onboarding.name || "" : "",
            tagline: onboarding.tagline ? onboarding.tagline || "" : "",
            biography: onboarding.biography ? onboarding.biography || "" : "",
            referred_by_code: referred_by_code
              ? referred_by_code || referralCode
              : referralCode,
            cuisine: onboarding.cuisine ? onboarding.cuisine || "" : "",
            social_links: {
              facebook: onboarding.social_links
                ? onboarding.social_links.facebook || ""
                : "",
              twitter: onboarding.social_links
                ? onboarding.social_links.twitter || ""
                : "",
              instagram: onboarding.social_links
                ? onboarding.social_links.instagram || ""
                : "",
            },
          }}
          submitDisabled={submitDisabled}
          submitHandler={handleSubmit}
          include_referred_by_code_field={true}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  onboarding: state.onboarding.business,
  cuisines: state.dishes.cuisines.list,
  isSubmitting: state.onboarding.stepOne.isSubmitting,
  error: state.onboarding.stepOne.error,
  referred_by_code: state.onboarding.referred_by_code,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ submitStep }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProfileStepBusinessInfo);
