import React from "react";
import classnames from "classnames";

export function CheckboxToggle(props: CheckboxToggleProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.checked, e);
  };

  return (
    <label
      htmlFor={props.name}
      className={classnames(
        props.className,
        "flex justify-between items-center text-gray-600 cursor-pointer"
      )}
    >
      {props.children}
      <div className={classnames("ml-4 relative h-5 w-8 flex items-center")}>
        <div
          className={classnames("w-full h-3 rounded-full bg-opacity-20", {
            "bg-brand-blue": props.value,
            "bg-gray-500": !props.value,
          })}
        />
        <div
          className={classnames(
            "absolute top-0 h-5 w-5 rounded-full bg-white transition-all duration-200 ease-out lg:h-3 lg:w-3",
            {
              "checkboxtoggle--enabled bg-brand-blue": props.value,
              "checkboxtoggle--disabled bg-gray-500": !props.value,
            }
          )}
        />
      </div>

      <input
        className="hidden"
        id={props.name}
        type="checkbox"
        name={props.name}
        checked={props.value}
        onChange={handleChange}
      />
    </label>
  );
}

type CheckboxToggleProps = {
  name: string;
  value: boolean;
  onChange: (value: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  children: React.ReactNode;
};
