import React from "react";
import { Link } from "react-router-dom";

const OnboardingHeader = (props) => {
  const { title, openModal, token } = props;

  return (
    <header className="onboarding__top-bar top-bar">
      <div className="onboarding__top-bar-inner">
        <span className="onboarding__top-bar-inner__left">
          {token ? (
            <button onClick={openModal} className="onboarding__logo" />
          ) : (
            <Link to="/">
              <div className="onboarding__logo" />
            </Link>
          )}

          {title}
        </span>
      </div>
    </header>
  );
};

export default OnboardingHeader;
