import React from "react";
import classnames from "classnames";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { DeleteIcon, DragIcon } from "icons";
import { IconButton } from "components/generic/IconButton";
import { ConfirmationDialog } from "components/ConfirmationDialog";
import { useMenuItemForm } from "components/menu/useMenuItemForm";
import { MenuItemForm } from "components/menu/MenuItemForm";
import { useForm } from "react-final-form";

export function MenuBuilderItems({ className, items, onDelete }) {
  const formApi = useForm();
  const [editFormId, setEditFormId] = React.useState();

  const handleSelect = (item) => {
    if (item == null) {
      formApi.reset({});
      setEditFormId(null);
    } else {
      const { section, ...data } = item;
      formApi.reset(data);
      setEditFormId(data.id);
    }
  };

  if (items == null) return null;

  return (
    <Droppable droppableId="droppable">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={classnames(className, "space-y-2")}
        >
          {items.map((item, index) =>
            editFormId === item.id ? (
              <MenuItemForm onClose={() => handleSelect(null)} />
            ) : (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    className="p-3 border border-gray-200 bg-white rounded-lg cursor-pointer space-y-3 hover:text-gray-900"
                    ref={provided.innerRef}
                    onClick={() => handleSelect(item)}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="flex justify-between items-center space-x-2">
                      <IconButton variant="terniary">
                        <DragIcon />
                      </IconButton>
                      <p className="font-bold">{item.name}</p>
                      <div className="flex-1" />
                      <ConfirmationDialog
                        text={`Do you really want to delete dish ${item.name}?`}
                        onConfirm={() => onDelete(item.id)}
                      >
                        <IconButton variant="terniary">
                          <DeleteIcon />
                        </IconButton>
                      </ConfirmationDialog>
                    </div>

                    <p className="text-sm text-gray-600">{item.description}</p>
                    <p className="text-sm">£{item.price}</p>

                    {!!item.dietary_information.length && (
                      <p className="text-sm text-gray-600">
                        {item.dietary_information
                          .map((dietary) => dietary.name)
                          .join(", ")}
                      </p>
                    )}
                  </div>
                )}
              </Draggable>
            )
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
