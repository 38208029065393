import * as profile from "../services/profile";

export const getGeneralSettings = () => (dispatch) => {
  return profile
    .getGeneralSettings()
    .then((response) => {
      dispatch({
        type: "SET_GENERAL_SETTINGS",
        data: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_GENERAL_SETTINGS_FAIL",
      });
    });
};
