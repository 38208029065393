import React from "react";
import { MenuItem } from "components/menu/MenuItem";

export function MenuItemsWithSections({
  className = "",
  items,
  sections,
  readOnly = false,
}) {
  return (
    <div className={className}>
      {sections.map((section) => {
        const sectionDishes = items.results.filter(
          (dish) => dish.section?.id === section.id
        );
        return (
          <div key={section.id}>
            <header id={`section-${section.id}`} className="text-lg font-bold">
              {section.name}
            </header>
            <div className="mt-4 mb-8 grid grid-cols-2 gap-2 grid-flow-row">
              {sectionDishes.map((dish) => (
                <MenuItem key={dish.id} dish={dish} readOnly={readOnly} />
              ))}
              {sectionDishes.length === 0 && (
                <p className="text-gray-700">
                  There're no dishes in this section
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
