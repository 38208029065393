import React from "react";
import { Form } from "react-final-form";

import { MenuBuilderImage } from "components/menu/MenuBuilderImage";
import { useDishesOperations } from "components/menu/useDishesOperations";
import { SectionLabel } from "components/generic/SectionLabel";
import { MenuBuilderSectionForm } from "components/menu/MenuBuilderSectionForm";
import { useMenuSectionsOps } from "components/menu/useMenuSectionsOps";
import { MenuBuilderSections } from "components/menu/MenuBuilderSections";
import { MenuNavigation } from "components/menu/MenuNavigation";

export function MenuBuilder() {
  const menuSectionsOps = useMenuSectionsOps();
  const dishOps = useDishesOperations({
    activeSection: menuSectionsOps.activeSection,
  });

  const handleSubmit = async (values, formApi) => {
    if (values.id != null) {
      const { id, ...data } = values;
      await dishOps.handleUpdate(id, data);
    } else if (values.section != null) {
      await menuSectionsOps.handleCreate(values.section);
    } else {
      await dishOps.handleCreate({
        ...values.dish,
        section: menuSectionsOps.activeSection,
      });
    }

    formApi.reset({});
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <MenuNavigation />

          <div className="relative flex flex-row-reverse min-h-screen-topbar pb-20 bg-white ">
            <div className="fixed top-topbar bottom-bottombar left-sidebar w-1/2-sidebar p-4 pt-24">
              <MenuBuilderImage />
            </div>

            <div className="w-1/2 p-4 pt-24 space-y-4">
              <SectionLabel>Section</SectionLabel>
              <p>Select a section name from the left</p>

              <MenuBuilderSectionForm />

              <MenuBuilderSections
                sections={menuSectionsOps.sections.data}
                activeSection={menuSectionsOps.activeSection}
                onSectionSelect={menuSectionsOps.handleSelect}
                onSectionDestroy={menuSectionsOps.handleDestroy}
                items={dishOps.dishes.data?.results ?? []}
                onItemDestroy={dishOps.handleDelete}
                onItemReorder={dishOps.handleReorder}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
}
