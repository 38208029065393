import * as api from "./api";
import {
  DASHBOARD,
  MOST_PURCHASED,
  CUSTOMER_TYPE,
  HIGHEST_RATED,
  LATEST_TRANSACTIONS,
} from "../api_urls";

export const getDashboardSummary = () =>
  api
    .get(DASHBOARD)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getMostPurchased = () =>
  api
    .get(MOST_PURCHASED)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getCustomerType = () =>
  api
    .get(CUSTOMER_TYPE)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getHighestRated = () =>
  api
    .get(HIGHEST_RATED)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getLatestTransactions = () =>
  api
    .get(LATEST_TRANSACTIONS)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
