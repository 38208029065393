import React from "react";

import SelectList from "../../forms/SelectList";
import MainError from "../../forms/MainError";

class EditAllergensForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.allergens.list,
      selected: this.props.profile
        ? this.props.profile.allergens.map((allergen) => allergen.id.toString())
        : [],
      valid: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    if (e.target.checked) {
      this.setState({
        selected: [...this.state.selected, e.target.dataset.itemId],
      });
    } else {
      this.setState({
        selected: this.state.selected.filter(
          (item) => item !== e.target.dataset.itemId
        ),
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    // Validate
    // Nothing to validate
    const data = this.state;
    this.props.submitHandler(data);
  }
  render() {
    return (
      <div>
        {this.props.error ? <MainError errorText={this.props.error} /> : ""}
        <p>
          Please indicate if your food contains any of the following allergens.
          This will help eaters with allergies or intolerances choose the right
          food for them.
        </p>
        <p>
          For more information on allergens, please visit{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.food.gov.uk/business-guidance/allergen-guidance-for-industry"
          >
            The Food Standards Agency
          </a>{" "}
          website. You should display an allergens poster at all times when
          trading. You and your staff should be trained on providing allergy
          information and food-handling procedures.
        </p>
        <form onSubmit={this.handleSubmit} noValidate>
          <SelectList
            listName="allergens"
            type="checkbox"
            items={this.state.options}
            valid={this.state.valid}
            selected={this.state.selected}
            changeHandler={this.handleChange}
          />
          <button
            type="submit"
            ref="submit"
            className="button expanded"
            disabled={this.props.submitDisabled}
          >
            {this.props.buttonText || "Save"}
          </button>
        </form>
      </div>
    );
  }
}

export default EditAllergensForm;
