const initialState = {
  allergens: {
    list: [],
    isLoading: true,
  },
  cuisines: {
    list: [],
    isLoading: true,
  },
  dietaryPreferences: {
    list: [],
    isLoading: true,
  },
  menu: {
    active: {
      list: [],
      count: 0,
      next: null,
    },
    inactive: {
      list: [],
      count: 0,
      next: null,
    },
    loaded: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALLERGENS":
      return {
        ...state,
        allergens: {
          ...state.allergens,
          list: action.allergens,
          isLoading: false,
        },
      };
    case "SET_CUISINES":
      return {
        ...state,
        cuisines: {
          ...state.cuisines,
          list: action.cuisines,
          isLoading: false,
        },
      };
    case "SET_DIETARY_PREFERENCES":
      return {
        ...state,
        dietaryPreferences: {
          ...state.dietaryPreferences,
          list: action.dietaryPreferences,
          isLoading: false,
        },
      };
    case "SET_ACTIVE_DISHES":
      return {
        ...state,
        menu: {
          ...state.menu,
          active: {
            ...state.menu.active,
            list: action.dishes,
            count: action.count,
            next: action.next,
          },
          loaded: true,
        },
      };
    case "SET_INACTIVE_DISHES":
      return {
        ...state,
        menu: {
          ...state.menu,
          inactive: {
            ...state.menu.inactive,
            list: action.dishes,
            count: action.count,
            next: action.next,
          },
        },
      };
    case "SET_NEXT_ACTIVE_DISHES":
      return {
        ...state,
        menu: {
          ...state.menu,
          active: {
            ...state.menu.active,
            list: [...state.menu.active.list, ...action.dishes],
            next: action.next,
          },
        },
      };
    case "SET_NEXT_INACTIVE_DISHES":
      return {
        ...state,
        menu: {
          ...state.menu,
          inactive: {
            ...state.menu.inactive,
            list: [...state.menu.inactive.list, ...action.dishes],
            next: action.next,
          },
        },
      };
    default:
      return state;
  }
};
