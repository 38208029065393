import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import ModalPortal from "./ModalPortal";
import PastWeek from "../components/dashboard/PastWeek";
import DayDetail from "../components/dashboard/DayDetail";

class PastWeekContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      latest: null,
      prev: null,
    };
    this.showDetail = this.showDetail.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }
  showDetail(latest, prev) {
    this.setState({
      latest,
      prev,
      showModal: true,
    });
  }
  render() {
    const { showModal, latest, prev } = this.state;
    const { showDetail, closeModal } = this;
    const { latestTransactions } = this.props;

    return (
      <React.Fragment>
        <ModalPortal
          halfscreen={true}
          showModal={showModal}
          title={latest ? moment(latest.date).format("DD/MM/YYYY") : ""}
          mobileOnly={true}
          closeModal={closeModal}
        >
          <DayDetail latest={latest} prev={prev} />
        </ModalPortal>
        <PastWeek
          showDetail={showDetail}
          latestTransactions={latestTransactions}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  latestTransactions: state.dashboard.latestTransactions.results,
});

export default connect(mapStateToProps)(PastWeekContainer);
