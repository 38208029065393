import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { uploadImage, deleteImages } from "../../../services/aws";

import { nanoid } from "nanoid";
import ImageCompressor from "image-compressor.js";

import heroCamera from "../../../assets/images/hero-camera.svg";
import otherCamera from "../../../assets/images/other-camera.svg";

import MainError from "../../forms/MainError";

class EditImagesForm extends React.Component {
  constructor() {
    super();
    this.state = {
      awsError: null,
      valid: true,
      images: {
        hero: {},
        other: {},
      },
      delete: [],
      deleteFromAWS: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if (Object.keys(this.props.profile.photos).length) {
      const currentImages = {
        hero: {},
        other: {},
      };
      this.props.profile.photos.forEach((image) => {
        const uniqueId = nanoid().id;
        image.hero
          ? (currentImages.hero = {
              awsUrl: image.image,
              hero: true,
              id: uniqueId,
              apiId: image.id,
              url: image.image,
            })
          : (currentImages.other[uniqueId] = {
              awsUrl: image.image,
              hero: false,
              id: uniqueId,
              apiId: image.id,
              url: image.image,
            });
      });
      this.setState({
        images: {
          ...currentImages,
        },
      });
    }
  }
  handleImageChange(e, isHero) {
    const uniqueID = nanoid().id;
    // Compress, Resize and Check Orientation
    new ImageCompressor(e.target.files[0], {
      checkOrientation: true,
      quality: 0.2,
      maxHeight: 1242,
      maxWidth: 1242,
      success(result) {
        onLoaded(result);
      },
    });

    // Take resulting blob and create/store url
    const onLoaded = (result) => {
      const reader = new FileReader();
      reader.readAsDataURL(result);
      reader.onloadend = () => {
        if (isHero) {
          this.setState({
            images: {
              ...this.state.images,
              hero: {
                url: reader.result,
                file: result,
                hero: isHero,
                id: uniqueID,
                awsUrl: null,
              },
            },
          });
        } else {
          this.setState({
            images: {
              ...this.state.images,
              other: {
                ...this.state.images.other,
                [uniqueID]: {
                  url: reader.result,
                  file: result,
                  hero: isHero,
                  id: uniqueID,
                  awsUrl: null,
                },
              },
            },
          });
        }
      };
    };
    e.target.value = "";
  }
  deleteImage(image) {
    if (image.hero) {
      this.setState({
        images: {
          ...this.state.images,
          hero: {},
        },
      });
    } else {
      const { [image.id]: omit, ...state } = this.state.images.other;
      this.setState({
        images: {
          ...this.state.images,
          other: state,
        },
      });
    }
    if (image.apiId) {
      this.setState({
        delete: [...this.state.delete, image.apiId],
        deleteFromAWS: [...this.state.deleteFromAWS, image.awsUrl],
      });
    }
  }
  imagesValid() {
    if (
      Object.keys(this.state.images.hero).length > 0 &&
      Object.keys(this.state.images.other).length > 0
    ) {
      return true;
    }
    return false;
  }
  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit.disabled = true;
    // Validate
    if (!this.imagesValid()) {
      this.setState({ valid: false });
      this.refs.submit.disabled = false;
      window.scrollTo(0, 0);
      return;
    } else {
      window.scrollTo(0, 0);
      this.setState({
        valid: true,
        loading: true,
      });
    }

    // Upload images to AWS (if not already)
    const data = {
      photos: [],
      delete: this.state.delete,
    };

    let hero = [];
    if (!this.state.images.hero.awsUrl) {
      hero = uploadImage(this.state.images.hero, "uploaded").then((url) => {
        data.photos.push({ image: url, hero: true });
      });
    }

    const others = [];

    for (const key of Object.keys(this.state.images.other)) {
      if (!this.state.images.other[key].awsUrl) {
        others.push(
          uploadImage(this.state.images.other[key], "uploaded").then((url) => {
            data.photos.push({ image: url, hero: false });
          })
        );
      }
    }

    if (!hero.length && !others.length && !this.state.delete.length) {
      this.props.submitHandler({ noNewImages: true });
      return;
    }

    let deleteImagesFromAWS;

    if (this.state.delete.length) {
      deleteImagesFromAWS = deleteImages(this.state.deleteFromAWS);
    }

    Promise.all([hero, ...others, deleteImagesFromAWS])
      .then(() => {
        this.props.submitHandler(data).catch(() => {
          this.setState({
            loading: false,
          });
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          awsError: "There was a problem uploading one or more of your images",
        });
        this.refs.submit.disabled = false;
        window.scrollTo(0, 0);
      });
  }
  render() {
    const renderUpload = (isHero) => (
      <div className="edit-images__upload-image">
        <div className="edit-images__upload-image__icon-wrapper">
          <img src={isHero ? heroCamera : otherCamera} alt="camera" />
        </div>
        <input
          type="file"
          onChange={(e) => this.handleImageChange(e, isHero)}
          accept="image/png, image/jpeg"
        />
      </div>
    );

    const renderPreview = (image) => (
      <CSSTransition
        enter={image.awsUrl ? false : true}
        key={image.id}
        timeout={300}
        classNames="zoom"
        unmountOnExit
      >
        <div className="cell small-6">
          <div
            className="edit-image__image-preview"
            style={{ backgroundImage: `url(${image.url})` }}
          >
            <div
              className="edit-images__image-preview__delete"
              onClick={() => this.deleteImage(image)}
            />
          </div>
        </div>
      </CSSTransition>
    );

    if (this.state.loading)
      return <div className="loading-placeholder edit-images__loading" />;

    return (
      <div>
        {this.props.error ? <MainError errorText={this.props.error} /> : ""}
        {!this.state.valid ? (
          <MainError
            errorText={
              "Error: Please ensure you have added a hero image and at least one 'other' image."
            }
          />
        ) : (
          ""
        )}
        {this.state.awsError ? (
          <MainError errorText={this.state.awsError} />
        ) : (
          ""
        )}
        <form onSubmit={this.handleSubmit} noValidate>
          <p>
            Here's a chance for you to upload some great eye-catching images of
            your dishes, your stall and your people in action. Square images in
            a 1:1 ratio work best.
          </p>
          <p>Only use original images of your dishes and not stock photos.</p>
          <p>
            You can crop or edit your photos on a web-based image editor (e.g.{" "}
            <a
              href="https://www.picmonkey.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              picmonkey
            </a>
            ) or get in touch with{" "}
            <a href="mailto:support@hawkker.com">support@hawkker.com</a> if you
            need some help with images.
          </p>
          <fieldset>
            <legend>Hero Image (single image)</legend>
            <p>
              This is the image that eaters will see for you when browsing
              through vendors in the vendor discover screen.
            </p>
            <TransitionGroup className="grid-x grid-margin-x">
              {Object.keys(this.state.images.hero).length
                ? renderPreview(this.state.images.hero)
                : ""}
            </TransitionGroup>
            {!Object.keys(this.state.images.hero).length
              ? renderUpload(true)
              : ""}
          </fieldset>

          <fieldset>
            <legend>Other Images (up to 9 More Images)</legend>
            <p>
              These are the images that eaters can scroll through when they
              click into your vendor profile page.
            </p>
            <TransitionGroup className="grid-x grid-margin-x grid-margin-y">
              {Object.keys(this.state.images.other).length > 0
                ? Object.keys(this.state.images.other).map((image) =>
                    renderPreview(this.state.images.other[image])
                  )
                : ""}
            </TransitionGroup>
            {Object.keys(this.state.images.other).length < 9
              ? renderUpload(false)
              : ""}
          </fieldset>

          <button
            type="submit"
            ref="submit"
            className="button expanded"
            disabled={this.props.submitDisabled}
          >
            {this.props.buttonText || "Save"}
          </button>
        </form>
      </div>
    );
  }
}

export default EditImagesForm;
