import * as api from "./api";
import { PURCHASES, DISH_NAMES } from "../api_urls";

export const getPurchases = () =>
  api
    .get(PURCHASES)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getNextPurchases = (url) =>
  api
    .get(null, url)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getDishNames = () =>
  api
    .get(DISH_NAMES)
    .then((response) => response)
    .catch((error) => Promise.reject(error));

export const getFilteredPurchases = (params) =>
  api
    .get(PURCHASES, null, params)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
