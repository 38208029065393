import React from "react";
import { Link } from "react-router-dom";
import notFound from "../assets/images/notfound.svg";

const NotFound = () => (
  <div className="grid-container">
    <div className="grid-x align-center justify-center not-found-wrapper">
      <div className="cell single-col-layout text-center not-found">
        <img className="not-found__image" src={notFound} alt="Page not found" />
        <h2 className="text-center not-found__title">
          Sorry, we can't find that page
        </h2>
        <Link className="not-found__link" to="/dashboard">
          Go to Dashboard
        </Link>
      </div>
    </div>
  </div>
);

export default NotFound;
