const initialState = {
  count: 0,
  results: [],
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_TRANSACTIONS":
      return {
        ...action.data,
        loading: false,
      };
    case "SET_NEXT_TRANSACTIONS":
      return {
        ...state,
        count: action.data.count,
        results: [...state.results, ...action.data.results],
        next: action.data.next,
        previous: action.data.previous,
      };
    default:
      return state;
  }
};
