import React from "react";
import { useField } from "react-final-form";
import classnames from "classnames";
import { CheckIcon } from "icons";

export function MenuBuilderSectionForm(props: MenuBuilderSectionFormProps) {
  const field = useField("section.name");

  return (
    <div
      className={classnames(
        props.className,
        "pl-4 py-1 pr-1 flex items-center bg-gray-100 rounded-full"
      )}
    >
      <input
        {...field.input}
        placeholder="Section name"
        className="mr-2 flex-1 border-0 h-auto focus:border-transparent focus:outline-none bg-gray-100"
      />
      <button
        type="submit"
        className={classnames(
          "h-10 w-10 flex items-center justify-center border rounded-full",
          {
            "border-brand-blue bg-brand-blue": field.input.value,
            "border-gray-400": !field.input.value,
          }
        )}
      >
        <CheckIcon
          className={classnames({
            "text-white": field.input.value,
            "text-gray-400": !field.input.value,
          })}
        />
      </button>
    </div>
  );
}

type MenuBuilderSectionFormProps = {
  className?: string;
};
