import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAllergens, editAllergens } from "../../actions";

import EditAllergensForm from "./forms/EditAllergensForm";

class EditAllergens extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      submitDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.props.allergens.isLoading) {
      this.props.getAllergens();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleSubmit(data) {
    this.setState({ submitDisabled: true });
    const payLoad = {
      allergens: data.selected,
    };
    this.props
      .editAllergens(payLoad)
      .then(() => {
        this.props.history.push("/profile");
      })
      .catch((error) => {
        if (!this._isMounted) return;
        this.setState({
          error: error.data.error,
        });
        this.setState({ submitDisabled: false });
        window.scrollTo(0, 0);
      });
  }
  render() {
    if (this.props.allergens.isLoading) {
      return <div className="loading-placeholder" />;
    }
    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Edit Allergens</h1>
            <EditAllergensForm
              profile={this.props.profile}
              allergens={this.props.allergens}
              submitDisabled={this.state.submitDisabled}
              submitHandler={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  allergens: state.dishes.allergens,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAllergens, editAllergens }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditAllergens);
