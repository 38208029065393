import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { DeleteIcon, DragIcon } from "icons";
import { IconButton } from "components/generic/IconButton";
import { ConfirmationDialog } from "components/ConfirmationDialog";
import { useMenuItemForm } from "components/menu/useMenuItemForm";
import { MenuItemForm } from "components/menu/MenuItemForm";

export function MenuItems({ items, onDelete }: any) {
  const menuItemForm = useMenuItemForm();

  if (items == null) return null;

  return (
    <Droppable droppableId="droppable">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="space-y-4"
        >
          {items.map((item, index) =>
            menuItemForm.editFormId === item.id ? (
              <MenuItemForm onClose={menuItemForm.close} />
            ) : (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    className="p-3 border border-gray-200 bg-white rounded-lg cursor-pointer space-y-3 hover:text-gray-900"
                    onClick={() => menuItemForm.open(item)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="flex justify-between items-center">
                      <p className="font-bold">{item.name}</p>
                      <div className="flex items-center space-x-2">
                        <ConfirmationDialog
                          text={`Do you really want to delete dish ${item.name}?`}
                          onConfirm={() => onDelete(item.id)}
                        >
                          <IconButton variant="terniary">
                            <DeleteIcon />
                          </IconButton>
                        </ConfirmationDialog>
                        <IconButton variant="terniary">
                          <DragIcon />
                        </IconButton>
                      </div>
                    </div>

                    <p className="text-sm text-gray-600">{item.description}</p>
                    <p className="text-sm">£{item.price}</p>

                    {!!item.dietary_information.length && (
                      <p className="text-sm text-gray-600">
                        {item.dietary_information
                          .map((dietary) => dietary.name)
                          .join(", ")}
                      </p>
                    )}
                  </div>
                )}
              </Draggable>
            )
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
