import React from "react";

const GenericError = (props) => (
  <div className={`page-load-error ${props.inline ? "inline" : ""}`}>
    <div className="page-load-error__message">
      <h2>{props.title ? props.title : "We couldn't load this page"}</h2>
      <p>Please try refreshing the page</p>
    </div>
  </div>
);

export default GenericError;
