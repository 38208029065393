import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SidebarMenu from "./SidebarMenu";
import closeSidebar from "../../assets/icons/sidebar-close.svg";
import { logout, getAuthUser } from "../../actions/auth";
import ModalPortal from "../../containers/ModalPortal";
import LogoutModal from "../generic/LogoutModal";

class Sidebar extends React.Component {
  pollStatus = null;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.showLogoutModal = this.showLogoutModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (this.props.status === 2) {
      this.props.getAuthUser();
      this.pollStatus = setInterval(() => this.props.getAuthUser(), 60 * 1000);
    }
  }

  componentWillUnmount() {
    if (this.pollStatus != null) clearInterval(this.pollStatus);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.status !== 2 && this.pollStatus) {
      clearInterval(this.pollStatus);
    }
  }

  showLogoutModal() {
    this.setState({ showModal: true });
  }
  closeModal() {
    this.setState({ showModal: false });
  }
  render() {
    const { showModal } = this.state;
    const { closeModal } = this;
    const { logout, status, checkedInStatus } = this.props;

    return (
      <aside className={`sidebar ${this.props.openState ? "open" : ""}`}>
        <ModalPortal showModal={showModal} closeModal={closeModal}>
          <LogoutModal closeModal={closeModal} logout={logout} />
        </ModalPortal>
        <div className="sidebar__container">
          <div className="sidebar__header">
            <div className="sidebar__logo" />
            <img
              className="sidebar__close-btn"
              src={closeSidebar}
              onClick={this.props.closeSidebar}
              alt="close"
            />
          </div>
          <h2 className="sidebar__name">{this.props.name}</h2>
          {status === 2 && (
            <span className="sidebar__status pending">Pending Approval</span>
          )}
          <SidebarMenu
            location={this.props.location}
            closeSidebar={this.props.closeSidebar}
            status={status}
            checkedInStatus={checkedInStatus}
          />
          <span className="sidebar__logout">
            <button onClick={this.showLogoutModal}>Log out</button>
          </span>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  name: state.profile.name,
  status: state.auth.user.status,
  checkedInStatus: state.profile.open,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout, getAuthUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
