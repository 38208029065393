import React from "react";

export function useSafeContext<C>(
  context: React.Context<C>,
  contextName = "context",
  providerName = "context provider"
): C {
  const viewport = React.useContext(context);

  if (process.env.NODE_ENV !== "production") {
    if (viewport == null) {
      // eslint-disable-next-line no-console
      console.error(
        `${contextName} is used without provider. ` +
          `Add ${providerName} above component which uses ${contextName}.`
      );
    }
  }

  // force non-null return value as uninitialized provider is reported above
  return viewport as C;
}
