import React from "react";
import CheckboxItem from "./CheckboxItem";
import RadioItem from "./RadioItem";

class SelectList extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      filteredItems: props.items,
      currentQuery: "",
    };
  }
  items = () => {
    const { filterable, items } = this.props;
    const { filteredItems } = this.state;
    if (!filterable) return items;
    return filteredItems;
  };
  filterItems = (e) => {
    const { items } = this.props;

    this.setState({
      currentQuery: e.target.value,
      filteredItems: items.filter((item) => {
        const searchTerms = e.target.value.toLowerCase().split(" ");
        let found = true;
        for (const term of searchTerms) {
          if (!item.name.toLowerCase().includes(term)) found = false;
        }
        return found;
      }),
    });
  };
  clearFilter = () => {
    const { items } = this.props;
    this.setState({
      filteredItems: items,
      currentQuery: "",
    });
  };
  handleChange(e) {
    const { changeHandler, listname } = this.props;
    changeHandler(e, listname);
  }
  render() {
    const { filteredItems, currentQuery } = this.state;
    const {
      filterable,
      valid,
      type,
      selected,
      fetchError,
      listname,
      errorMessage,
    } = this.props;
    const { items, clearFilter, filterItems } = this;

    const checkboxItems = () =>
      items().map((item) => {
        return (
          <CheckboxItem
            id={item.id}
            item={item.name}
            key={`${item.id}-${item.name}`}
            name={`${item.id}-${item.name}`}
            checked={selected.indexOf(item.id.toString()) > -1}
            changeHandler={this.handleChange}
          />
        );
      });

    const radioItems = () =>
      items().map((item) => (
        <RadioItem
          id={item.id}
          item={item.name}
          key={`${item.id}-${item.name}`}
          name={`${item.id}-${item.name}`}
          checked={selected.indexOf(item.id.toString()) > -1}
          changeHandler={this.handleChange}
        />
      ));

    const filterBox = () => (
      <div className="select-list-filter">
        <span className="select-list-filter__status">
          Showing {filteredItems.length} of {items().length} items
        </span>
        <input
          name="filter"
          type="text"
          placeholder="Enter text to filter..."
          onChange={filterItems}
          value={currentQuery}
        />
        <div
          className={`select-list-filter__reset-btn ${
            currentQuery.length ? "show" : ""
          }`}
          onClick={clearFilter}
        />
      </div>
    );

    return (
      <React.Fragment>
        {filterable ? filterBox() : ""}

        <ul
          className={`select-list ${fetchError ? "error" : ""} ${
            valid ? "" : "invalid"
          } ${filterable ? "filterable" : ""}`}
        >
          {type === "checkbox" ? checkboxItems() : radioItems()}
          {filterable && !filteredItems.length && (
            <span className="select-list__no-match-message">
              There are no {listname} that match this search term.
            </span>
          )}
        </ul>
        {!valid && (
          <span className="validation-message select">{errorMessage}</span>
        )}
      </React.Fragment>
    );
  }
}

export default SelectList;
