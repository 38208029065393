import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { submitStep } from "../../actions";

import ModalPortal from "../../containers/ModalPortal";
import { ProgressBar } from "../ProgressBar";

function CreateProfileStepAgreement({
  changeStep,
  submitStep,
  isSubmitting,
  error,
  currentStep,
  stepCount,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  React.useEffect(() => {
    // Only run after submit request finishes
    if (!isSubmitting && submitDisabled) {
      if (error) {
        this.setState({ submitDisabled: false });
        window.scrollTo(0, 0);
      } else {
        changeStep(5);
        window.scrollTo(0, 0);
      }
    }
  }, [isSubmitting, submitDisabled]);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const submit = () => {
    setSubmitDisabled(true);
    submitStep({
      onboarding_stage: 6,
    });
  };

  return (
    <React.Fragment>
      <ModalPortal
        title="Complete Hawkker Registration"
        fullscreen={true}
        showModal={showModal}
        closeModal={closeModal}
      >
        <p>
          Congratulations you have now completed registering your business for
          Hawkker. We will review your account very shortly, in the meantime you
          can login to your admin area and add your locations and menu items.
          Don't worry you can review everything that you've just entered in your
          profile area and make edits as needed.
        </p>
        <div className="grid-x grid-margin-x align-right">
          <button
            disabled={submitDisabled}
            onClick={() => submit()}
            className="button positive redemption-terms-btn"
          >
            Continue
          </button>
          <button
            disabled={submitDisabled}
            onClick={closeModal}
            className="button negative-outline redemption-terms-btn decline"
          >
            Back
          </button>
        </div>
      </ModalPortal>

      <div className="create-profile__breadcrumbs">
        <a onClick={() => changeStep(currentStep - 1)} className="step-back">
          Step back
        </a>

        <ProgressBar
          value={(((currentStep + 3) / stepCount) * 100).toFixed(0)}
        />
      </div>

      <h2>Hawkker Points</h2>
      <p>
        The Hawkker platform is totally free-of-charge for vendors, which means
        we don’t charge any subscription fees, sales commissions, set-up charges
        or hardware costs. We understand the challenges of running a small
        business mean you have to keep a close eye on the costs going out of the
        door. Our mission is to help businesses like yours succeed and grow – we
        don’t want to add to your overheads with the hefty fees that some of our
        competitors charge.
      </p>
      <p>
        What we do ask is that all vendors participate in the Hawkker points
        system. We use Hawkker points as our way of rewarding eaters for
        choosing independent vendors like you over high street alternatives.
        When eaters spend £5.00 or more with you, they are eligible to scan your
        vendor code and earn Hawkker points. In return, we ask eaters to rate
        their meal – these ratings are aggregated into an average rating, which
        appears on your profile in the Hawkker eater app. We also ask eaters to
        leave waiting time information and comments about their meal, both of
        which are only visible to you (i.e. this feedback does not appear in the
        eater app). All of these customer insights are crunched and presented
        free-of-charge in your Hawkker business analytics suite to support you
        in the development, growth and success of your business.
      </p>
      <p>
        After collecting enough Hawkker points eaters can convert them into a
        simple discount voucher for use at any vendor on the Hawkker platform.
        Each point they earn is worth £0.01. When an eater wants to redeem their
        Hawkker voucher with you, we ask that you reduce the requisite amount
        from their total bill. E.g. if an eater presents a £2.00 Hawkker
        discount voucher to you (equivalent to 200 Hawkker points), you would
        charge them £5.00 for a £7.00 meal.
      </p>
      <p>
        We hope you view the occasional discounts you give to eaters as a
        reasonable payment for the benefits of the Hawkker platform. Our
        research with vendors shows that a points-based discount system is the
        most effective route for vendors to “pay” for the benefits of the
        Hawkker platform. If Hawkker isn’t driving footfall to your business,
        you’ll honour less discounts to Hawkker eaters. On the flip side, if
        Hawkker is attracting more customers to your stall, it’s likely that
        you’ll be honouring more discount vouchers, but you’ll also enjoy
        increased sales, more than offsetting any discounts that you honour. Our
        logic here is that you only “pay” for Hawkker when it’s working for you
        – by bringing customers to your business and increasing your bottom
        line. Congratulations you have now completed registering your business
        for Hawkker. We will review your account very shortly, in the meantime
        you can login to your admin area and add your locations and menu items!
      </p>
      <p>
        If this all sounds good to you, please click accept to submit your
        business profile for review and approval. If your business meets the
        requirements of the platform we’ll get you up and running and driving
        more customers in no time.
      </p>
      <p>
        Since the Hawkker points system is what makes our platform tick, if you
        decline then unfortunately you won’t be able to register your business
        at this time.
      </p>
      <p>
        For more detail on Hawkker points please visit:{" "}
        <a
          href="https://hawkker.com/hawkker-points-terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://hawkker.com/hawkker-points-terms
        </a>
      </p>
      <p>
        If you’ve got any questions about the Hawkker points system, or the
        platform in general, we’d love to here from you, please drop us an email
        on: <a href="mailto:support@hawkker.com">support@hawkker.com</a>
      </p>
      <button type="submit" className="button expanded" onClick={openModal}>
        Continue
      </button>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  isSubmitting: state.onboarding.stepSix.isSubmitting,
  error: state.onboarding.stepSix.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ submitStep }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProfileStepAgreement);
