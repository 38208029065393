import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getProfile, profileLoading } from "../actions";

import { getIntercomUserHash } from "../selectors";

import ErrorBoundary from "./ErrorBoundary";
import GlobalErrors from "../components/errors/GlobalErrors";
import CrumbRoute from "./CrumbRoute";
import Header from "../components/layout/Header";
import Sidebar from "../components/sidebar/Sidebar";
import DashboardContainer from "./DashboardContainer";
import MenuContainer from "./MenuContainer";
import ProfileContainer from "./ProfileContainer";
import QRCodeContainer from "./QRCodeContainer";
import RedeemContainer from "./RedeemContainer";
import WalletContainer from "./WalletContainer";
import ReviewsContainer from "./ReviewsContainer";
import TransactionsContainer from "./TransactionsContainer";
import ScheduleContainer from "./ScheduleContainer";
import ChalkboardContainer from "containers/ChalkboardContianer";

import NotFound from "./NotFound";
import { getGeneralSettings } from "../actions/generalSettings";

function MainLayout(props) {
  const { history, location } = props;
  const [sidebarOpen, setSitebarOpen] = React.useState(false);

  React.useEffect(() => {
    const {
      getProfile,
      profileLoading,
      user,
      intercomUserHash,
      getGeneralSettings,
    } = props;

    getProfile().then(() => {
      profileLoading(false);
    });

    getGeneralSettings();

    window.Intercom("boot", {
      // TODO: use a var here
      app_id: "pakc0tx3",
      email: user.email,
      user_id: user.id,
      user_hash: intercomUserHash,
    });

    window.Upscope("updateConnection", {
      uniqueId: user.id,
      identities: [user.name, user.email],
    });
  }, []);

  const openSidebar = () => {
    setSitebarOpen(true);
  };

  const closeSidebar = () => {
    setSitebarOpen(false);
  };

  return (
    <div>
      <div
        className={`site-overlay ${sidebarOpen ? "site-overlay__show" : ""}`}
      />
      <GlobalErrors />
      <div className="main-layout">
        <Sidebar
          history={history}
          openState={sidebarOpen}
          closeSidebar={closeSidebar}
          location={location.pathname}
        />
        <div className="main-layout__right">
          <Header openSidebar={openSidebar} />
          <div className="main-layout__right-container">
            <ErrorBoundary>
              <Switch>
                <CrumbRoute
                  title="Dashboard"
                  path="/dashboard"
                  component={DashboardContainer}
                />
                <CrumbRoute
                  title="Transactions"
                  path="/transactions"
                  component={TransactionsContainer}
                />
                <CrumbRoute
                  title="Menu"
                  path="/menu"
                  component={MenuContainer}
                />
                <CrumbRoute
                  title="Chalkboard"
                  path="/chalkboard"
                  component={ChalkboardContainer}
                />
                <CrumbRoute
                  title="Profile"
                  path="/profile"
                  component={ProfileContainer}
                />
                <CrumbRoute
                  title="Eater QR Voucher"
                  path="/qrcode/redeem"
                  component={RedeemContainer}
                />
                <CrumbRoute
                  title="QR Code"
                  path="/qrcode"
                  component={QRCodeContainer}
                />
                <CrumbRoute
                  title="Wallet"
                  path="/wallet"
                  component={WalletContainer}
                />
                <CrumbRoute
                  title="Reviews"
                  path="/reviews"
                  component={ReviewsContainer}
                />
                <CrumbRoute
                  title="Schedule"
                  path="/schedule"
                  component={ScheduleContainer}
                />
                <Route component={NotFound} />
              </Switch>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  intercomUserHash: getIntercomUserHash(state),
});

const actions = {
  getProfile,
  profileLoading,
  getGeneralSettings,
};

export default connect(mapStateToProps, actions)(MainLayout);
