import React from "react";
import arrowUp from "../../assets/icons/arrow-up.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";

const TransactionsTableHeaderTitle = (props) => {
  const {
    title,
    active,
    descending,
    setActiveHandler,
    changeDirectionHandler,
  } = props;

  return (
    <td>
      <button
        className={`transactions-table-head__title${active ? " active" : ""}`}
        onClick={setActiveHandler}
      >
        {title}
      </button>
      <span className="transactions-table-head__direction-wrapper">
        {active && (
          <button
            className="transactions-table-head__order-direction"
            onClick={changeDirectionHandler}
          >
            {descending ? (
              <img src={arrowDown} alt="Descending" />
            ) : (
              <img src={arrowUp} alt="Ascending" />
            )}
          </button>
        )}
      </span>
    </td>
  );
};

export default TransactionsTableHeaderTitle;
