import React from "react";
import classnames from "classnames";

import { EllipsisIcon } from "icons";
import { IconButton } from "components/generic/IconButton";
import { useToggle } from "components/useToggle";
import { MenuItemActionsDropdown } from "components/menu/MenuItemActionsDropdown";

export function MenuItemActions(props: MenuItemActionsProps) {
  const dialog = useToggle();

  return (
    <div className={classnames(props.className, "relative")}>
      <IconButton variant="terniary" onClick={() => dialog.enable()}>
        <EllipsisIcon />
      </IconButton>

      {dialog.value && (
        <MenuItemActionsDropdown
          id={props.id}
          onClose={() => dialog.disable()}
        />
      )}
    </div>
  );
}

type MenuItemActionsProps = {
  id: number;
  className?: string;
};
