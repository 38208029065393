import React from "react";
import classnames from "classnames";

export function TailwindCssContainer(props: TailwindCssContainerProps) {
  return (
    <div className="tailwindcss">
      <div className={classnames("tailwindcss-reset", props.className)}>
        {props.children}
      </div>
    </div>
  );
}

type TailwindCssContainerProps = {
  className?: string;
  children: React.ReactNode;
};
