const initialState = {
  isLoading: true,
  isLoadingMore: false,
  results: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_REVIEWS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        ...action.data,
        results: [...state.results, ...action.data.results],
      };
    case "GET_MORE_REVIEWS_REQUEST":
      return {
        ...state,
        isLoadingMore: true,
      };
    case "GET_MORE_REVIEWS_SUCCESS":
      return {
        ...state,
        isLoadingMore: false,
        ...action.data,
        results: [...state.results, ...action.data.results],
      };
    case "GET_MORE_REVIEWS_FAIL":
      return {
        ...state,
        isLoadingMore: false,
      };
    default:
      return state;
  }
};
