import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getToken, setToken, setUser } from "../services/auth";
import {
  getSteps,
  getAllergens,
  getLocations,
  getCuisines,
  loginSuccess,
  logoutOnboarding,
} from "../actions";
import URLSearchParams from "@ungap/url-search-params";

import CreateProfileStepBusinessInfo from "../components/create-profile/CreateProfileStepBusinessInfo";
import CreateProfileStepTradingInfo from "../components/create-profile/CreateProfileStepTradingInfo";
import CreateProfileStepPhotos from "../components/create-profile/CreateProfileStepPhotos";
import CreateProfileStepSchedule from "../components/create-profile/CreateProfileStepSchedule";
import CreateProfileStepAgreement from "../components/create-profile/CreateProfileStepAgreement";
import MainError from "../components/forms/MainError";
import OnboardingHeader from "../components/onboarding/OnboardingHeader";
import Pending from "../components/create-profile/Pending";
import ModalPortal from "./ModalPortal";
import LeaveModal from "../components/generic/LeaveModal";
import loading from "../assets/icons/loading.svg";

const steps = {
  1: CreateProfileStepBusinessInfo,
  2: CreateProfileStepTradingInfo,
  3: CreateProfileStepPhotos,
  4: CreateProfileStepSchedule,
  5: CreateProfileStepAgreement,
  6: Pending,
};

// mapping from backend's onboarding_step attribute to frontend step.
// Unfortunatelly we can only append new steps to existing workflow,
// which makes `onboarding_step` unsorted sequence. We need to map
// it to frontend `step`, which is sorted sequence, to keep it simple.
const onboardingStepMap = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 5,
  7: 5,
  8: 4,
};

class CreateProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      loading: true,
      error: false,
      showModal: false,
    };
    this.changeStep = this.changeStep.bind(this);
    this.getOnboardingStep = this.getOnboardingStep.bind(this);
    this.showStep = this.showStep.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logout = this.logout.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;

    const fetchData = () => {
      // Fetch all data for onboarding process
      Promise.all([
        this.props.getSteps(),
        this.props.getAllergens(),
        this.props.getLocations(),
        this.props.getCuisines(),
      ])
        .then(() => {
          if (!this._isMounted) return;
          this.setState({ loading: false });
          this.changeStep(this.getOnboardingStep());
        })
        .catch(() => {
          if (!this._isMounted) return;
          this.setState(
            {
              loading: false,
            },
            this.changeStep(this.getOnboardingStep())
          );
        });
    };

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    const { isLoggedIn, user } = this.props;

    if (token) {
      setToken(token);
      setUser(null);
      this.props.loginSuccess(token);
      fetchData();
    } else if ((isLoggedIn && user.status === 1) || getToken()) {
      // isLoggedIn might be null when `loginSuccess` action was recently dispatched.
      // We need to check `getToken` directly to check for logged in users.
      fetchData();
    } else {
      this.props.history.push("/");
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.intercomHash &&
      !prevProps.intercomHash &&
      process.env.NODE_ENV === "production"
    ) {
      window.Intercom("boot", {
        app_id: "pakc0tx3",
        email: this.props.onboardingUser.email,
        user_id: this.props.onboardingUser.id,
        user_hash: this.props.intercomHash,
      });
    }

    if (this.props.status === 2 && this.state.step !== 6) {
      this.changeStep(6);
      return;
    }

    if (prevProps.step !== this.props.step) {
      this.changeStep(this.getOnboardingStep());
    }
  }

  getOnboardingStep() {
    return onboardingStepMap[this.props.step];
  }

  changeStep(step) {
    this.setState({ step }, this.showStep);
  }
  showStep() {
    switch (this.state.step) {
      case null:
        this.props.history.push("/sign-up/success");
        break;
      case 1:
        this.props.history.push("/create-profile/step-one");
        break;
      case 2:
        this.props.history.push("/create-profile/step-two");
        break;
      case 3:
        this.props.history.push("/create-profile/step-three");
        break;
      case 4:
        this.props.history.push("/create-profile/step-four");
        break;
      case 5:
        this.props.history.push("/create-profile/step-five");
        break;
      case 6:
        this.props.history.push("/create-profile/pending");
        break;
      default:
        return;
    }
  }
  openModal() {
    this.setState({ showModal: true });
  }
  closeModal() {
    this.setState({ showModal: false });
  }
  logout() {
    const { logoutOnboarding, history } = this.props;
    logoutOnboarding();
    history.push("/");
  }
  render() {
    const { token } = this.props;
    const { showModal } = this.state;
    const { logout, closeModal, openModal, changeStep } = this;

    const StepComponent = steps[this.state.step];

    return (
      <React.Fragment>
        <ModalPortal showModal={showModal}>
          <LeaveModal logout={logout} closeModal={closeModal}>
            <p>Any unsubmitted progress will be lost.</p>
          </LeaveModal>
        </ModalPortal>
        <div className="grid-x onboarding-container">
          <OnboardingHeader
            title="Hawkker - Business Profile Builder"
            openModal={openModal}
            token={token}
          />
          <div className="onboarding cell medium-9">
            {this.props.error ? <MainError errorText={this.props.error} /> : ""}
            {this.state.loading && (
              <div className="grid-x align-center create-profile__loading">
                <img
                  className="create-profile__loading-image"
                  src={loading}
                  alt="loading"
                />
              </div>
            )}

            {!this.state.loading && StepComponent != null && (
              <StepComponent
                changeStep={changeStep}
                currentStep={this.state.step}
                stepCount={8}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  step: state.onboarding.onboarding_stage,
  onboardingUser: {
    id: state.onboarding.id,
    email: state.onboarding.email,
  },
  intercomHash: state.onboarding.intercom_user_hash_web,
  status: state.onboarding.status,
  error: state.onboarding.error,
  token: state.auth.token,
  isLoggedIn: state.auth.user,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSteps,
      getAllergens,
      getCuisines,
      getLocations,
      loginSuccess,
      logoutOnboarding,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProfileContainer);
