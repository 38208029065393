import React from "react";
import { Form } from "react-final-form";

import { useSafeContext } from "components/useSafeContext";

export type MenuItemFormValues = {
  id?: number;
  name: string;
  description: string;
  price: string;
  dietary_information: number[];
};

export type MenuItemFormContext = {
  showAddForm: boolean;
  editFormId: number | null;
  initialValues: MenuItemFormValues | null;

  open: (editValues?: MenuItemFormValues) => void;
  close: () => void;
  submit: (values: MenuItemFormValues) => void;
};

export function MenuItemFormProvider({ onCreate, onUpdate, children }) {
  const [initialValues, setInitialValues] = React.useState(null);

  const open = (editValues = null) => {
    setInitialValues(
      editValues != null
        ? {
            ...editValues,
            section: editValues.section?.id,
            dietary_information: editValues.dietary_information.map(
              (item) => item.id
            ),
          }
        : {
            name: "",
            description: "",
            price: "",
            dietary_information: [],
          }
    );
  };

  const close = () => {
    setInitialValues(null);
  };

  const submit = (values: MenuItemFormValues) => {
    (async () => {
      const { id, ...data } = values;
      try {
        if (id) {
          await onUpdate(id, data);
        } else {
          await onCreate(data);
        }
      } catch (e) {
        return { FORM_ERROR: e.response.error };
      }

      setInitialValues(null);
    })();
  };

  const ctx = React.useMemo(
    () => ({
      showAddForm: initialValues != null && initialValues.id == null,
      editFormId: initialValues?.id,
      initialValues,

      open,
      close,
      submit,
    }),
    [JSON.stringify(initialValues)]
  );
  return (
    <MenuItemFormContext.Provider value={ctx}>
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        validate={validateMenuItem}
        render={() => children}
      />
    </MenuItemFormContext.Provider>
  );
}

export function validateMenuItem(values: MenuItemFormValues) {
  const errors: Record<string, string> = {};
  if (!values.name) {
    errors.name = "Name is required";
  }

  if (!values.price) {
    errors.price = "Price is required";
  } else if (isNaN(parseFloat(values.price))) {
    errors.price = "Price must be a number";
  }

  return errors;
}

const MenuItemFormContext = React.createContext(null);

export function useMenuItemForm() {
  return useSafeContext(
    MenuItemFormContext,
    "useMenuItemForm",
    "MenuItemFormContext"
  );
}
