import React from "react";

const DashboardSummaryBox = (props) => {
  const {
    title,
    description,
    image,
    figure,
    denominator,
    up,
    down,
    isFraction,
  } = props;

  return (
    <div className="dashboard-summary">
      <div className="dashboard-summary-top">
        <h4>{title}</h4>
        <p className="dashboard-summary-top__description">{description}</p>
      </div>
      <div className="dashboard-summary-btm">
        <img className="dashboard-summary__btm__icon" src={image} alt={title} />
        <span
          className={`dashboard-summary__btm__figure ${
            isFraction ? "" : "center"
          }`}
        >
          {figure}
          {denominator ? (
            <span className="dashboard-summary__btm__figure__denominator">
              /{denominator}
            </span>
          ) : (
            ""
          )}
          {up.isUp ? (
            <div
              className={`dashboard-summary__btm__figure__up ${
                up.isPositive ? "positive" : "negative"
              }`}
            />
          ) : (
            ""
          )}
          {down.isDown ? (
            <div
              className={`dashboard-summary__btm__figure__down ${
                down.isPositive ? "positive" : "negative"
              }`}
            />
          ) : (
            ""
          )}
        </span>
      </div>
    </div>
  );
};

DashboardSummaryBox.defaultProps = {
  up: {
    isUp: false,
  },
  down: {
    isDown: false,
  },
};

export default DashboardSummaryBox;
