import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class CameraPermissionInformationText extends React.Component {
  render() {
    return (
      <div className="inner_container">
        <p>Check your browser permissions and then your device permissions</p>
        <Tabs>
          <TabList>
            <Tab>Chrome</Tab>
            <Tab>Firefox</Tab>
            <Tab>Edge</Tab>
            <Tab>Internet Explorer</Tab>
          </TabList>

          <TabPanel>
            <ol>
              <li>
                To the left of the web address, click on the icon (probably a
                lock)
              </li>
              <li>
                Change Camera to 'Allow'. Your changes will save automatically.
              </li>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>

          <TabPanel>
            <ol>
              <li>To the left of the web address, click on an icon</li>
              <li>
                After 'Use the Camera' you should see 'Blocked'. Click on the
                'X' after this
              </li>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>

          <TabPanel>
            <p className="clear">Try this first:</p>
            <ol>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
            <p className="clear">If that doesn't work, try this:</p>
            <ol>
              <li>Top right hand corner, click on the three dots</li>
              <li>At the end of this menu, select 'Settings'</li>
              <li>
                Towards the end of the list, click on 'View advanced settings'
              </li>
              <li>
                If you have 'Manage websites' option, click on that. If not,
                stop here and check the Windows 10 settings (below)
              </li>
              <li>
                Hover over the line that says 'hawkker.com' and click on the 'x'
                to the right
              </li>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>

          <TabPanel>
            <p>
              Direct use of the camera for QR code scanning is not supported for
              Internet Explorer. You will have to use a different browser, like
              Google Chrome or Mozilla Firefox
            </p>
          </TabPanel>
        </Tabs>
        <br />
        <Tabs>
          <TabList>
            <Tab>Android</Tab>
            <Tab>Windows 10</Tab>
          </TabList>

          <TabPanel>
            <p className="clear">The following requires Android 6</p>
            <ol>
              <li>Start the Settings app</li>
              <li>Under 'Device' click on 'Apps'</li>
              <li>
                Find your browser (e.g. 'Chrome') in the list and click on it
              </li>
              <li>Click on 'Permissions</li>
              <li>Make sure 'Camera' is on (slider to the right)</li>
              <li>In your browser, reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>

          <TabPanel>
            <h2>Windows 10</h2>
            <ol>
              <li>Click the Windows button</li>
              <li>
                Click the gear icon, a few icons above the Windows button, to
                open Settings
              </li>
              <li>Click on the 'Privacy' Lock icon</li>
              <li>In the left column, click on 'Location'</li>
              <li>Make sure that 'Location service' is On</li>
              <li>
                Scroll down to 'Choose apps that can use your precise location'
              </li>
              <li>Make sure this is switched on for your internet browser</li>
              <li>In your browser, reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

class LocationPermissionInformationText extends React.Component {
  render() {
    return (
      <div className="inner_container">
        <p>Check your browser permissions and then your device permissions</p>
        <Tabs>
          <TabList>
            <Tab>Chrome</Tab>
            <Tab>Firefox</Tab>
            <Tab>Edge</Tab>
            <Tab>Internet Explorer</Tab>
          </TabList>

          <TabPanel>
            <ol>
              <li>
                To the left of the web address, click on the icon (probably a
                lock)
              </li>
              <li>
                Change Location to 'Allow'. Your changes will save
                automatically.
              </li>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>

          <TabPanel>
            <ol>
              <li>To the left of the web address, click on an icon</li>
              <li>
                After 'Access Your Location' you should see 'Blocked'. Click on
                the 'X' after this
              </li>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>

          <TabPanel>
            <p className="warning">
              If you've accidentally denied location permission in Edge, you
              will have to clear <b>all</b> your location decisions, not just
              for Hawkker
            </p>
            <ol>
              <li>Top right hand corner, click on the three dots</li>
              <li>At the end of this menu, select 'Settings'</li>
              <li>
                Under 'Clear browsing data' click on the 'Choose what to clear'
                button
              </li>
              <li>
                Make sure 'Location permissions' is selected (blue tick mark),{" "}
                <b>and nothing else</b>
              </li>
              <li>Click the 'Clear' button</li>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>

          <TabPanel>
            <p className="clear">
              <b>Try this first:</b>
            </p>
            <ol>
              <li>Top right hand corner, click on the gear/cog icon</li>
              <li>Almost at the end, click on 'Internet options'</li>
              <li>Select the Privacy tab</li>
              <li>
                Under 'Location' is a checkbox 'Never allow websites to request
                your physical location'. Make sure this is not checked
              </li>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
            <p className="warning">
              If that didn't work, you will have to clear <b>all</b> your
              location decisions, not just for Hawkker:
            </p>
            <ol>
              <li>Top right hand corner, click on the gear/cog icon</li>
              <li>Almost at the end, click on 'Internet options'</li>
              <li>Select the Privacy tab</li>
              <li>Under 'Location' click 'Clear Sites'</li>
              <li>Reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>
        </Tabs>
        <br />
        <Tabs>
          <TabList>
            <Tab>Android</Tab>
            <Tab>Windows 10</Tab>
          </TabList>

          <TabPanel>
            <p className="clear">Try this first</p>
            <ol>
              <li>Start the Settings app</li>
              <li>Under 'Personal' click on 'Location'</li>
              <li>Make sure it is on (slider, top right)</li>
              <li>In your browser, reload this page, e.g. by pressing F5</li>
            </ol>
            <p className="clear">
              If that doesn't work, and you've got at least Android 6, try this
            </p>
            <ol>
              <li>Start the Settings app</li>
              <li>Under 'Device' click on 'Apps'</li>
              <li>
                Find your browser (e.g. 'Chrome') in the list and click on it
              </li>
              <li>Click on 'Permissions</li>
              <li>Make sure 'Location' is on (slider to the right)</li>
              <li>In your browser, reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>

          <TabPanel>
            <h2>Windows 10</h2>
            <ol>
              <li>Click the Windows button</li>
              <li>
                Click the gear icon, a few icons above the Windows button, to
                open Settings
              </li>
              <li>Click on the 'Privacy' Lock icon</li>
              <li>In the left column, click on 'Camera'</li>
              <li>Make sure that 'Let apps use my camera' is On</li>
              <li>Scroll down to 'Choose apps that can use your camera'</li>
              <li>Make sure this is switched on for your internet browser</li>
              <li>In your browser, reload this page, e.g. by pressing F5</li>
            </ol>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export { LocationPermissionInformationText, CameraPermissionInformationText };
