import React from "react";
import { MenuItemActions } from "components/menu/MenuItemActions";

export function MenuItem({ dish, readOnly = false }) {
  return (
    <div className="p-3 bg-white border boder-gray-300 rounded-lg">
      <div className="mb-1 flex justify-between items-center">
        <p className="font-bold">{dish.name}</p>
        {!readOnly && <MenuItemActions id={dish.id} />}
      </div>
      {dish.description && (
        <p className="mb-2 text-gray-600">{dish.description}</p>
      )}
      <p className="mb-1">£{dish.price}</p>

      {!!dish.dietary_information.length && (
        <p className="text-sm text-gray-600">
          {dish.dietary_information.map((dietary) => dietary.name).join(", ")}
        </p>
      )}
    </div>
  );
}
