import * as locations from "../services/locations";

// eslint-disable-next-line import/prefer-default-export
export const getVendorLocations = () => (dispatch) =>
  locations
    .getVendorLocations()
    .then((response) => {
      dispatch({
        type: "SET_VENDOR_LOCATIONS",
        locations: response.data.results,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });

// eslint-disable-next-line import/prefer-default-export
export const getLocationOfferings = () => (dispatch) =>
  locations
    .getLocationOfferings()
    .then((response) => {
      dispatch({
        type: "SET_LOCATION_OFFERINGS",
        // Backward compatibility.
        // Let's migrate backend to unpaginated results. Since FE is already
        // in production, we need to handle both result types.
        locationOfferings: response.data.results ?? response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });

// eslint-disable-next-line import/prefer-default-export
export const getPostcodeAreas = () => (dispatch) =>
  locations
    .getPostcodeAreas()
    .then((response) => {
      dispatch({
        type: "SET_POSTCODE_AREAS",
        postcodeAreas: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });
