import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import classnames from "classnames";

import { DeleteIcon, DragIcon } from "icons";
import { plural } from "core/i18n";
import { IconButton } from "components/generic/IconButton";
import { ConfirmationDialog } from "components/ConfirmationDialog";
import { MenuSectionEditForm } from "components/menu/MenuSectionEditForm";

export function MenuSections(props: MenuSectionsProps) {
  const [sectionEdit, setSectionEdit] = React.useState(null);

  const handleSectionEdit = (item) => (e) => {
    e.stopPropagation();
    setSectionEdit(item);
  };

  const handleSectionSubmit = async (values) => {
    await props.onUpdate(values.id, { name: values.name });
    handleSectionEdit(null);
  };

  if (props.sections == null) return null;

  return (
    <Droppable droppableId="droppable">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {props.sections.map((section, index) => {
            const isActive = props.activeSection === section.id;
            return (
              <Draggable
                key={section.id}
                draggableId={section.id.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    className={classnames(
                      "px-6 border-l-2 cursor-pointer hover:text-gray-900",
                      {
                        "border-brand-blue text-gray-900": isActive,
                        "border-transparent text-gray-600": !isActive,
                      }
                    )}
                    onClick={() => props.onSelect(section.id)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="h-20 flex items-center space-x-2 border-b border-gray-200">
                      {sectionEdit?.id === section.id ? (
                        <MenuSectionEditForm
                          initialValues={sectionEdit}
                          onSubmit={handleSectionSubmit}
                          onClose={handleSectionEdit(null)}
                        />
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={handleSectionEdit(section)}
                            className="p-px rounded hover:bg-gray-200"
                          >
                            {section.name}
                          </button>
                          <div className="flex-1" />
                          {section.dish_count ? (
                            <span>
                              ({plural(section.dish_count, "# item", "# items")}
                              )
                            </span>
                          ) : (
                            <span className="text-brand-orange">(empty)</span>
                          )}
                          <ConfirmationDialog
                            text={`Do you really want to delete section ${section.name}?`}
                            onConfirm={() => props.onDelete(section.id)}
                          >
                            <IconButton variant="terniary">
                              <DeleteIcon />
                            </IconButton>
                          </ConfirmationDialog>

                          <IconButton variant="terniary">
                            <DragIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export type MenuSectionsProps = {
  sections: any[];
  activeSection: number | null;
  onSelect: (section: number) => void;
  onUpdate: (id: number, values: any) => Promise<void>;
  onDelete: (id: number) => void;
};
