import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import thunkMiddleware from "redux-thunk";
import combineReducers from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const historyMiddleware = routerMiddleware(history);

const store = createStore(
  combineReducers,
  composeEnhancers(applyMiddleware(historyMiddleware, thunkMiddleware))
);

export default store;
