import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import CrumbRoute from "./CrumbRoute";

import GenericError from "../components/errors/GenericError";
import ProfileOverview from "../components/profile/ProfileOverview";
import EditBusinessInfo from "../components/profile/EditBusinessInfo";
import EditLocation from "../components/profile/EditLocation";
import EditFurtherInfo from "../components/profile/EditFurtherInfo";
import EditAllergens from "../components/profile/EditAllergens";
import EditIngredients from "../components/profile/EditIngredients";
import EditImages from "../components/profile/EditImages";
import EditAddress from "../components/profile/EditAddress";
import EditUserInformation from "../components/profile/EditUserInformation";
import ChangePassword from "../components/profile/ChangePassword";
import DeleteAccount from "../components/profile/DeleteAccount";

const ProfileContainer = (props) => {
  if (props.error) return <GenericError />;
  if (props.loading) return <div className="loading-placeholder" />;
  return (
    <Switch>
      <Route exact path="/profile" component={ProfileOverview} />
      <CrumbRoute
        exact
        title="Edit Business Info"
        path="/profile/edit-business-info"
        component={EditBusinessInfo}
      />
      <CrumbRoute
        exact
        title="Edit Location"
        path="/profile/edit-location"
        component={EditLocation}
      />
      <CrumbRoute
        exact
        title="Edit Further Info"
        path="/profile/edit-further-info"
        component={EditFurtherInfo}
      />
      <CrumbRoute
        exact
        title="Edit Allergens"
        path="/profile/edit-allergens"
        component={EditAllergens}
      />
      <CrumbRoute
        exact
        title="Edit Ingredients"
        path="/profile/edit-ingredients"
        component={EditIngredients}
      />
      <CrumbRoute
        exact
        title="Edit Images"
        path="/profile/edit-images"
        component={EditImages}
      />
      <CrumbRoute
        exact
        title="Edit Address"
        path="/profile/edit-address"
        component={EditAddress}
      />
      <CrumbRoute
        exact
        title="Edit User Information"
        path="/profile/edit-user-information"
        component={EditUserInformation}
      />
      <CrumbRoute
        exact
        title="Change Password"
        path="/profile/change-password"
        component={ChangePassword}
      />
      <CrumbRoute
        exact
        title="Delete Account"
        path="/profile/delete-account"
        component={DeleteAccount}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  loading: state.profile.loading,
  error: state.profile.error,
});

export default connect(mapStateToProps)(ProfileContainer);
