export function flatten(list) {
  return [].concat.apply([], list);
}

export function reorder(list, from, to) {
  const result = Array.from(list);
  const [removed] = result.splice(from, 1);
  result.splice(to, 0, removed);
  return result;
}
