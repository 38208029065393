import React from "react";
import validation from "../../../helpers/validation";

import InputCharCount from "../../forms/InputCharCount";
import MainError from "../../forms/MainError";
import CuisineSelect from "../../forms/CuisineSelect";

class EditBusinessInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      name: props.profile.name,
      tagline: props.profile.tagline,
      biography: props.profile.biography,
      referred_by_code: props.profile.referred_by_code,
      cuisines: {
        selected: props.profile.cuisine.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        valid: true,
        validate: false,
      },
      social_links: {
        facebook:
          props.profile.social_links && props.profile.social_links.facebook
            ? props.profile.social_links.facebook
            : "",
        twitter:
          props.profile.social_links && props.profile.social_links.twitter
            ? props.profile.social_links.twitter
            : "",
        instagram:
          props.profile.social_links && props.profile.social_links.instagram
            ? props.profile.social_links.instagram
            : "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSocialChange = this.handleSocialChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCuisineChange = this.handleCuisineChange.bind(this);
  }
  handleInputChange(e) {
    let value = e.target.value;
    if (e.target.name === "referred_by_code") {
      value = value.toUpperCase();
    }
    this.setState({
      [e.target.name]: value,
    });
  }
  handleCuisineChange(selected) {
    this.setState(
      {
        cuisines: {
          ...this.state.cuisines,
          selected: selected || [],
        },
      },
      () => this.checkCuisinesValid()
    );
  }
  handleSocialChange(e) {
    this.setState({
      social_links: {
        ...this.state.social_links,
        [e.target.name]: e.target.value,
      },
    });
  }
  checkCuisinesValid() {
    if (!this.state.cuisines.validate) return;
    this.cuisinesValid();
  }
  cuisinesValid() {
    if (
      this.state.cuisines.selected.length < 1 ||
      this.state.cuisines.selected.length > 3
    ) {
      this.setState({
        cuisines: {
          ...this.state.cuisines,
          valid: false,
          validate: true,
        },
      });
      return false;
    } else {
      this.setState({
        cuisines: {
          ...this.state.cuisines,
          valid: true,
          validate: true,
        },
      });
      return true;
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    // Validate
    const cuisinesValid = this.cuisinesValid();
    if (!validation(e.target) || !cuisinesValid) {
      this.setState({ valid: false });
      window.scrollTo(0, 0);
      return;
    } else {
      this.setState({ valid: true });
    }
    // Data composed and submitted in callback function
    const data = {
      ...this.state,
      cuisines: {
        ...this.state.cuisines,
        selected: this.state.cuisines.selected.map(({ value }) => value),
      },
    };
    this.props.submitHandler(data);
  }
  render() {
    const cuisinesOptions = this.props.cuisines.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    return (
      <div>
        {this.props.error ? <MainError errorText={this.props.error} /> : ""}
        {!this.state.valid ? (
          <MainError
            errorText={
              "Error: Please check you have entered all information correctly."
            }
          />
        ) : (
          ""
        )}
        <form onSubmit={this.handleSubmit} noValidate>
          <fieldset>
            <InputCharCount
              changeHandler={this.handleInputChange}
              label="Business Name"
              maxLength="30"
              name="name"
              required={true}
              type="text"
              validate="business-name"
              value={this.state.name}
            />
            <InputCharCount
              changeHandler={this.handleInputChange}
              label="Tagline"
              maxLength="50"
              name="tagline"
              placeholder='A short description of your food, e.g. "Japanese Soul Food"'
              required={true}
              type="text"
              validate="tagline"
              value={this.state.tagline}
            />
            <InputCharCount
              changeHandler={this.handleInputChange}
              label="Biography"
              maxLength="600"
              name="biography"
              placeholder="Share the story of your business and food"
              required={true}
              rows="3"
              type="textarea"
              validate="biography"
              value={this.state.biography}
            />
          </fieldset>
          {this.props.include_referred_by_code_field && (
            <fieldset>
              <InputCharCount
                changeHandler={this.handleInputChange}
                label="Invite Code"
                maxLength="6"
                name="referred_by_code"
                placeholder="Enter Invite Code (if you have one)"
                required={false}
                type="text"
                validate="referred_by_code"
                value={this.state.referred_by_code}
              />
            </fieldset>
          )}
          <fieldset>
            <legend>Cuisine Types</legend>
            <p>
              Which of the following descriptors represent your food? (Choose up
              to 3)
            </p>
            <CuisineSelect
              name="cuisines"
              options={cuisinesOptions}
              isMulti
              value={this.state.cuisines.selected}
              onChange={this.handleCuisineChange}
            />
            {!this.state.cuisines.valid && (
              <span className="validation-message">
                Please select between one and three cuisine types
              </span>
            )}
          </fieldset>
          <fieldset>
            <legend>
              Social Media Links <span className="label-hint">(optional)</span>
            </legend>
            <p>
              Include full URL links to your social media profiles so eaters can
              find you.
            </p>
            <label>
              Facebook
              <input
                name="facebook"
                type="url"
                placeholder="https://www.facebook.com/hawkker"
                value={this.state.social_links.facebook}
                onChange={this.handleSocialChange}
                data-validate="facebook"
              ></input>
            </label>
            <span
              className="hide validation-message"
              data-validate-message="facebook"
            >
              Error: Please provide a valid URL including 'http://'
            </span>
            <label>
              Twitter
              <input
                name="twitter"
                type="url"
                placeholder="https://twitter.com/hawkker"
                value={this.state.social_links.twitter}
                onChange={this.handleSocialChange}
                data-validate="twitter"
              ></input>
            </label>
            <span
              className="hide validation-message"
              data-validate-message="twitter"
            >
              Error: Please provide a valid URL including 'http://'
            </span>
            <label>
              Instagram
              <input
                name="instagram"
                type="url"
                placeholder="https://www.instagram.com/hawkker"
                value={this.state.social_links.instagram}
                onChange={this.handleSocialChange}
                data-validate="instagram"
              ></input>
            </label>
            <span
              className="hide validation-message"
              data-validate-message="instagram"
            >
              Error: Please provide a valid URL including 'http://'
            </span>
          </fieldset>
          <button
            type="submit"
            className="button expanded"
            disabled={this.props.submitDisabled}
          >
            {this.props.buttonText || "Save"}
          </button>
        </form>
      </div>
    );
  }
}

export default EditBusinessInfoForm;
