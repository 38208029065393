import React from "react";
import { useField } from "react-final-form";

import RadioItem from "./RadioItem";

export default function RadioItemField(props) {
  const { input } = useField(props.name, { type: "radio", value: props.value });

  return <RadioItem {...props} {...input} />;
}
