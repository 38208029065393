import useSWR from "swr";
import { fetcher } from "api/base";

export const menuSectionListEndpoint = "api/v2/dish/menu-section/";

export function useMenuSections(params: UseMenuSectionsParams = {}) {
  const p = new URLSearchParams();
  if (params.vendor) p.set("vendor", params.vendor.toString());
  if (params.expand) p.set("expand", params.expand.join(","));

  return useSWR(`${menuSectionListEndpoint}?${p.toString()}`, fetcher);
}

type UseMenuSectionsParams = {
  expand?: string[];
  vendor?: string[];
};
