import React from "react";
import { Field, Form } from "react-final-form";
import classnames from "classnames";

import { CrossIcon, EnterIcon } from "icons";
import { IconButton } from "components/generic/IconButton";

export function MenuSectionEditForm(props: MenuSectionEditFormProps) {
  const handleKeyPress = (e) => {
    if (e.keyCode === 27) {
      props.onClose(e);
    }
  };

  return (
    <Form
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className={classnames(
            "w-full flex items-center space-x-2",
            props.className
          )}
        >
          <Field
            autoFocus
            name="name"
            className="flex-1 border-transparent bg-transparent focus:border-transparent focus:bg-transparent focus:outline-none"
            component="input"
            onKeyDown={handleKeyPress}
          />
          <span className="inline-flex items-center text-sm text-gray-500">
            <EnterIcon className="mr-2" /> Enter to save
          </span>
          <IconButton variant="terniary" onClick={props.onClose}>
            <CrossIcon />
          </IconButton>
        </form>
      )}
    />
  );
}

type MenuSectionEditFormProps = {
  className?: string;
  initialValues: any;
  onSubmit: (values: any) => void;
  onClose: (e: any) => void;
};
