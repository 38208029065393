import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import pending from "../../assets/images/pending.svg";
import { getAuthUser } from "../../actions";

function Pending({ getAuthUser }) {
  React.useEffect(() => {
    getAuthUser();
  }, []);

  return (
    <div className="grid-x align-center">
      <img src={pending} alt="Coming Soon" />
      <span className="create-profile__pending-title">
        Thank you for building your Hawkker profile. Our team will review your
        application to confirm the eligibility of your business for the Hawkker
        platform.
      </span>
      <p className="text-center create-profile__pending-blurb">
        You'll get a confirmation email once we've completed our review. From
        there, you can build your menu and start using Hawkker to promote your
        business and get insights from your customers!
      </p>
      <Link to="/menu" className="button expanded">
        Go to My Account
      </Link>
    </div>
  );
}

export default connect(undefined, { getAuthUser })(Pending);
