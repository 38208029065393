import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getPurchases,
  getNextPurchases,
  getDishNames,
  getFilteredPurchases,
  getNextFilteredPurchases,
} from "../actions";

import ScrollToTop from "../components/layout/ScrollToTop";
import TransactionFilters from "../components/transactions/TransactionFilters";
import TransactionsTable from "../components/transactions/TransactionsTable";
import NoTransactions from "../components/transactions/NoTransactions";

class TransactionsContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      filtersOpen: false,
      filtered: false,
      filters: {
        days: "Last 7 Days",
        rating: "Any Rating",
        dish: "Any Dish",
      },
      showing: {
        days: "Last 7 Days",
        rating: "Any Rating",
        dish: "Any Dish",
      },
      filtersData: {
        days: 7,
        rating: null,
        dish: null,
      },
      view: "default",
    };
  }
  componentDidMount() {
    const { purchases, getPurchases, dishNames, getDishNames } = this.props;
    if (purchases.isFetching) {
      getPurchases();
    }

    if (!dishNames.length) {
      getDishNames();
    }
  }
  filterOptionChange = (e) => {
    const type = e.target.id;
    const value = e.target.value;
    const i = e.target.options.selectedIndex;
    const dataValue = e.target.options[i].dataset.value;

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [type]: value,
      },
      filtersData: {
        ...prevState.filtersData,
        [type]: dataValue === "null" ? null : dataValue,
      },
    }));
  };
  handleFilterSubmit = (e) => {
    e.preventDefault();
    const { getFilteredPurchases } = this.props;
    const { filtersData } = this.state;
    this.setState((prevState) => ({
      filtered: true,
      filtersOpen: false,
      showing: {
        ...prevState.filters,
      },
    }));
    getFilteredPurchases(filtersData);
  };
  openFilters = () => {
    this.setState((prevState) => ({
      filtersOpen: !prevState.filtersOpen,
    }));
  };
  purchases = () => {
    const { filtered } = this.state;
    const { purchases } = this.props;
    if (filtered) return purchases.filtered;
    return purchases;
  };
  transactions = () => {
    const { filtered } = this.state;
    const { purchases } = this.props;
    if (filtered) return purchases.filtered.results;
    return purchases.results;
  };
  getNextPurchases = () => {
    const { purchases } = this;
    const { getNextPurchases, getNextFilteredPurchases } = this.props;
    const { filtered } = this.state;
    if (filtered) {
      getNextFilteredPurchases(purchases().next);
    } else {
      getNextPurchases(purchases().next);
    }
  };
  render() {
    const {
      openFilters,
      transactions,
      filterOptionChange,
      handleFilterSubmit,
      purchases,
      getNextPurchases,
    } = this;
    const { filtersOpen, filters, showing } = this.state;
    const { isFetchingNext, dishNames, isFetchingFiltered } = this.props;

    if (purchases().isFetching || purchases().dishNamesFetching)
      return <div className="loading-placeholder" />;

    return (
      <div className="grid-container transaction-detail">
        <ScrollToTop className="transaction-detail__scroll-to-top" />
        <TransactionFilters
          changeHandler={filterOptionChange}
          dishNames={dishNames}
          filters={filters}
          filtersOpen={filtersOpen}
          handleFilterSubmit={handleFilterSubmit}
          isFetchingFiltered={isFetchingFiltered}
          openFilters={openFilters}
          showing={showing}
        />
        {!isFetchingFiltered ? (
          !transactions().length ? (
            <NoTransactions />
          ) : (
            <TransactionsTable
              getNextPurchases={getNextPurchases}
              isFetchingFiltered={isFetchingFiltered}
              isFetchingNext={isFetchingNext}
              purchases={purchases()}
              transactions={transactions()}
            />
          )
        ) : (
          <div className="loading-placeholder transactions-table-loading" />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  purchases: state.purchases,
  isFetchingNext: state.purchases.isFetchingNext,
  isFetchingFiltered: state.purchases.isFetchingFiltered,
  dishNames: state.purchases.dishNames,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPurchases,
      getNextPurchases,
      getDishNames,
      getFilteredPurchases,
      getNextFilteredPurchases,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsContainer);
