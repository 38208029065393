import signUp from "../services/sign-up";

export const register = (data) => (dispatch) =>
  signUp(data).catch((error) => {
    dispatch({
      type: "REGISTER_FAIL",
      error: error.data.error,
    });
    return Promise.reject();
  });
