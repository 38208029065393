import React from "react";
import moment from "moment";

const PastWeekRow = (props) => {
  const { showDetail, day, prevDay } = props;

  return (
    <tr onClick={() => showDetail(day, prevDay)}>
      <td>{moment(day.date).format("DD/MM/YYYY")}</td>
      <td className="desktop-only">
        {day.check_in
          ? moment(day.check_in, "HH:mm:ss.SSS").format("HH:mm")
          : "--"}
      </td>
      <td className="desktop-only">
        {day.check_out
          ? moment(day.check_out, "HH:mm:ss.SSS").format("HH:mm")
          : "--"}
      </td>
      <td>
        {day.transactions || "0"}
        {day.transactions > prevDay.transactions ? (
          <span className="past-week__arrow up" />
        ) : (
          ""
        )}
        {day.transactions < prevDay.transactions ? (
          <span className="past-week__arrow down" />
        ) : (
          ""
        )}
      </td>
      <td>
        £{day.sales || 0}
        {day.sales > prevDay.sales ? (
          <span className="past-week__arrow up" />
        ) : (
          ""
        )}
        {day.sales < prevDay.sales ? (
          <span className="past-week__arrow down" />
        ) : (
          ""
        )}
      </td>
      <td className="desktop-only">
        {day.wait_time
          ? moment(day.wait_time.substring(0, 3), "m.s").format(
              "m[mins] s[sec]"
            )
          : "--"}
      </td>
    </tr>
  );
};

export default PastWeekRow;
