import React from "react";

import SelectList from "../../forms/SelectList";
import MainError from "../../forms/MainError";

class EditLocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      location: {
        selected: props.profile
          ? props.profile.home_market[0]
            ? [props.profile.home_market[0].id.toString()]
            : []
          : [],
        valid: true,
        validate: false,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState(
      {
        location: {
          ...this.state.location,
          selected: [e.target.dataset.itemId],
        },
      },
      () => this.locationValid()
    );
  }
  locationValid() {
    if (this.state.location.selected.length < 1) {
      this.setState({
        location: {
          ...this.state.location,
          valid: false,
          validate: true,
        },
      });
      return false;
    } else {
      this.setState({
        location: {
          ...this.state.location,
          valid: true,
          validate: true,
        },
      });
      return true;
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    // Validate
    if (!this.locationValid()) {
      this.setState({ valid: false });
      this.refs.submit.disabled = false;
      return;
    } else {
      this.setState({ valid: true });
    }
    // Data composed and submitted in callback function
    const location = this.state.location.selected;
    this.props.submitHandler(location);
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit} noValidate>
        {this.props.error ? <MainError errorText={this.props.error} /> : ""}
        {!this.state.valid ? (
          <MainError
            errorText={
              "Error: Please check you have entered all information correctly."
            }
          />
        ) : (
          ""
        )}
        <p>
          Please specify the market or location you trade at most often. If you
          don't have a usual trading spot, select "Location Varies."
        </p>
        <fieldset>
          <SelectList
            changeHandler={this.handleChange}
            errorMessage={"Please specify a market"}
            filterable={true}
            items={this.props.locations.list}
            listname="locations"
            selected={this.state.location.selected}
            type="radio"
            valid={this.state.location.valid}
          />
        </fieldset>
        <button
          type="submit"
          ref="submit"
          className="button expanded"
          disabled={this.props.submitDisabled}
        >
          {this.props.buttonText || "Save"}
        </button>
      </form>
    );
  }
}

export default EditLocationForm;
