import React from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";

import { useToggle } from "components/useToggle";
import { Button } from "components/Button";
import { TailwindCssContainer } from "components/TailwindCssContainer";

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const dialog = useToggle();
  const handleOpen = (e) => {
    e.stopPropagation();
    dialog.enable();
  };

  return (
    <>
      {React.cloneElement(props.children, { onClick: handleOpen })}

      {dialog.value && (
        <DialogOverlay isOpen onDismiss={() => dialog.disable()}>
          <TailwindCssContainer className="z-50 fixed inset-0 sm:inset-auto sm:w-screen sm:h-screen sm:top-0 sm:left-0 p-4 flex items-center justify-center bg-gray-900 bg-opacity-60">
            <DialogContent className="mx-auto p-4 rounded-lg border border-gray-100 bg-white text-base shadow-xl outline-none">
              {props.text}

              <div className="pt-4 mt-8 flex justify-between items-center border-t border-gray-100">
                <Button color="transparent" onClick={() => dialog.disable()}>
                  Cancel
                </Button>
                <Button
                  color="red"
                  onClick={() => {
                    props.onConfirm();
                    dialog.disable();
                  }}
                >
                  Delete
                </Button>
              </div>
            </DialogContent>
          </TailwindCssContainer>
        </DialogOverlay>
      )}
    </>
  );
}

type ConfirmationDialogProps = {
  className?: string;
  children: React.ReactElement;

  onConfirm: () => void;
  text: string;
};
