import React from "react";
import { Link } from "react-router-dom";

const DashboardDishList = (props) => {
  const { title, description, dishes, seeAllLink, largeTitle } = props;

  const noDishes = () => (
    <div className="dish-list__no-dishes-wrapper">
      <p className="dish-list__no-results">Nothing to see here yet!</p>
    </div>
  );

  const list = () => (
    <React.Fragment>
      <ul className="dish-list__list">
        {dishes.map((dish, i) => (
          <li key={dish.dish_id} className="dish-list__list__item">
            <span className="dish-list__list__item__container">
              <span className="dish-list__list__item__number">{i + 1}</span>
              <Link
                className="dish-list__list__item__link"
                to={`/menu/${dish.dish_id}`}
              >
                {dish.name}
              </Link>
            </span>
            <span className="dish-list__list__item__qty">
              {dish.qty ? dish.qty : dish.rating}
            </span>
          </li>
        ))}
      </ul>
      {seeAllLink ? (
        <span className="dish-list__see-all-container">
          <Link to={seeAllLink} className="dish-list__see-all">
            See all
          </Link>
        </span>
      ) : (
        ""
      )}
    </React.Fragment>
  );

  return (
    <div className="dish-list">
      {largeTitle ? <h1>{title}</h1> : <h4>{title}</h4>}
      <p className={largeTitle ? "" : "dish-list__description"}>
        {description}
      </p>
      {!dishes.length ? noDishes() : list()}
    </div>
  );
};

export default DashboardDishList;
