import React from "react";

const MainError = (props) => {
  if (typeof props.errorText === "string") {
    return <div className="callout alert">{props.errorText}</div>;
  } else {
    return (
      <div className="callout alert">
        {Object.keys(props.errorText).map((key) => (
          <p key={key}>{props.errorText[key].join(" ")}</p>
        ))}
      </div>
    );
  }
};

export default MainError;
