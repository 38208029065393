import React from "react";

import { MenuItemForm } from "components/menu/MenuItemForm";

export function MenuBuilderItemAddButton() {
  const [isOpen, setIsOpen] = React.useState(false);

  return isOpen ? (
    <MenuItemForm prefix="dish." onClose={() => setIsOpen(false)} />
  ) : (
    <button
      className="w-full px-4 py-2 rounded-full bg-gray-200 text-sm text-center font-bold cursor-pointer hover:bg-gray-400 transition-colors"
      onClick={() => setIsOpen(true)}
    >
      Add new item
    </button>
  );
}
