import React from "react";
import StarRating from "./StarRating";
import ReviewsTable from "./ReviewsTable";
import ScrollToTop from "../layout/ScrollToTop";

const Reviews = (props) => {
  const {
    averageRating,
    reviews,
    getMoreReviews,
    getMoreReviewsLoading,
  } = props;

  return (
    <div className="reviews">
      <ScrollToTop />
      <h2>All Reviews</h2>
      <div className="reviews__header">
        <span className="reviews__average-score__score">
          {averageRating}
          <span className="denominator">/5</span>
        </span>
        <div className="reviews-average-score__ratings">
          <StarRating rating={averageRating} />
          {reviews.count} reviews
        </div>
      </div>
      {reviews.count > 0 ? (
        <ReviewsTable reviews={reviews} />
      ) : (
        <div className="reviews__no-reviews">
          <h2 className="text-center">You have 0 reviews</h2>
          <p className="text-center">
            This page shows reviews that eaters have left for your food.
          </p>
        </div>
      )}
      {reviews.next ? (
        <div className="reviews__load-more-wrapper">
          <button
            className="load-more-btn"
            onClick={getMoreReviews}
            disabled={getMoreReviewsLoading}
          >
            Load More
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Reviews;
