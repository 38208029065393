const initialState = {
  loading: true,
  open: false,
  error: false,
  vendor: {},
  check_out: "00:00",
  opening_hours: [
    {
      weekday: 0,
      from_hour: "00:00:00",
      to_hour: "00:00:00",
      valid: true,
      open: true,
    },
    {
      weekday: 1,
      from_hour: "00:00:00",
      to_hour: "00:00:00",
      valid: true,
      open: true,
    },
    {
      weekday: 2,
      from_hour: "00:00:00",
      to_hour: "00:00:00",
      valid: true,
      open: true,
    },
    {
      weekday: 3,
      from_hour: "00:00:00",
      to_hour: "00:00:00",
      valid: true,
      open: true,
    },
    {
      weekday: 4,
      from_hour: "00:00:00",
      to_hour: "00:00:00",
      valid: true,
      open: true,
    },
    {
      weekday: 5,
      from_hour: "00:00:00",
      to_hour: "00:00:00",
      valid: true,
      open: true,
    },
    {
      weekday: 6,
      from_hour: "00:00:00",
      to_hour: "00:00:00",
      valid: true,
      open: true,
    },
  ],
};

const openingHours = (state, days) =>
  state.opening_hours.map((defaultHours) => {
    const fetchedHours = days.find(
      (day) => day.weekday === defaultHours.weekday
    );
    if (fetchedHours) return fetchedHours;
    return defaultHours;
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROFILE":
      return {
        ...state,
        ...action.data,
        opening_hours: openingHours(state, action.data.opening_hours),
      };
    case "SET_PROFILE_FAIL":
      return {
        ...state,
        error: true,
      };
    case "PROFILE_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
    case "EDIT_OFFER":
      return {
        ...state,
        offer: action.offer,
        offer_active: action.offerActive,
      };
    case "EDIT_PROFILE":
      return {
        ...state,
        ...action.data,
        opening_hours: action.data.opening_hours
          ? openingHours(state, action.data.opening_hours)
          : state.opening_hours,
      };
    case "EDIT_ADDRESS":
      return {
        ...state,
        vendor: {
          ...state.vendor,
          address_line_1: action.data.business.vendor.address_line_1,
          address_line_2: action.data.business.vendor.address_line_2,
          city: action.data.business.vendor.city,
          county: action.data.business.vendor.county,
          postcode: action.data.business.vendor.postcode,
        },
      };
    case "UPDATE_OPEN_BUSINESS":
      return {
        ...state,
        open: true,
        check_out: action.data.checkout_time,
      };
    case "UPDATE_CLOSE_BUSINESS":
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
