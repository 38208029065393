import * as profile from "../services/profile";
import * as auth from "../services/auth";

export const getProfile = () => (dispatch) => {
  return profile
    .getProfile()
    .then((response) => {
      dispatch({
        type: "SET_PROFILE",
        data: response.data,
      });
      window.Intercom("update", {
        name: response.data.name || "",
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_PROFILE_FAIL",
      });
    });
};

export const profileLoading = (loading) => ({
  type: "PROFILE_LOADING",
  loading,
});

export const editOffer = (data) => (dispatch) => {
  return profile
    .editOffer(data)
    .then((response) => {
      dispatch({
        type: "EDIT_OFFER",
        offer: data.offer,
        offerActive: data.offer_active,
      });
    })
    .catch((error) => Promise.reject(error));
};

export const editBusinessInfo = (data) => (dispatch) => {
  return profile
    .editBusinessInfo(data)
    .then((response) => {
      dispatch({
        type: "EDIT_PROFILE",
        data: response.data,
      });
    })
    .catch((error) => Promise.reject(error));
};

export const editAllergens = (data) => (dispatch) => {
  return profile
    .editAllergens(data)
    .then((response) => {
      dispatch({
        type: "EDIT_PROFILE",
        data: response.data,
      });
    })
    .catch((error) => Promise.reject(error));
};

export const editIngredients = (data) => (dispatch) => {
  return profile
    .editIngredients(data)
    .then((response) => {
      dispatch({
        type: "EDIT_PROFILE",
        data: response.data,
      });
    })
    .catch((error) => Promise.reject(error));
};

export const editLocation = (location) => (dispatch) => {
  return profile
    .editLocation(location)
    .then((response) => {
      dispatch({
        type: "EDIT_PROFILE",
        data: response.data,
      });
    })
    .catch((error) => Promise.reject(error));
};

export const editImages = (data) => (dispatch) => {
  return profile
    .editImages(data)
    .then((response) => {
      dispatch({
        type: "EDIT_PROFILE",
        data: response.data,
      });
    })
    .catch((error) => Promise.reject(error));
};

export const editUserInformation = (data) => (dispatch) => {
  return profile
    .editProfile(data)
    .then((response) => {
      dispatch({
        type: "EDIT_USER",
        data: response.data,
      });
      const user = JSON.parse(auth.getUser());
      user.name = response.data.name;
      user.email = response.data.email;
      auth.setUser(user);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const editAddress = (data) => (dispatch) => {
  return profile
    .editAddress(data)
    .then((response) => {
      dispatch({
        type: "EDIT_ADDRESS",
        data: response.data,
      });
    })
    .catch((error) => Promise.reject(error));
};

export const updateUserType = () => (dispatch) => {
  return profile
    .getProfile()
    .then((response) => {
      dispatch({
        type: "UPDATE_USER_TYPE",
        data: response.data,
      });
      const user = JSON.parse(auth.getUser());
      user.status = response.data.vendor.status;
      auth.setUser(user);
    })
    .catch((error) => {
      dispatch({
        type: "SET_ERROR",
        error,
      });
    });
};

export const checkin = (data) => (dispatch) => {
  return profile
    .checkin(data)
    .then((response) => {
      // The API just returns (something like) {success:true}, but not the updated data
      // So we can't update the states with the response data
      dispatch({
        type: "UPDATE_OPEN_BUSINESS",
        data: {
          checkout_time: data.checkout_time,
        },
      });
    })
    .catch((error) => Promise.reject(error));
};

export const checkoutTime = (data) => (dispatch) => {
  return profile
    .checkoutTime(data)
    .then((response) => {
      // The API just returns (something like) {success:true}, but not the updated data
      // So we can't update the states with the response data
      dispatch({
        type: "UPDATE_OPEN_BUSINESS",
        data: {
          checkout_time: data.checkout_time,
        },
      });
    })
    .catch((error) => Promise.reject(error));
};

export const checkout = () => (dispatch) => {
  return profile
    .checkout()
    .then((response) => {
      dispatch({
        type: "UPDATE_CLOSE_BUSINESS",
      });
    })
    .catch((error) => Promise.reject(error));
};
