import React from "react";
import validation from "../../../helpers/validation";

import MainError from "../../forms/MainError";

class EditAddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      addressLineOne: props.profile.vendor.address_line_1 || "",
      addressLineTwo: props.profile.vendor.address_line_2 || "",
      city: props.profile.vendor.city || "",
      county: props.profile.vendor.county || "",
      postcode: props.profile.vendor.postcode || "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit.disabled = true;
    if (!validation(e.target)) {
      this.refs.submit.disabled = false;
      this.setState({ valid: false });
      window.scrollTo(0, 0);
    } else {
      this.setState({ valid: true });
    }
    const data = this.state;
    this.props.submitHandler(data);
  }
  render() {
    return (
      <div>
        {this.props.error ? <MainError errorText={this.props.error} /> : ""}
        {!this.state.valid ? (
          <MainError
            errorText={
              "Error: Please check you have entered all information correctly."
            }
          />
        ) : (
          ""
        )}
        <form noValidate onSubmit={this.handleSubmit}>
          <fieldset>
            <legend>Address</legend>
            <p>
              Enter your address details to receive promotions and offers from
              Hawkker that may be of interest to you.
            </p>
            <label>
              Address Line 1
              <input
                name="addressLineOne"
                type="text"
                value={this.state.addressLineOne}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Address Line 2
              <input
                name="addressLineTwo"
                type="text"
                value={this.state.addressLineTwo}
                onChange={this.handleChange}
              />
            </label>
            <label>
              City/Town
              <input
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.handleChange}
              />
            </label>
            <label>
              County
              <input
                name="county"
                type="text"
                value={this.state.county}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Postcode
              <input
                name="postcode"
                type="text"
                value={this.state.postcode}
                onChange={this.handleChange}
              />
            </label>
          </fieldset>
          <button
            ref="submit"
            className="button expanded"
            type="submit"
            disabled={this.props.submitDisabled}
          >
            Save
          </button>
        </form>
      </div>
    );
  }
}

export default EditAddressForm;
