import React from "react";
import moment from "moment";

import SideScrollExample from "../layout/SideScrollExample";
import TruncatedText from "../generic/TruncatedText";
import TransactionsTableHeaderTitle from "./TransactionsTableHeaderTitle";
import DownloadTransactions from "./DownloadTransactions";

class TransactionsTable extends React.Component {
  constructor() {
    super();
    this.state = {
      orderBy: {
        date: true,
        type: false,
        item: false,
        price: false,
        discounted: false,
        rating: false,
        waitTime: false,
      },
      descending: {
        date: true,
        type: true,
        items: true,
        price: true,
        discounted: true,
        rating: true,
        waitTime: true,
      },
    };
  }
  orderedTransactions = () => {
    const { transactions } = this.props;
    const { orderBy, descending } = this.state;
    let orderedTransactions = [...transactions];

    if (orderBy.waitTime) {
      orderedTransactions.sort((a, b) => {
        if (descending.waitTime) {
          return b.waiting_time - a.waiting_time;
        } else {
          return a.waiting_time - b.waiting_time;
        }
      });
    }

    if (orderBy.rating) {
      orderedTransactions = orderedTransactions
        .filter((a) => a.rating)
        .sort((a, b) => {
          if (descending.rating) {
            return b.rating.overall_rating - a.rating.overall_rating;
          } else {
            return a.rating.overall_rating - b.rating.overall_rating;
          }
        });
    }

    if (orderBy.discounted) {
      orderedTransactions.sort((a, b) => {
        if (descending.discounted) {
          return b.discount > a.discount;
        } else {
          return a.discount > b.discount;
        }
      });
    }

    if (orderBy.price) {
      orderedTransactions.sort((a, b) => {
        if (descending.price) {
          return b.amount - a.amount;
        } else {
          return a.amount - b.amount;
        }
      });
    }

    if (orderBy.items) {
      orderedTransactions = orderedTransactions.filter((a) => a.details.length);

      orderedTransactions.forEach((transaction) => {
        transaction.details.sort(
          (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
        );
      });

      orderedTransactions.sort((a, b) => {
        const nameA = a.details[0].name;
        const nameB = b.details[0].name;

        if (descending.items) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        }
      });
    }

    if (orderBy.type) {
      orderedTransactions.sort((a, b) => {
        if (descending.type) {
          return b.new_customer - a.new_customer;
        } else {
          return a.new_customer - b.new_customer;
        }
      });
    }

    if (orderBy.date) {
      orderedTransactions.sort((a, b) => {
        if (descending.date) {
          return new Date(b.created_at) - new Date(a.created_at);
        } else {
          return new Date(a.created_at) - new Date(b.created_at);
        }
      });
    }

    return orderedTransactions;
  };
  setOrderTypeActiveHandler = (type) => {
    this.setState((prevState) => ({
      orderBy: {
        date: false,
        type: false,
        item: false,
        price: false,
        discounted: false,
        rating: false,
        waitTime: false,
        [type]: !prevState.orderBy[type],
      },
    }));
  };
  changeDirectionHandler = (type) => {
    this.setState((prevState) => ({
      descending: {
        ...prevState.descending,
        [type]: !prevState.descending[type],
      },
    }));
  };
  getTableElm = () => {
    return this.refs.table;
  };
  render() {
    const {
      orderedTransactions,
      setOrderTypeActiveHandler,
      changeDirectionHandler,
      getTableElm,
    } = this;
    const { orderBy, descending } = this.state;
    const { purchases, isFetchingNext, getNextPurchases } = this.props;

    return (
      <React.Fragment>
        <div className="transactions-table-wrapper">
          <SideScrollExample />
          <table className="transactions-table" ref="table">
            <thead className="transactions-table-head">
              <tr>
                <TransactionsTableHeaderTitle
                  title="Date/Time"
                  active={orderBy.date}
                  descending={descending.date}
                  setActiveHandler={() => setOrderTypeActiveHandler("date")}
                  changeDirectionHandler={() => changeDirectionHandler("date")}
                />
                <TransactionsTableHeaderTitle
                  title="Customer Type"
                  active={orderBy.type}
                  descending={descending.type}
                  setActiveHandler={() => setOrderTypeActiveHandler("type")}
                  changeDirectionHandler={() => changeDirectionHandler("type")}
                />
                <TransactionsTableHeaderTitle
                  title="Item(s)"
                  active={orderBy.items}
                  descending={descending.items}
                  setActiveHandler={() => setOrderTypeActiveHandler("items")}
                  changeDirectionHandler={() => changeDirectionHandler("items")}
                />
                <TransactionsTableHeaderTitle
                  title="Price"
                  active={orderBy.price}
                  descending={descending.price}
                  setActiveHandler={() => setOrderTypeActiveHandler("price")}
                  changeDirectionHandler={() => changeDirectionHandler("price")}
                />
                <TransactionsTableHeaderTitle
                  title="Discounted?"
                  active={orderBy.discounted}
                  descending={descending.discounted}
                  setActiveHandler={() =>
                    setOrderTypeActiveHandler("discounted")
                  }
                  changeDirectionHandler={() =>
                    changeDirectionHandler("discounted")
                  }
                />
                <TransactionsTableHeaderTitle
                  title="Rating"
                  active={orderBy.rating}
                  descending={descending.rating}
                  setActiveHandler={() => setOrderTypeActiveHandler("rating")}
                  changeDirectionHandler={() =>
                    changeDirectionHandler("rating")
                  }
                />
                <TransactionsTableHeaderTitle
                  title="Wait Time (mins)"
                  active={orderBy.waitTime}
                  descending={descending.waitTime}
                  setActiveHandler={() => setOrderTypeActiveHandler("waitTime")}
                  changeDirectionHandler={() =>
                    changeDirectionHandler("waitTime")
                  }
                />
                <td>Comments</td>
              </tr>
            </thead>
            <tbody className="transactions-table-body">
              {orderedTransactions().map((transaction, i) => (
                <tr key={`${transaction.id}-${i}`}>
                  <td>
                    {moment(transaction.created_at).format("DD/MM/YYYY HH:mm")}
                  </td>
                  <td>{transaction.new_customer ? "N" : "R"}</td>
                  <td className="transactions-table-body__width-limit min-width">
                    {transaction.details.length ? (
                      transaction.details.length > 1 ? (
                        transaction.details
                          .sort((a, b) => a.name > b.name)
                          .map((dish, i) => (
                            <React.Fragment key={i}>
                              {i === transaction.details.length - 1
                                ? dish.name + "."
                                : dish.name + ", "}
                            </React.Fragment>
                          ))
                      ) : (
                        transaction.details[0].name
                      )
                    ) : (
                      <span className="transactions-table-body_dish-not-listed">
                        Dish not listed
                      </span>
                    )}
                  </td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.discount ? "Y" : "N"}</td>
                  <td>
                    {transaction.rating ? (
                      <React.Fragment>
                        {transaction.rating.overall_rating}
                        <span className="transactions-table-body__star">
                          &#9733;
                        </span>
                      </React.Fragment>
                    ) : (
                      "--"
                    )}
                  </td>
                  <td>
                    {transaction.waiting_time ? transaction.waiting_time : "--"}
                  </td>
                  <td className="transactions-table-body__width-limit min-width">
                    {transaction.rating ? (
                      <TruncatedText
                        text={transaction.rating.text}
                        maxLength={90}
                      />
                    ) : (
                      "--"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="grid-x align-center justify-center transaction-detail__footer">
          {purchases.next && (
            <button
              ref="loadMoreButton"
              className="load-more-btn"
              disabled={isFetchingNext}
              onClick={getNextPurchases}
            >
              Load More
            </button>
          )}
          <DownloadTransactions table={getTableElm} />
        </div>
      </React.Fragment>
    );
  }
}

export default TransactionsTable;
