import React from "react";
import capitalise from "../../helpers/capitalise";

const CheckboxItem = (props) => (
  <li
    key={props.name}
    className={`select-list__item ${
      props.checked ? "select-list__item-checked" : ""
    } ${props.disabled ? "select-list__item-disabled" : ""}`}
  >
    <input
      data-item-id={props.id}
      type="checkbox"
      id={props.name}
      checked={props.checked}
      onChange={props.changeHandler}
      disabled={props.disabled}
    />
    <label htmlFor={props.name}>{capitalise(props.item)}</label>
  </li>
);

export default CheckboxItem;
