import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getDashboardSummary,
  getMostPurchased,
  getCustomerType,
  getHighestRated,
  getLatestTransactions,
} from "../actions";

import CrumbRoute from "./CrumbRoute";
import DashboardOverview from "../components/dashboard/DashboardOverview";
import FullDishList from "../components/dashboard/FullDishList";

class DashboardContainer extends React.Component {
  componentDidMount() {
    const {
      summaryLoading,
      getDashboardSummary,
      mostPurchasedLoading,
      getMostPurchased,
      customerTypeLoading,
      getCustomerType,
      highestRatedLoading,
      getHighestRated,
      latestTransactionsLoading,
      getLatestTransactions,
    } = this.props;
    if (summaryLoading) getDashboardSummary();
    if (mostPurchasedLoading) getMostPurchased();
    if (customerTypeLoading) getCustomerType();
    if (highestRatedLoading) getHighestRated();
    if (latestTransactionsLoading) getLatestTransactions();
  }
  render() {
    const {
      summaryLoading,
      dashboard,
      mostPurchasedLoading,
      highestRatedLoading,
      mostPurchased,
      highestRated,
      customerTypeLoading,
      latestTransactionsLoading,
    } = this.props;

    if (
      summaryLoading ||
      mostPurchasedLoading ||
      customerTypeLoading ||
      highestRatedLoading ||
      latestTransactionsLoading
    )
      return <div className="loading-placeholder" />;

    return (
      <Switch>
        <Route
          exact
          path="/dashboard"
          render={() => <DashboardOverview dashboard={dashboard} />}
        />
        <CrumbRoute
          title="Most Purchased Dishes"
          path="/dashboard/most-purchased"
          render={() => (
            <FullDishList
              dishes={mostPurchased}
              isLoading={mostPurchasedLoading}
            />
          )}
        />
        <CrumbRoute
          title="Highest Rated Dishes"
          path="/dashboard/highest-rated"
          render={() => (
            <FullDishList
              dishes={highestRated}
              isLoading={highestRatedLoading}
            />
          )}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  mostPurchased: state.dashboard.mostPurchased.results,
  highestRated: state.dashboard.highestRated.results,
  summaryLoading: state.dashboard.summary.isLoading,
  mostPurchasedLoading: state.dashboard.mostPurchased.isLoading,
  customerTypeLoading: state.dashboard.customerType.isLoading,
  highestRatedLoading: state.dashboard.highestRated.isLoading,
  latestTransactionsLoading: state.dashboard.latestTransactions.isLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDashboardSummary,
      getMostPurchased,
      getCustomerType,
      getHighestRated,
      getLatestTransactions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
