import * as React from "react";
import { connect } from "react-redux";
import { getLocations } from "../../actions";

function PureMarketAreasField({
  name,
  value,
  onChange,
  markets,
  getLocations,
  ...props
}) {
  React.useEffect(() => {
    if (markets == null) {
      getLocations();
    }
  }, []);

  const areas: string[] = React.useMemo(() => {
    if (markets == null || !Array.isArray(markets)) return [];

    return Array.from(
      new Set(markets.map((m) => m.area.trim()).filter(Boolean))
    ).sort();
  }, [markets]);

  return (
    <select
      {...props}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value={null}>Please select area</option>
      {areas.map((area) => (
        <option key={area} value={area}>
          {area}
        </option>
      ))}
    </select>
  );
}

export const MarketAreasField = connect(
  (state) => ({
    // @ts-ignore
    markets: state.markets.locations?.list,
  }),
  { getLocations }
)(PureMarketAreasField);
