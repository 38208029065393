import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getLocations, isLoadingLocations } from "../../selectors/locations";
import ScheduleLocation from "./ScheduleLocation";
import { getVendorLocations } from "../../actions/locations";
import EmptyLocations from "./EmptyLocations";

function LocationsList({
  history,
  isLoading,
  location,
  scheduleLocations,
  getVendorLocations,
}) {
  React.useEffect(() => {
    getVendorLocations();
  }, []);

  if (isLoading) {
    return <div className="loading-placeholder menu-preload" />;
  }

  return (
    <div>
      <div className="grid-container menu-list__grid-container">
        <div className="grid-x align-center">
          {!scheduleLocations.length ? (
            <EmptyLocations location={location.pathname} />
          ) : (
            <div className="cell medium-9 large-8 menu-list">
              <div className="menu-list__header">
                <div className="menu-list__header__left">
                  <h1 className="menu-list__header__left__title">Schedule</h1>
                  {!!scheduleLocations.length && (
                    <p className="menu-list__header__left__totals">
                      {scheduleLocations.length}{" "}
                      {scheduleLocations.length > 1 ? "Locations" : "Location"}
                    </p>
                  )}
                </div>
                <Link
                  to={`${location.pathname}/add-location`}
                  className="button menu-list__add-btn"
                >
                  Add Location
                </Link>
              </div>
              <div>
                {scheduleLocations.map((scheduleLocation) => (
                  <ScheduleLocation
                    key={scheduleLocation.id}
                    location={scheduleLocation}
                    onClick={() =>
                      history.push(`/schedule/${scheduleLocation.id}`)
                    }
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: isLoadingLocations(state),
  scheduleLocations: getLocations(state),
});

export default connect(mapStateToProps, { getVendorLocations })(LocationsList);
