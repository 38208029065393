import React from "react";
import capitalise from "../../helpers/capitalise";

export default function RadioItem({ item, changeHandler, ...props }) {
  // Backward compatibility with old API
  if (changeHandler == null && props.onChange) {
    changeHandler = props.onChange;
  }

  return (
    <li
      key={props.name}
      className={`select-list__item ${
        props.checked ? "select-list__item-checked" : ""
      } ${props.disabled ? "select-list__item-disabled" : ""}`}
    >
      <input
        {...props}
        data-item-id={props.id}
        type="radio"
        onChange={changeHandler}
      />
      <label htmlFor={props.id}>{capitalise(item)}</label>
    </li>
  );
}
