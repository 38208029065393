import { Schema } from "yup";
import { setIn } from "final-form";

/*
 * makeValidate - turn yup schema validator into react-final-form
 * compatible validation function
 */
export function makeValidate<T>(validator: Schema<T>) {
  return async (values: T) => {
    try {
      await validator.validate(values, { abortEarly: false });
    } catch (e) {
      return (e as any).inner.reduce(
        (errors, { path, message }) => setIn(errors, path, message),
        {}
      );
    }
  };
}
