import useSWR from "swr";
import { fetcher } from "api/base";

export function useDietary() {
  return useSWR("api/v2/dish/dietary/", fetcher);
}

export const dietaryOrder = [
  "vegetarian",
  "vegan",
  "lactosefree",
  "lowcarb",
  "halal",
  "kosher",
  "glutenfree",
  "raw",
];
