import React from "react";
import { post } from "api/base";

export function useUploadMenu({ onSuccess }: useUploadFileProps) {
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState("");

  const upload = async ({ file }) => {
    let filename = sanitizeFilename(file.name);
    const isImage = /^image\//.test(file.type);
    if (file.type !== "application/pdf" && !isImage) {
      setError("Please ensure you upload a pdf, png or jpeg file.");
      return;
    }

    setError("");
    setUploading(true);
    const presignedPostRequest = await post(
      "api/v2/menu_builder/upload/request/",
      {
        filename,
      }
    );

    const formData = new FormData();
    Object.keys(presignedPostRequest.fields).forEach((key) => {
      formData.append(key, presignedPostRequest.fields[key]);
    });
    formData.append("file", file, filename);

    const uploadedFile = await fetch(presignedPostRequest.url, {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status === 204) {
        const filename = response.headers
          .get("location")
          .replace(presignedPostRequest.url, "")
          .replace(/^\//, "");
        return decodeURIComponent(filename);
      }
    });

    const response = await post("api/v2/menu_builder/upload/", {
      key: uploadedFile,
    });

    if (!response.error) {
      const { sessionId } = presignedPostRequest;
      onSuccess({ sessionId });
    } else {
      let reason = "";
      if (response.error.message === "The document is empty") {
        reason = "The file appears to be empty.";
      }
      setError(`Unable to upload this document. ${reason}`);
      setUploading(false);
    }
  };

  return {
    upload,
    uploading,
    error,
  };
}

type useUploadFileProps = {
  onSuccess: ({ sessionId: string }) => Promise<any> | void;
};

/**
 * Leave only basic characters in filename,
 * otherwise there're unexpected problems with AWS S3.
 */
function sanitizeFilename(value) {
  return value.replace(/[^0-9a-zA-Z._-]+/g, "") || "document";
}
