import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editAddress } from "../../actions";

import EditAddressForm from "./forms/EditAddressForm";

class EditAddress extends React.Component {
  constructor() {
    super();
    this.state = {
      submitDisabled: false,
      error: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleSubmit(data) {
    this.setState({ submitDisabled: true });
    const payLoad = {
      address_line_1: data.addressLineOne,
      address_line_2: data.addressLineTwo,
      city: data.city,
      county: data.county,
      postcode: data.postcode,
    };
    this.props
      .editAddress(payLoad)
      .then(() => {
        this.props.history.push("/profile");
      })
      .catch((error) => {
        if (!this._isMounted) return;
        this.setState({
          error: error.data.error,
          submitDisabled: false,
        });
        window.scrollTo(0, 0);
      });
  }
  render() {
    const { profile } = this.props;
    const { submitDisabled, error } = this.state;
    const { handleSubmit } = this;

    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Edit Address</h1>
            <EditAddressForm
              error={error}
              profile={profile}
              submitDisabled={submitDisabled}
              submitHandler={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editAddress }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
