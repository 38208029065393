import React from "react";
import classnames from "classnames";

export function SectionButton({
  variant = "default",
  actions = null,
  children,
  className = "",
  ...props
}) {
  return (
    <button
      className={classnames(
        className,
        "px-6 py-4 flex justify-between items-center rounded-full cursor-pointer disabled:border disabled:border-gray-100 disabled:bg-transparent",
        {
          "bg-white shadow hover:bg-opacity-25": variant === "inverted",
          "bg-gray-100 hover:bg-opacity-75": variant === "default",
        }
      )}
      {...props}
    >
      {children}
      {actions && <div>{actions}</div>}
    </button>
  );
}
