import React from "react";

class ScrollToTop extends React.Component {
  constructor() {
    super();
    this.state = {
      showScrollToTop: false,
    };
    this.showScrollToTop = this.showScrollToTop.bind(this);
  }
  componentDidMount() {
    const { showScrollToTop } = this;
    window.addEventListener("scroll", showScrollToTop);
  }
  componentWillUnmount() {
    const { showScrollToTop } = this;
    window.removeEventListener("scroll", showScrollToTop);
  }
  showScrollToTop() {
    if (
      window.pageYOffset > window.innerHeight &&
      !this.state.showScrollToTop
    ) {
      this.setState({
        showScrollToTop: true,
      });
    } else if (
      window.pageYOffset < window.innerHeight &&
      this.state.showScrollToTop
    ) {
      this.setState({
        showScrollToTop: false,
      });
    }
  }
  scrollToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const { className } = this.props;
    const { showScrollToTop } = this.state;
    const { scrollToTop } = this;

    return (
      <button
        className={`scroll-to-top${showScrollToTop ? " show" : ""}${
          className ? ` ${className}` : ""
        }`}
        onClick={scrollToTop}
      />
    );
  }
}

export default ScrollToTop;
