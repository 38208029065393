const initialState = {
  summary: {
    isLoading: true,
  },
  mostPurchased: {
    isLoading: true,
    results: [],
  },
  customerType: {
    isLoading: true,
  },
  highestRated: {
    isLoading: true,
    results: [],
  },
  latestTransactions: {
    isLoading: true,
    results: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_SUCCESS":
      return {
        ...state,
        summary: {
          isLoading: false,
          ...action.data,
        },
      };
    case "GET_MOST_PURCHASED_SUCCESS":
      return {
        ...state,
        mostPurchased: {
          isLoading: false,
          results: action.data,
        },
      };
    case "GET_CUSTOMER_TYPE_SUCCESS":
      return {
        ...state,
        customerType: {
          isLoading: false,
          ...action.data,
        },
      };
    case "GET_HIGHEST_RATED_SUCCESS":
      return {
        ...state,
        highestRated: {
          isLoading: false,
          results: action.data,
        },
      };
    case "GET_LATEST_TRANSACTIONS_SUCCESS":
      const sorted = action.data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      const lastWeek = sorted.slice(0, 7);
      const previousWeek = sorted.slice(7, 14);
      return {
        ...state,
        latestTransactions: {
          isLoading: false,
          results: {
            lastWeek,
            previousWeek,
          },
        },
      };
    default:
      return state;
  }
};
