import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import EditChalkboardOffer from "components/menu/EditChalkboardOffer";
import CrumbRoute from "containers/CrumbRoute";
import Chalkboard from "components/menu/Chalkboard";

function ChalkboardContainer({ profileLoading }) {
  return (
    <div>
      <Switch>
        <Route exact path="/chalkboard" component={Chalkboard} />
        <CrumbRoute
          exact
          title="Edit Chalkboard"
          path="/chalkboard/edit"
          render={(props) => (
            <div>
              {profileLoading ? (
                <div className="loading-placeholder" />
              ) : (
                <EditChalkboardOffer {...props} />
              )}
            </div>
          )}
        />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profileLoading: state.profile.loading,
});

export default connect(mapStateToProps)(ChalkboardContainer);
