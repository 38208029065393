import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLocations, editBusinessInfo } from "../../actions";

import EditFurtherInfoForm, { FurtherInfo } from "./forms/EditFurtherInfoForm";

class EditFurtherInfo extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      submitDisabled: false,
      error: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    // @ts-ignore
    this._isMounted = true;
    if (!this.props.locations.list.length) {
      this.props.getLocations();
    }
  }
  componentWillUnmount() {
    // @ts-ignore
    this._isMounted = false;
  }
  handleSubmit(data: FurtherInfo) {
    this.setState({ submitDisabled: true });
    const payLoad = {
      cash: data.cash,
      card: data.card,
      private_hire_email: data.private_hire_email,
      website_link: data.website_link,
    };
    this.props
      .editBusinessInfo(payLoad)
      .then(() => {
        this.props.history.push("/profile");
      })
      .catch((error) => {
        // @ts-ignore
        if (!this._isMounted) return;
        this.setState({
          error: error.data.error,
          submitDisabled: false,
        });
        window.scrollTo(0, 0);
      });
  }

  render() {
    return (
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell single-col-layout">
            <h1>Edit Further Info</h1>
            <EditFurtherInfoForm
              profile={this.props.profile}
              submitDisabled={this.state.submitDisabled}
              submitHandler={this.handleSubmit}
              error={this.state.error}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  locations: state.markets.locations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getLocations, editBusinessInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditFurtherInfo);
