import { useDishes } from "api/menu/useDishes";
import { useCreateDish } from "api/menu/useCreateDish";
import { useUpdateDish } from "api/menu/useUpdateDish";
import { useRemoveDish } from "api/menu/useRemoveDish";

export function useDishesOperations({ activeSection }) {
  const dishes = useDishes(
    {
      expand: ["section", "dietary_information"],
      section: activeSection,
    },
    { skip: activeSection == null }
  );
  const createDish = useCreateDish();
  const updateDish = useUpdateDish();
  const deleteDish = useRemoveDish();

  const handleCreate = async (values) => {
    await createDish({
      ...values,
      section: activeSection,
    });
    await dishes.mutate();
  };

  const handleUpdate = async (id, values) => {
    await updateDish(id, values);
    await dishes.mutate();
  };

  const handleDelete = async (id) => {
    await deleteDish(id);
    await dishes.mutate();
  };

  const handleReorder = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    await updateDish(result.draggableId, {
      insert_before: result.destination.index,
    });
    await dishes.mutate();
  };

  return {
    dishes,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleReorder,
  };
}
