import React from "react";
import { connect } from "react-redux";

function Chalkboard({ history, offer, offerActive }) {
  const handleChalkboardClick = () => {
    history.push("/chalkboard/edit");
  };

  return (
    <div className="grid-container">
      <div className="grid-x align-center">
        <div className="cell single-col-layout">
          <div className="menu-list__chalkboard-offer-container">
            <h1 className="menu-list__chalkboard-offer-title">Chalkboard</h1>
            <div
              className="menu-list__chalkboard-offer"
              onClick={handleChalkboardClick}
            >
              <h2
                className={`menu-list__chalkboard-offer__title ${
                  offerActive ? "" : "inactive"
                }`}
              >
                {offer ? offer : "E.g. 50% Off All Burgers, Today Only!"}
              </h2>
            </div>
            <span
              className={`badge ${
                offerActive ? "active" : ""
              } menu-list__chalkboard-offer__active`}
            >
              {offerActive ? "Active" : "Inactive"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  offer: state.profile.offer,
  offerActive: state.profile.offer_active,
});

export default connect(mapStateToProps)(Chalkboard);
