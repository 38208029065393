import React from "react";
import { PlusIcon } from "icons";

import { SectionButton } from "components/generic/SectionButton";
import { useMenuItemForm } from "components/menu/useMenuItemForm";
import { MenuItemForm } from "components/menu/MenuItemForm";

export function MenuItemAddButton({ label }) {
  const menuItemForm = useMenuItemForm();

  return (
    <>
      <SectionButton
        onClick={() => menuItemForm.open()}
        disabled={menuItemForm.showAddForm}
        actions={<PlusIcon className="text-gray-900 text-xl" />}
      >
        {label}
      </SectionButton>

      {menuItemForm.showAddForm && (
        <MenuItemForm onClose={menuItemForm.close} />
      )}
    </>
  );
}
