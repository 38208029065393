import React from "react";
import classnames from "classnames";
import { DragDropContext } from "react-beautiful-dnd";
import { MenuItemFormProvider } from "components/menu/useMenuItemForm";
import { SectionLabel } from "components/generic/SectionLabel";
import { MenuItemAddButton } from "components/menu/MenuItemAddButton";
import { MenuItems } from "components/menu/MenuItems";
import { useDishesOperations } from "components/menu/useDishesOperations";

export function MenuItemsContainer(props: MenuItemsContainerProps) {
  const dishOps = useDishesOperations({
    activeSection: props.activeSection,
  });

  if (props.sections == null) return null;

  const activeSection = props.sections.find(
    (section) => section.id === props.activeSection
  );

  const items = dishOps.dishes.data?.results ?? [];

  return (
    <MenuItemFormProvider
      onCreate={dishOps.handleCreate}
      onUpdate={dishOps.handleUpdate}
    >
      <div className={classnames(props.className)}>
        {props.activeSection == null ? (
          <p>
            Add menu sections (e.g. starters, mains, sides) to add your menu
            items to these sections.
          </p>
        ) : (
          <MenuItemAddButton
            label={`Add a New Menu Item to "${activeSection?.name}"`}
          />
        )}

        {activeSection != null && (
          <SectionLabel>Menu Items in "{activeSection.name}"</SectionLabel>
        )}

        <DragDropContext onDragEnd={dishOps.handleReorder}>
          <MenuItems items={items} onDelete={dishOps.handleDelete} />
        </DragDropContext>

        {!items.length && activeSection != null && (
          <p>This section is empty. Add more items to "{activeSection.name}"</p>
        )}
      </div>
    </MenuItemFormProvider>
  );
}

type MenuItemsContainerProps = {
  className?: string;
  sections: any[];
  activeSection: number | null;
};
