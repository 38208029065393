import React from "react";
import CrumbRoute from "./CrumbRoute";
import { Route, Switch } from "react-router-dom";
import AddLocation from "../components/schedule/AddLocation";
import LocationsList from "../components/schedule/LocationsList";
import EditLocation from "../components/schedule/EditLocation";
import { connect } from "react-redux";
import { getVendorLocations } from "../actions/locations";
import { getLocations as getMarkets } from "../actions";
import { getLocations } from "../selectors/locations";

class ScheduleContainer extends React.Component {
  componentDidMount() {
    this.props.getVendorLocations();
    this.props.getMarkets();
  }

  render() {
    return (
      <div className="grid-container align-center reviews-container">
        <Switch>
          <Route exact path="/schedule" component={LocationsList} />
          <CrumbRoute
            exact
            title="Add Location"
            path="/schedule/add-location"
            component={AddLocation}
          />
          <CrumbRoute
            exact
            title="Edit Location"
            path="/schedule/:locationId"
            render={(props) => {
              const location = this.props.scheduleLocations.find(
                (loc) => `${loc.id}` === props.match.params.locationId
              );

              return <EditLocation {...props} location={location} />;
            }}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scheduleLocations: getLocations(state),
});

export default connect(mapStateToProps, { getVendorLocations, getMarkets })(
  ScheduleContainer
);
