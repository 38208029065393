import { useSWRConfig } from "swr";

import { patch } from "api/base";
import { menuSectionEndpoint } from "api/menu/useMenuSection";

export function useUpdateMenuSection() {
  const { mutate } = useSWRConfig();

  return async (id: number, values: any) => {
    const data = await patch(menuSectionEndpoint(id), values);
    await mutate(menuSectionEndpoint(id), data, false);
    return data;
  };
}
