const initialState = {
  locations: {
    list: [],
    isLoading: true,
  },
  locationOfferings: {
    list: [],
    isLoading: true,
  },
  postcodeAreas: {
    list: [],
    isLoading: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_VENDOR_LOCATIONS":
      return {
        ...state,
        locations: {
          ...state.locations,
          list: action.locations,
          isLoading: false,
        },
      };
    case "SET_LOCATION_OFFERINGS":
      return {
        ...state,
        locationOfferings: {
          ...state.locationOfferings,
          list: action.locationOfferings,
          isLoading: false,
        },
      };
    case "SET_POSTCODE_AREAS":
      return {
        ...state,
        postcodeAreas: {
          ...state.postcodeAreas,
          list: action.postcodeAreas,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
