const initialState = {
  locations: {
    list: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCATIONS":
      return {
        ...state,
        locations: {
          ...state.locations,
          list: action.locations,
        },
      };
    default:
      return state;
  }
};
