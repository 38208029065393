import React from "react";

export function ProgressBar({ value }) {
  const className = `progress-bar__value ${
    value === 100 ? "progress-bar__value--completed" : ""
  }`;

  return (
    <div className="progress-bar">
      <span className="progress-bar__label">Progress: {value}%</span>
      <div className="progress-bar__bar">
        <div className={className} style={{ width: `${value}%` }} />
      </div>
    </div>
  );
}
