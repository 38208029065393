import React from "react";
import filterIcon from "../../assets/icons/filters.svg";

import FilterSummary from "./FilterSummary";
import FilterOptions from "./FilterOptions";

const TransactionFilters = (props) => {
  const {
    filtersOpen,
    openFilters,
    dishNames,
    filters,
    changeHandler,
    handleFilterSubmit,
    showing,
    isFetchingFiltered,
  } = props;

  return (
    <div className="transaction-filters">
      <div
        className={`transaction-filters__overlay${filtersOpen ? " open" : ""}`}
      />
      <img src={filterIcon} alt="Filter" />
      <FilterSummary
        open={filtersOpen}
        clickHandler={openFilters}
        dateRange={showing.days}
        dish={showing.dish}
        rating={showing.rating}
      />
      <FilterOptions
        isFetchingFiltered={isFetchingFiltered}
        submitHandler={handleFilterSubmit}
        filters={filters}
        changeHandler={changeHandler}
        open={filtersOpen}
        dishNames={dishNames}
      />
    </div>
  );
};

export default TransactionFilters;
